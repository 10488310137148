// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `clickable-text-input {
  display: block;
}

.clickable-text-input--Wrapper {
  align-items: center;
  flex-direction: row;
  gap: 15px;
}

.clickable-text-input--Input {
  font-size: 17px;
}

.clickable-text-input--Input input[type='number'] {
  -moz-appearance: textfield;
}

.clickable-text-input--InputMonospace {
  font-family: monospace;
}

.clickable-text-input--EditButton {
  position: relative;
  line-height: 0;
}

.clickable-text-input--CompactEditButton {
  position: relative;
}

.clickable-text-input--HasFloatingLabel .clickable-text-input--EditButton {
  top: 0;
}

.clickable-text-input--IncrementButton {
  width: 24px;
  height: 24px;
  padding: unset;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/clickable-text-input/clickable-text-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,MAAM;AACR;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":["clickable-text-input {\n  display: block;\n}\n\n.clickable-text-input--Wrapper {\n  align-items: center;\n  flex-direction: row;\n  gap: 15px;\n}\n\n.clickable-text-input--Input {\n  font-size: 17px;\n}\n\n.clickable-text-input--Input input[type='number'] {\n  -moz-appearance: textfield;\n}\n\n.clickable-text-input--InputMonospace {\n  font-family: monospace;\n}\n\n.clickable-text-input--EditButton {\n  position: relative;\n  line-height: 0;\n}\n\n.clickable-text-input--CompactEditButton {\n  position: relative;\n}\n\n.clickable-text-input--HasFloatingLabel .clickable-text-input--EditButton {\n  top: 0;\n}\n\n.clickable-text-input--IncrementButton {\n  width: 24px;\n  height: 24px;\n  padding: unset;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
