// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-position-detail-entries-widget-table {
  display: block;
}

.process-task-position-detail-entries-widget-table--Header {
  color: var(--record-it-color-gray-600);
}

.process-task-position-detail-entries-widget-table--Footer
  .process-task-position-detail-entries-widget--PropertyColumn {
  color: var(--record-it-color-gray-600);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-position-detail-entries-widget/process-task-position-detail-entries-widget-table/process-task-position-detail-entries-widget-table.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;;EAEE,sCAAsC;AACxC","sourcesContent":["process-task-position-detail-entries-widget-table {\n  display: block;\n}\n\n.process-task-position-detail-entries-widget-table--Header {\n  color: var(--record-it-color-gray-600);\n}\n\n.process-task-position-detail-entries-widget-table--Footer\n  .process-task-position-detail-entries-widget--PropertyColumn {\n  color: var(--record-it-color-gray-600);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
