// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `sign-pdf-report-dialog {
  display: block;
}

.sign-pdf-report-dialog--Layout {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/sign-pdf-report-dialog/sign-pdf-report-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":["sign-pdf-report-dialog {\n  display: block;\n}\n\n.sign-pdf-report-dialog--Layout {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
