// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-device-list-item {
  display: block;
}

.process-task-device-list-item--TitleColumn {
  padding-left: 0;
}

.process-task-device-list-item--BottomRow {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid var(--record-it-color-gray-300);
  line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-device-list-item/process-task-device-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,qDAAqD;EACrD,gBAAgB;AAClB","sourcesContent":["process-task-device-list-item {\n  display: block;\n}\n\n.process-task-device-list-item--TitleColumn {\n  padding-left: 0;\n}\n\n.process-task-device-list-item--BottomRow {\n  flex: 1 1 auto;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  border-top: 1px solid var(--record-it-color-gray-300);\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
