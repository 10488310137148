// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `aspect-ratio-box {
  display: block;
  position: relative;
}

.aspect-ratio-box--Svg {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/aspect-ratio-box/aspect-ratio-box.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["aspect-ratio-box {\n  display: block;\n  position: relative;\n}\n\n.aspect-ratio-box--Svg {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
