// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `weekday-input {
  display: block;
}

.weekday-input--SelectedButton {
  background-color: var(--record-it-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/weekday-input/weekday-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gDAAgD;AAClD","sourcesContent":["weekday-input {\n  display: block;\n}\n\n.weekday-input--SelectedButton {\n  background-color: var(--record-it-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
