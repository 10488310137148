// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thing-map--FlexContainer {
  display: flex;
  flex-flow: column nowrap;
  min-height: 400px;
}

.thing-map--Container {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  align-self: center;
}

.thing-map--Header {
  flex: 0 0 auto;
  height: auto;
}

.thing-map--BasemapMap {
  width: 100%;
}

@media (max-width: 1279px) {
  .thing-map--Map {
    height: 100%;
    max-width: unset;
    padding: 0;
  }

  .thing-map--BasemapMap {
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/thing_map/thing_map.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".thing-map--FlexContainer {\n  display: flex;\n  flex-flow: column nowrap;\n  min-height: 400px;\n}\n\n.thing-map--Container {\n  flex: 1 1 auto;\n  width: 100%;\n  height: 100%;\n  align-self: center;\n}\n\n.thing-map--Header {\n  flex: 0 0 auto;\n  height: auto;\n}\n\n.thing-map--BasemapMap {\n  width: 100%;\n}\n\n@media (max-width: 1279px) {\n  .thing-map--Map {\n    height: 100%;\n    max-width: unset;\n    padding: 0;\n  }\n\n  .thing-map--BasemapMap {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
