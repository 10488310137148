// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `create-projects-and-manage-process-task-relations-widget {
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/create-projects-and-manage-process-task-relations-widget/create-projects-and-manage-process-task-relations-widget.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":["create-projects-and-manage-process-task-relations-widget {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
