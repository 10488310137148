// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-picture-area-preview {
  display: block;
  position: absolute;
}

.structure-picture-area-preview--Box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/structurePictureAreaComponents/structure-picture-area-preview/structure-picture-area-preview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX","sourcesContent":["structure-picture-area-preview {\n  display: block;\n  position: absolute;\n}\n\n.structure-picture-area-preview--Box {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
