// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thing-section-assessment-dialog-thing-section-picture {
  display: block;
}

.thing-section-assessment-dialog-thing-section-picture--PictureRow {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.thing-section-assessment-dialog-thing-section-picture--PictureWrapper {
  position: relative;
  flex: 0 0 400px;
}

.thing-section-assessment-dialog-thing-section-picture--Marking {
  cursor: pointer;
}

.thing-section-assessment-dialog-thing-section-picture--PreviousMarking {
  border-color: blueviolet;
}

.thing-section-assessment-dialog-thing-section-picture--MarkedEntryPicturesScroller {
  --picture-scroller--picture-size: 200px;
}

.thing-section-assessment-dialog-thing-section-picture--ExpandIconWrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  background: rgba(255, 255, 255, 30%);
  cursor: pointer;
  color: black;
}

.thing-section-assessment-dialog-thing-section-picture--ExpandIconWrapper:hover {
  background: rgba(255, 255, 255, 55%);
}
`, "",{"version":3,"sources":["webpack://./src/thingSectionComponents/thing-section-assessment-dialog/thing-section-assessment-dialog-thing-section-picture/thing-section-assessment-dialog-thing-section-picture.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,oCAAoC;EACpC,eAAe;EACf,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["thing-section-assessment-dialog-thing-section-picture {\n  display: block;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--PictureRow {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--PictureWrapper {\n  position: relative;\n  flex: 0 0 400px;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--Marking {\n  cursor: pointer;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--PreviousMarking {\n  border-color: blueviolet;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--MarkedEntryPicturesScroller {\n  --picture-scroller--picture-size: 200px;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--ExpandIconWrapper {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: 3px;\n  background: rgba(255, 255, 255, 30%);\n  cursor: pointer;\n  color: black;\n}\n\n.thing-section-assessment-dialog-thing-section-picture--ExpandIconWrapper:hover {\n  background: rgba(255, 255, 255, 55%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
