// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `save-biometric-credentials-panel {
  display: block;
}

.save-biometric-credentials-panel--SaveCredentialsPanel {
  background: var(--record-it-color-gray-300);
  padding: 25px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/save-biometric-credentials-panel/save-biometric-credentials-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,2CAA2C;EAC3C,kBAAkB;AACpB","sourcesContent":["save-biometric-credentials-panel {\n  display: block;\n}\n\n.save-biometric-credentials-panel--SaveCredentialsPanel {\n  background: var(--record-it-color-gray-300);\n  padding: 25px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
