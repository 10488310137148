// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-defect-filter {
  display: block;
}

.gallery-thing-defect-filter--TopBar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.gallery-thing-defect-filter--Title {
  font-weight: 500;
  font-size: 17px;
}

.gallery-thing-defect-filter--SubTitle {
  font-weight: 500;
  font-size: 14px;
}

.gallery-thing-defect-filter--ResetFilter {
  color: var(--record-it-color-gray-700);
  text-decoration: underline;
  cursor: pointer;
}

.gallery-thing-defect-filter--Grid {
  display: grid;
  gap: 42px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.gallery-thing-defect-filter--PropertyGrid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gallery-thing-defect-filter--ExportButtons {
  height: 40px;
}

@media (max-width: 992px) {
  .gallery-thing-defect-filter--Grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .gallery-thing-defect-filter--PropertyGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 767px) {
  .gallery-thing-defect-filter--Grid {
    grid-template-columns: minmax(0, 1fr);
  }
  .gallery-thing-defect-filter--PropertyGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-filter/gallery-thing-defect-filter/gallery-thing-defect-filter.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gDAAgD;AAClD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,gDAAgD;EAClD;EACA;IACE,gDAAgD;EAClD;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;EACA;IACE,gDAAgD;EAClD;AACF","sourcesContent":["gallery-thing-defect-filter {\n  display: block;\n}\n\n.gallery-thing-defect-filter--TopBar {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n}\n\n.gallery-thing-defect-filter--Title {\n  font-weight: 500;\n  font-size: 17px;\n}\n\n.gallery-thing-defect-filter--SubTitle {\n  font-weight: 500;\n  font-size: 14px;\n}\n\n.gallery-thing-defect-filter--ResetFilter {\n  color: var(--record-it-color-gray-700);\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.gallery-thing-defect-filter--Grid {\n  display: grid;\n  gap: 42px;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n}\n\n.gallery-thing-defect-filter--PropertyGrid {\n  display: grid;\n  gap: 20px;\n  grid-template-columns: repeat(4, minmax(0, 1fr));\n}\n\n.gallery-thing-defect-filter--ExportButtons {\n  height: 40px;\n}\n\n@media (max-width: 992px) {\n  .gallery-thing-defect-filter--Grid {\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n  }\n  .gallery-thing-defect-filter--PropertyGrid {\n    grid-template-columns: repeat(3, minmax(0, 1fr));\n  }\n}\n\n@media (max-width: 767px) {\n  .gallery-thing-defect-filter--Grid {\n    grid-template-columns: minmax(0, 1fr);\n  }\n  .gallery-thing-defect-filter--PropertyGrid {\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
