// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `switch-thing-type-button {
  display: block;
}

.switch-thing-type-button--DropdownItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

.switch-thing-type-button--DropdownItemIcon:hover {
  text-decoration: unset;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/switch-thing-type-button/switch-thing-type-button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["switch-thing-type-button {\n  display: block;\n}\n\n.switch-thing-type-button--DropdownItem {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.switch-thing-type-button--DropdownItemIcon:hover {\n  text-decoration: unset;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
