// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ultra-rapid-fire-widget-overlay {
  display: flex;

  position: absolute;
  top: 16px;
  left: 20px;

  right: 160px;

  margin-top: -16px;

  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/ultra-rapid-fire-widget-overlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,kBAAkB;EAClB,SAAS;EACT,UAAU;;EAEV,YAAY;;EAEZ,iBAAiB;;EAEjB,eAAe;AACjB","sourcesContent":["ultra-rapid-fire-widget-overlay {\n  display: flex;\n\n  position: absolute;\n  top: 16px;\n  left: 20px;\n\n  right: 160px;\n\n  margin-top: -16px;\n\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
