// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `notification-widget {
  display: block;
}

.notification-widget--Container {
  display: flex;
  flex-flow: column;
  max-height: 100%;

  background: white;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
}

.notification-widget--ContentContainer {
  overflow: auto;
}

.notification-widget--Heading {
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 25px;
}

.notification-widget--ImageContainer {
  text-align: center;
}

.notification-widget--ImageContainer > img {
  max-width: 100%;
}

.notification-widget--Content {
  margin-bottom: 35px;
  white-space: pre-line;
  font-size: 1.15rem;
  padding-left: 15px;
}

.notification-widget--Content > ul,
li {
  margin: 0;
  padding: 0;
}

.notification-widget--SwipingIndicator {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.notification-widget--ButtonBar {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.notification-widget--ButtonBar > *:not(:first-child) {
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/notification-widget/notification-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,gBAAgB;;EAEhB,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;;EAEE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["notification-widget {\n  display: block;\n}\n\n.notification-widget--Container {\n  display: flex;\n  flex-flow: column;\n  max-height: 100%;\n\n  background: white;\n  border-radius: 10px;\n  padding: 20px;\n  overflow: auto;\n}\n\n.notification-widget--ContentContainer {\n  overflow: auto;\n}\n\n.notification-widget--Heading {\n  font-weight: 800;\n  font-size: 1.5rem;\n  margin-bottom: 25px;\n}\n\n.notification-widget--ImageContainer {\n  text-align: center;\n}\n\n.notification-widget--ImageContainer > img {\n  max-width: 100%;\n}\n\n.notification-widget--Content {\n  margin-bottom: 35px;\n  white-space: pre-line;\n  font-size: 1.15rem;\n  padding-left: 15px;\n}\n\n.notification-widget--Content > ul,\nli {\n  margin: 0;\n  padding: 0;\n}\n\n.notification-widget--SwipingIndicator {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n\n.notification-widget--ButtonBar {\n  display: flex;\n  flex-flow: row;\n  justify-content: flex-end;\n}\n\n.notification-widget--ButtonBar > *:not(:first-child) {\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
