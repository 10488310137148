// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thing-person-relations-select-and-edit-widget {
  display: block;
}

.thing-person-relation-select-and-edit-widget--AddRelationButton {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/thing-person-relations-select-and-edit-widget/thing-person-relations-select-and-edit-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":["thing-person-relations-select-and-edit-widget {\n  display: block;\n}\n\n.thing-person-relation-select-and-edit-widget--AddRelationButton {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
