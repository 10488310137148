// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `categorized-tags {
  display: block;
}

.categorized-tags--TagsWidgetList {
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/overlay-widget-templates/categorized-tags.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["categorized-tags {\n  display: block;\n}\n\n.categorized-tags--TagsWidgetList {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
