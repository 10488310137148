// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `project-properties {
  display: block;
}

.project-properties--ContentWrapper > * {
  margin-bottom: 20px;
}

.project-properties--TemplateBlock {
  display: flex;
  width: 100%;
  align-items: center;
}

.project-properties--TemplateBlock:not(:last-child) {
  margin-bottom: 20px;
}

.project-properties--TemplateBlock > custom-select {
  flex: 1 1 auto;
  margin-right: 15px;
}

.project-properties--TemplateBlockCurrentTemplate {
  flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/project-properties/project-properties.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["project-properties {\n  display: block;\n}\n\n.project-properties--ContentWrapper > * {\n  margin-bottom: 20px;\n}\n\n.project-properties--TemplateBlock {\n  display: flex;\n  width: 100%;\n  align-items: center;\n}\n\n.project-properties--TemplateBlock:not(:last-child) {\n  margin-bottom: 20px;\n}\n\n.project-properties--TemplateBlock > custom-select {\n  flex: 1 1 auto;\n  margin-right: 15px;\n}\n\n.project-properties--TemplateBlockCurrentTemplate {\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
