// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-indicator {
  opacity: 0.75;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.circle-preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* transform: translate(-50%, -50%); */
  width: 68px;
  height: 68px;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/loadingComponents/loading-overlay/loading-overlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,sCAAsC;EACtC,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".loading-indicator {\n  opacity: 0.75;\n  background: white;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 5px;\n}\n\n.circle-preloader {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  /* transform: translate(-50%, -50%); */\n  width: 68px;\n  height: 68px;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
