// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `urfw-switch-camera-button {
  display: block;
}

.urfw-switch-camera-button--Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background: none;
  color: var(--record-it-color-gray-400);
  border: none;
  line-height: 0;
  font-size: 32px;
}

.urfw-switch-camera-button--Button:hover {
  color: var(--record-it-color-primary-light);
}

.urfw-switch-camera-button--Button:active {
  color: var(--record-it-color-primary);
}

.urfw-switch-camera-button--Arrows {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/urfw-switch-camera-button/urfw-switch-camera-button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,gBAAgB;EAChB,sCAAsC;EACtC,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,eAAe;AACjB","sourcesContent":["urfw-switch-camera-button {\n  display: block;\n}\n\n.urfw-switch-camera-button--Button {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0;\n  background: none;\n  color: var(--record-it-color-gray-400);\n  border: none;\n  line-height: 0;\n  font-size: 32px;\n}\n\n.urfw-switch-camera-button--Button:hover {\n  color: var(--record-it-color-primary-light);\n}\n\n.urfw-switch-camera-button--Button:active {\n  color: var(--record-it-color-primary);\n}\n\n.urfw-switch-camera-button--Arrows {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
