// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `multi-file-input {
  display: block;
}

.multi-file-input--Label {
  margin-bottom: 11px;
}

.multi-file-input--SelectedFilesContainer {
  display: flex;
  gap: 10px 16px;
}

.multi-file-input--SelectedFile {
  display: inline-block;
}

.multi-file-input--SelectedFile > * {
  vertical-align: middle;
}

.multi-file-input--PreloaderWrapper {
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  position: relative;
}

.multi-file-input--RemoveSelectedFileIcon {
  cursor: pointer;
}

.multi-file-input--AddFileButtonWrapper {
  display: inline-block;
  position: relative;
}

.multi-file-input--FileInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/multi-file-input/multi-file-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;;EAEP,UAAU;AACZ","sourcesContent":["multi-file-input {\n  display: block;\n}\n\n.multi-file-input--Label {\n  margin-bottom: 11px;\n}\n\n.multi-file-input--SelectedFilesContainer {\n  display: flex;\n  gap: 10px 16px;\n}\n\n.multi-file-input--SelectedFile {\n  display: inline-block;\n}\n\n.multi-file-input--SelectedFile > * {\n  vertical-align: middle;\n}\n\n.multi-file-input--PreloaderWrapper {\n  display: inline-block;\n  height: 1.5em;\n  width: 1.5em;\n  position: relative;\n}\n\n.multi-file-input--RemoveSelectedFileIcon {\n  cursor: pointer;\n}\n\n.multi-file-input--AddFileButtonWrapper {\n  display: inline-block;\n  position: relative;\n}\n\n.multi-file-input--FileInput {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
