// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-checklist-list-item-layout {
  display: block;
}

.process-task-checklist-list-item-layout--Done {
  opacity: 0.7;
}

.process-task-checklist-list-item-layout--MainRow {
  display: flex;
  flex-flow: row nowrap;
}

.process-task-checklist-list-item-layout--Checkbox {
  flex: 0 0 auto;
  margin-right: 17px;
  align-self: flex-start;
}

.process-task-checklist-list-item-layout--TextInput {
  flex: 1 1 auto;
  min-width: 0;
}

.process-task-checklist-list-item-layout--Footer {
  margin-top: 17px;
  margin-bottom: -10px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-checklist/process-task-checklist-list-item-layout/process-task-checklist-list-item-layout.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":["process-task-checklist-list-item-layout {\n  display: block;\n}\n\n.process-task-checklist-list-item-layout--Done {\n  opacity: 0.7;\n}\n\n.process-task-checklist-list-item-layout--MainRow {\n  display: flex;\n  flex-flow: row nowrap;\n}\n\n.process-task-checklist-list-item-layout--Checkbox {\n  flex: 0 0 auto;\n  margin-right: 17px;\n  align-self: flex-start;\n}\n\n.process-task-checklist-list-item-layout--TextInput {\n  flex: 1 1 auto;\n  min-width: 0;\n}\n\n.process-task-checklist-list-item-layout--Footer {\n  margin-top: 17px;\n  margin-bottom: -10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
