// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `user-group-users-widget {
  display: block;
}

/* these styles are also shared with the user-group-user-widget */

.user-group-users-widget--RemoveUserSpecColumn {
  flex: 0 0 25px;
  cursor: pointer;
}

.user-group-users-widget--NameColumn {
  flex: 1 1 auto;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-group-users-widget--CheckboxColumn {
  flex: 0 0 100px;
  text-align: center;
  align-self: center;
}

.user-group-users-widget--UserWidgetsHeader {
  display: flex;
  margin-bottom: 7px;

  /* TODO: use a recordit grey */
  color: #878787;
}

@media (max-width: 767px) {
  .user-group-users-widget--CheckboxColumn {
    flex: 0 0 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/userGroupComponents/user-group-users-widget/user-group-users-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA,iEAAiE;;AAEjE;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;;EAEd,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;;EAElB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":["user-group-users-widget {\n  display: block;\n}\n\n/* these styles are also shared with the user-group-user-widget */\n\n.user-group-users-widget--RemoveUserSpecColumn {\n  flex: 0 0 25px;\n  cursor: pointer;\n}\n\n.user-group-users-widget--NameColumn {\n  flex: 1 1 auto;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.user-group-users-widget--CheckboxColumn {\n  flex: 0 0 100px;\n  text-align: center;\n  align-self: center;\n}\n\n.user-group-users-widget--UserWidgetsHeader {\n  display: flex;\n  margin-bottom: 7px;\n\n  /* TODO: use a recordit grey */\n  color: #878787;\n}\n\n@media (max-width: 767px) {\n  .user-group-users-widget--CheckboxColumn {\n    flex: 0 0 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
