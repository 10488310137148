// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-plan-based-overview-marker {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
}

.gallery-thing-plan-based-overview-marker--NoPositionAvailable {
  visibility: hidden;
}

.gallery-thing-plan-based-overview-marker--Marker {
  width: 25px;
  transform: translate(-50%, -100%);
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-plan-based-overview-marker/gallery-thing-plan-based-overview-marker.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iCAAiC;AACnC","sourcesContent":["gallery-thing-plan-based-overview-marker {\n  display: block;\n  position: absolute;\n  height: 0;\n  width: 0;\n  cursor: pointer;\n}\n\n.gallery-thing-plan-based-overview-marker--NoPositionAvailable {\n  visibility: hidden;\n}\n\n.gallery-thing-plan-based-overview-marker--Marker {\n  width: 25px;\n  transform: translate(-50%, -100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
