// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `assignee-invitation-email-widget {
  display: block;
}

.assignee-invitation-email-widget--BottomBar {
  display: flex;
  align-items: center;
}

.assignee-invitation-email-widget--Message {
  margin: 0px 0px 0px 16px;
}

.assignee-invitation-email-widget--SuccessMessage {
  color: var(--record-it-color-green);
}

.assignee-invitation-email-widget--ErrorMessage {
  color: var(--record-it-color-red);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/assignee-invitation-email-widget/assignee-invitation-email-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["assignee-invitation-email-widget {\n  display: block;\n}\n\n.assignee-invitation-email-widget--BottomBar {\n  display: flex;\n  align-items: center;\n}\n\n.assignee-invitation-email-widget--Message {\n  margin: 0px 0px 0px 16px;\n}\n\n.assignee-invitation-email-widget--SuccessMessage {\n  color: var(--record-it-color-green);\n}\n\n.assignee-invitation-email-widget--ErrorMessage {\n  color: var(--record-it-color-red);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
