// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-entry-xml-file-dialog--Heading {
  font-size: 14px;
  margin-bottom: 17px;
}

.import-entry-xml-file-dialog--EntryToCreate
  + .import-entry-xml-file-dialog--EntryToCreate {
  margin-top: 17px;
}

.import-entry-xml-file-dialog--EntryToCreateTitle {
  font-weight: 500;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/import-entry-xml-file-dialog/import-entry-xml-file-dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".import-entry-xml-file-dialog--Heading {\n  font-size: 14px;\n  margin-bottom: 17px;\n}\n\n.import-entry-xml-file-dialog--EntryToCreate\n  + .import-entry-xml-file-dialog--EntryToCreate {\n  margin-top: 17px;\n}\n\n.import-entry-xml-file-dialog--EntryToCreateTitle {\n  font-weight: 500;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
