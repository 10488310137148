// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-checkbox {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.custom-checkbox--MultistateCheckbox {
  width: 100%;
  display: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/custom-checkbox/custom-checkbox.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["custom-checkbox {\n  display: inline-block;\n  vertical-align: middle;\n  max-width: 100%;\n}\n\n.custom-checkbox--MultistateCheckbox {\n  width: 100%;\n  display: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
