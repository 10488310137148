// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-details {
  display: block;
}

edit-details > * + * {
  padding-top: 1em;
}

edit-details:not([data-border='none']) > * + * {
  border-top: 2px solid var(--record-it-color-gray-300);
}

.edit-details--Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-details--Title {
  margin: 0.2em 0;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/edit-details/edit-details.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["edit-details {\n  display: block;\n}\n\nedit-details > * + * {\n  padding-top: 1em;\n}\n\nedit-details:not([data-border='none']) > * + * {\n  border-top: 2px solid var(--record-it-color-gray-300);\n}\n\n.edit-details--Header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.edit-details--Title {\n  margin: 0.2em 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
