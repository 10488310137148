// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `entry-picture-preview {
  position: relative;

  display: inline-block;
  width: 200px;
  height: 200px;
}

.entry-picture-preview--PicturePreview {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: auto;
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/picture/entry-picture-preview/entry-picture-preview.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,qBAAqB;EACrB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;;EAElB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;;EAEP,YAAY;EACZ,WAAW;AACb","sourcesContent":["entry-picture-preview {\n  position: relative;\n\n  display: inline-block;\n  width: 200px;\n  height: 200px;\n}\n\n.entry-picture-preview--PicturePreview {\n  position: absolute;\n\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  height: auto;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
