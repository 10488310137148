// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `sharepoint-list-column-to-thing-mapping-list-item {
  display: block;
}

.sharepoint-list-column-to-thing-mapping-list-item--SynchronizationDirectionColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sharepoint-list-column-to-thing-mapping-list-item--ButtonContainer {
  display: flex;
  align-items: center;
}

.sharepoint-list-column-to-thing-mapping-list-item--RedStatusIcon {
  color: var(--record-it-color-red);
}

.sharepoint-list-column-to-thing-mapping-list-item--GreenStatusIcon {
  color: var(--record-it-color-green);
}
`, "",{"version":3,"sources":["webpack://./src/sharepointSynchronizationComponents/sharepoint-list-column-to-thing-mapping-list-item/sharepoint-list-column-to-thing-mapping-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":["sharepoint-list-column-to-thing-mapping-list-item {\n  display: block;\n}\n\n.sharepoint-list-column-to-thing-mapping-list-item--SynchronizationDirectionColumn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.sharepoint-list-column-to-thing-mapping-list-item--ButtonContainer {\n  display: flex;\n  align-items: center;\n}\n\n.sharepoint-list-column-to-thing-mapping-list-item--RedStatusIcon {\n  color: var(--record-it-color-red);\n}\n\n.sharepoint-list-column-to-thing-mapping-list-item--GreenStatusIcon {\n  color: var(--record-it-color-green);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
