// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-template-entry-group-item {
  display: block;
  background-color: var(--record-it-color-gray-500);
  border-radius: 3px;
}

.structure-template-entry-group-item--ListItem,
.structure-template-entry-group-item--ListItem:hover {
  background: none;
  box-shadow: inherit;
}

.structure-template-entry-group-item--Container {
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/structureTemplateComponents/structure-template-entry-group-item/structure-template-entry-group-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":["structure-template-entry-group-item {\n  display: block;\n  background-color: var(--record-it-color-gray-500);\n  border-radius: 3px;\n}\n\n.structure-template-entry-group-item--ListItem,\n.structure-template-entry-group-item--ListItem:hover {\n  background: none;\n  box-shadow: inherit;\n}\n\n.structure-template-entry-group-item--Container {\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
