// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `recurrence-input {
  display: block;
}

.recurrence-input--InlineBlock {
  display: inline-block;
}

.recurrence-input--InlineSelect {
  display: inline-block;
  width: 120px;
}

.recurrence-input--InlineInput {
  display: inline-block;
  width: 30px;
}

.recurrence-input--EndSelectBlock {
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/recurrence-input/recurrence-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["recurrence-input {\n  display: block;\n}\n\n.recurrence-input--InlineBlock {\n  display: inline-block;\n}\n\n.recurrence-input--InlineSelect {\n  display: inline-block;\n  width: 120px;\n}\n\n.recurrence-input--InlineInput {\n  display: inline-block;\n  width: 30px;\n}\n\n.recurrence-input--EndSelectBlock {\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
