// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-comments-widget-comment-card {
  display: block;
}

.process-task-comments-widget-comment-card--CommentText {
  display: inline-block;
  white-space: pre-wrap;
}

.process-task-comments-widget-comment-card--ProcessConfigurationStep {
  color: var(--record-it-color-gray-600);
  display: inline-block;
  font-size: 11px;
  margin-right: 24px; /* space for the delete icon to prevent collisions */
}

.process-task-comments-widget-comment-card--IconWrapper {
  position: relative;
  width: 24px;
}

.process-task-comments-widget-comment-card--DeleteIcon {
  position: absolute;
  bottom: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-comments-widget/process-task-comments-widget-comment-card.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;EACtC,qBAAqB;EACrB,eAAe;EACf,kBAAkB,EAAE,oDAAoD;AAC1E;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV","sourcesContent":["process-task-comments-widget-comment-card {\n  display: block;\n}\n\n.process-task-comments-widget-comment-card--CommentText {\n  display: inline-block;\n  white-space: pre-wrap;\n}\n\n.process-task-comments-widget-comment-card--ProcessConfigurationStep {\n  color: var(--record-it-color-gray-600);\n  display: inline-block;\n  font-size: 11px;\n  margin-right: 24px; /* space for the delete icon to prevent collisions */\n}\n\n.process-task-comments-widget-comment-card--IconWrapper {\n  position: relative;\n  width: 24px;\n}\n\n.process-task-comments-widget-comment-card--DeleteIcon {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
