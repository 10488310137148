// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `lat-long-area-input {
  display: inline-block;
}

.lat-long-area-input--ContentWrapper {
  margin-left: -10px;
  margin-top: -10px;
}

.lat-long-area-input--ContentWrapper > * {
  margin-left: 10px;
  margin-top: 10px;
}

.lat-long-area-input--PositionInputContainer {
  display: inline-block;
}

.lat-long-area-input--PositionInput {
  display: inline-block;
  width: 70px;
}

.lat-long-area-input--PositionInputSeparator {
  padding: 0px 7px;
  display: inline-block;
}

.lat-long-area-input--LabelWarning {
  color: orange;
}

.lat-long-area--SelectCoordinatesIcon {
  vertical-align: center;
  margin-left: 5px;
  cursor: pointer;
}

.lat-long-area-input--DistanceInputContainer {
  display: inline-block;
}

.lat-long-area-input--DistanceInput {
  display: inline-block;
  width: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/lat-long-area-input/lat-long-area-input.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb","sourcesContent":["lat-long-area-input {\n  display: inline-block;\n}\n\n.lat-long-area-input--ContentWrapper {\n  margin-left: -10px;\n  margin-top: -10px;\n}\n\n.lat-long-area-input--ContentWrapper > * {\n  margin-left: 10px;\n  margin-top: 10px;\n}\n\n.lat-long-area-input--PositionInputContainer {\n  display: inline-block;\n}\n\n.lat-long-area-input--PositionInput {\n  display: inline-block;\n  width: 70px;\n}\n\n.lat-long-area-input--PositionInputSeparator {\n  padding: 0px 7px;\n  display: inline-block;\n}\n\n.lat-long-area-input--LabelWarning {\n  color: orange;\n}\n\n.lat-long-area--SelectCoordinatesIcon {\n  vertical-align: center;\n  margin-left: 5px;\n  cursor: pointer;\n}\n\n.lat-long-area-input--DistanceInputContainer {\n  display: inline-block;\n}\n\n.lat-long-area-input--DistanceInput {\n  display: inline-block;\n  width: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
