// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `operations-calendar-user-legend-group {
  display: block;
}

.operations-calendar-user-legend-group--LegendList {
  display: flex;
  width: 175px;
}

.operations-calendar-user-legend-group--LegendCheckbox {
  align-self: center;
}

.operations-calendar-user-legend-group--NameLabel {
  background-color: #fff;
  flex-grow: 1;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.operations-calendar-user-legend-group--LabelColorBar {
  width: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/operations-calendar-user-legend/operations-calendar-user-legend-group/operations-calendar-user-legend-group.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;;EAEhB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["operations-calendar-user-legend-group {\n  display: block;\n}\n\n.operations-calendar-user-legend-group--LegendList {\n  display: flex;\n  width: 175px;\n}\n\n.operations-calendar-user-legend-group--LegendCheckbox {\n  align-self: center;\n}\n\n.operations-calendar-user-legend-group--NameLabel {\n  background-color: #fff;\n  flex-grow: 1;\n  padding: 7px;\n  font-size: 14px;\n  font-weight: 500;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.operations-calendar-user-legend-group--LabelColorBar {\n  width: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
