// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `record-it-dialog-columns {
  display: block;

  --record-it-dialog-columns--WrapperFlexDirection: row;
  --record-it-dialog-columns--SideColumnFlex: 0 0 25%;
  --record-it-dialog-columns--SideColumnMaxWidth: 20%;
}

@media (max-width: 768px) {
  record-it-dialog-columns {
    --record-it-dialog-columns--WrapperFlexDirection: column;
    --record-it-dialog-columns--SideColumnFlex: 0 0 auto;
    --record-it-dialog-columns--SideColumnMaxWidth: initial;
  }
}

.record-it-dialog-columns--Wrapper {
  display: flex;
  flex-direction: var(--record-it-dialog-columns--WrapperFlexDirection);
  justify-content: space-between;
}

.record-it-dialog-columns--SideColumn {
  flex: var(--record-it-dialog-columns--SideColumnFlex);
  min-width: 0;
  max-width: var(--record-it-dialog-columns--SideColumnMaxWidth);
}

.record-it-dialog-columns--MainColumn {
  flex: 0 0 50%;
  max-width: 1024px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/record-it-dialog-columns/record-it-dialog-columns.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,qDAAqD;EACrD,mDAAmD;EACnD,mDAAmD;AACrD;;AAEA;EACE;IACE,wDAAwD;IACxD,oDAAoD;IACpD,uDAAuD;EACzD;AACF;;AAEA;EACE,aAAa;EACb,qEAAqE;EACrE,8BAA8B;AAChC;;AAEA;EACE,qDAAqD;EACrD,YAAY;EACZ,8DAA8D;AAChE;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB","sourcesContent":["record-it-dialog-columns {\n  display: block;\n\n  --record-it-dialog-columns--WrapperFlexDirection: row;\n  --record-it-dialog-columns--SideColumnFlex: 0 0 25%;\n  --record-it-dialog-columns--SideColumnMaxWidth: 20%;\n}\n\n@media (max-width: 768px) {\n  record-it-dialog-columns {\n    --record-it-dialog-columns--WrapperFlexDirection: column;\n    --record-it-dialog-columns--SideColumnFlex: 0 0 auto;\n    --record-it-dialog-columns--SideColumnMaxWidth: initial;\n  }\n}\n\n.record-it-dialog-columns--Wrapper {\n  display: flex;\n  flex-direction: var(--record-it-dialog-columns--WrapperFlexDirection);\n  justify-content: space-between;\n}\n\n.record-it-dialog-columns--SideColumn {\n  flex: var(--record-it-dialog-columns--SideColumnFlex);\n  min-width: 0;\n  max-width: var(--record-it-dialog-columns--SideColumnMaxWidth);\n}\n\n.record-it-dialog-columns--MainColumn {\n  flex: 0 0 50%;\n  max-width: 1024px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
