// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-positions-widget-positions-list {
  display: block;
}

.process-task-positions-widget-positions-list--CategoryHeader {
  margin-bottom: 20px;
  padding-left: 23px; /* list item column padding + width of list item color bar */
}

.process-task-positions-widget-positions-list--CategoryHeaderCheckboxHidden {
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-positions-widget/process-task-positions-widget-positions-list/process-task-positions-widget-positions-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB,EAAE,4DAA4D;AAClF;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["process-task-positions-widget-positions-list {\n  display: block;\n}\n\n.process-task-positions-widget-positions-list--CategoryHeader {\n  margin-bottom: 20px;\n  padding-left: 23px; /* list item column padding + width of list item color bar */\n}\n\n.process-task-positions-widget-positions-list--CategoryHeaderCheckboxHidden {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
