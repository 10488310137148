// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `animated-icon-error {
  display: inline-block;
  height: 3em;
  width: 3em;
}

.animated-icon-error--Circle {
  stroke: #dc311e;
  stroke-width: 5px;
  fill: none;
  animation-name: animated-icon-error-circle-animation;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.animated-icon-error--Line1,
.animated-icon-error--Line2 {
  stroke: #dc311e;
  stroke-width: 6px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  fill: none;

  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.animated-icon-error--Line1 {
  animation-name: animated-icon-error-line-animation;
  animation-delay: 0.55s;
}

.animated-icon-error--Line2 {
  animation-name: animated-icon-error-line-animation;
  animation-delay: 0.35s;
}

@keyframes animated-icon-error-circle-animation {
  0% {
    stroke-dasharray: 0 252 1000;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    stroke-dasharray: 126 0 1000;
  }
}

@keyframes animated-icon-error-line-animation {
  0% {
    stroke-dasharray: 0 10000;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    stroke-dasharray: 57 10000;
  }
}
`, "",{"version":3,"sources":["webpack://./src/animatedIcon/animated-icon-error/animated-icon-error.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,oDAAoD;EACpD,wBAAwB;EACxB,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;;EAEE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,sBAAsB;EACtB,oBAAoB;EACpB,UAAU;;EAEV,wBAAwB;EACxB,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,kDAAkD;EAClD,sBAAsB;AACxB;;AAEA;EACE,kDAAkD;EAClD,sBAAsB;AACxB;;AAEA;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,yBAAyB;IACzB,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["animated-icon-error {\n  display: inline-block;\n  height: 3em;\n  width: 3em;\n}\n\n.animated-icon-error--Circle {\n  stroke: #dc311e;\n  stroke-width: 5px;\n  fill: none;\n  animation-name: animated-icon-error-circle-animation;\n  animation-duration: 0.3s;\n  animation-iteration-count: 1;\n  animation-fill-mode: both;\n}\n\n.animated-icon-error--Line1,\n.animated-icon-error--Line2 {\n  stroke: #dc311e;\n  stroke-width: 6px;\n  stroke-linecap: butt;\n  stroke-linejoin: miter;\n  stroke-miterlimit: 4;\n  fill: none;\n\n  animation-duration: 0.2s;\n  animation-iteration-count: 1;\n  animation-fill-mode: both;\n}\n\n.animated-icon-error--Line1 {\n  animation-name: animated-icon-error-line-animation;\n  animation-delay: 0.55s;\n}\n\n.animated-icon-error--Line2 {\n  animation-name: animated-icon-error-line-animation;\n  animation-delay: 0.35s;\n}\n\n@keyframes animated-icon-error-circle-animation {\n  0% {\n    stroke-dasharray: 0 252 1000;\n    opacity: 0;\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    stroke-dasharray: 126 0 1000;\n  }\n}\n\n@keyframes animated-icon-error-line-animation {\n  0% {\n    stroke-dasharray: 0 10000;\n    opacity: 0;\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    stroke-dasharray: 57 10000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
