// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-task-groups--FilterSelect {
  margin-bottom: 20px;
}

.edit-process-task-groups--FilterBar {
  margin-bottom: 47px;
}

/* this is just a quick way to satisfy Trockenmax. A configurable or an automatically calculated version will be implemented later on */
.edit-process-task-groups--SiteContainer {
  max-width: 1350px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/edit_process_task_groups/edit_process_task_groups.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA,uIAAuI;AACvI;EACE,iBAAiB;AACnB","sourcesContent":[".edit-process-task-groups--FilterSelect {\n  margin-bottom: 20px;\n}\n\n.edit-process-task-groups--FilterBar {\n  margin-bottom: 47px;\n}\n\n/* this is just a quick way to satisfy Trockenmax. A configurable or an automatically calculated version will be implemented later on */\n.edit-process-task-groups--SiteContainer {\n  max-width: 1350px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
