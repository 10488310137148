// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-group-filter-select {
  display: block;
}

.process-task-group-filter-select--Container {
  display: flex;
  align-items: center;
}

.process-task-group-filter-select--Select {
  flex: 0 0 auto;
}

.process-task-group-filter-select--QuickSelectButton {
  flex: 0 1 auto;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-group-filter-select/process-task-group-filter-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["process-task-group-filter-select {\n  display: block;\n}\n\n.process-task-group-filter-select--Container {\n  display: flex;\n  align-items: center;\n}\n\n.process-task-group-filter-select--Select {\n  flex: 0 0 auto;\n}\n\n.process-task-group-filter-select--QuickSelectButton {\n  flex: 0 1 auto;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
