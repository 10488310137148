// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-devices-list-item {
  display: block;
  background-color: var(--record-it-color-white);
}

.process-task-appointment-devices-list-item--DeviceStatusSymbol {
  font-size: 32px;
  transition: all 0.2s;
  margin-right: 10px;
  cursor: pointer;
  overflow: visible;
  color: #4a4a4a;

  transform: translate(
    -50%,
    calc(-50% - 5px)
  ); /* because of the arrow icon above the status, we don't want everything to be exactly centered, 5px is just a value that makes it look good */
  top: 50%;
  position: absolute;
  left: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-devices-list/process-task-appointment-devices-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,8CAA8C;AAChD;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,cAAc;;EAEd;;;GAGC,EAAE,6IAA6I;EAChJ,QAAQ;EACR,kBAAkB;EAClB,SAAS;AACX","sourcesContent":["process-task-appointment-devices-list-item {\n  display: block;\n  background-color: var(--record-it-color-white);\n}\n\n.process-task-appointment-devices-list-item--DeviceStatusSymbol {\n  font-size: 32px;\n  transition: all 0.2s;\n  margin-right: 10px;\n  cursor: pointer;\n  overflow: visible;\n  color: #4a4a4a;\n\n  transform: translate(\n    -50%,\n    calc(-50% - 5px)\n  ); /* because of the arrow icon above the status, we don't want everything to be exactly centered, 5px is just a value that makes it look good */\n  top: 50%;\n  position: absolute;\n  left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
