// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-list-item {
  display: block;
}

.defect-list-item--TagList {
  white-space: normal;
  line-height: 0rem;
}

.defect-list-item--ItemWithStatebar {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.record-it-entity-list-item
  > .record-it-entity-list-item-column
  > .defect-list-item--Tag {
  display: inline-block;
}

.defect-list-item--StateBar {
  height: 100%;
  flex: 0 0 8px;
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.defect-list-item--StateBar[data-status='open'] {
  background-color: var(--record-it-color-defect-state-open);
}

.defect-list-item--StateBar[data-status='processed'] {
  background-color: var(--record-it-color-defect-state-processed);
}

.defect-list-item--StateBar[data-status='done'] {
  background-color: var(--record-it-color-defect-state-done);
}

.defect-list-item--DefectProtocol {
  margin: 10px;
  --defect-comment-color-background: var(--record-it-color-white);
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-list-item/defect-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uDAAuD;EACvD,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,0DAA0D;AAC5D;;AAEA;EACE,+DAA+D;AACjE;;AAEA;EACE,0DAA0D;AAC5D;;AAEA;EACE,YAAY;EACZ,+DAA+D;AACjE","sourcesContent":["defect-list-item {\n  display: block;\n}\n\n.defect-list-item--TagList {\n  white-space: normal;\n  line-height: 0rem;\n}\n\n.defect-list-item--ItemWithStatebar {\n  border-top-right-radius: 0px;\n  border-bottom-right-radius: 0px;\n}\n\n.record-it-entity-list-item\n  > .record-it-entity-list-item-column\n  > .defect-list-item--Tag {\n  display: inline-block;\n}\n\n.defect-list-item--StateBar {\n  height: 100%;\n  flex: 0 0 8px;\n  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);\n  border-top-right-radius: 3px;\n  border-bottom-right-radius: 3px;\n}\n\n.defect-list-item--StateBar[data-status='open'] {\n  background-color: var(--record-it-color-defect-state-open);\n}\n\n.defect-list-item--StateBar[data-status='processed'] {\n  background-color: var(--record-it-color-defect-state-processed);\n}\n\n.defect-list-item--StateBar[data-status='done'] {\n  background-color: var(--record-it-color-defect-state-done);\n}\n\n.defect-list-item--DefectProtocol {\n  margin: 10px;\n  --defect-comment-color-background: var(--record-it-color-white);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
