// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `scaled-marked-picture {
  display: block;
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;
}

.scaled-marked-picture--ContentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/picture/scaled-marked-picture/scaled-marked-picture.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;AACb","sourcesContent":["scaled-marked-picture {\n  display: block;\n  height: 100px;\n  width: 100px;\n  position: relative;\n  overflow: hidden;\n}\n\n.scaled-marked-picture--ContentWrapper {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
