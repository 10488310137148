// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `coordinate-input {
  display: block;
}

.coordinate-input--Container {
  display: flex;
  align-items: baseline;
}

coordinate-input[data-inline] .coordinate-input--CoordinateContainer {
  display: flex;
  align-items: baseline;
}

.coordinate-input--PositionInputSeparator {
  padding: 0px 7px;
  display: none;
}

coordinate-input[data-inline] .coordinate-input--PositionInputSeparator {
  display: inline-block;
}

.coordinate-input--IconButton {
  position: relative;
  align-self: center;
  line-height: 0;
  margin-top: -3px;
}

.coordinate-input--IconButton > .record-it-icon {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  vertical-align: middle;
  color: var(--record-it-color-secondary);
  cursor: pointer;
}

.coordinate-input--IconButton > .record-it-icon:hover {
  color: var(--record-it-color-primary);
}

floating-label-input.coordinate-input--InputNotEditing * {
  border-color: transparent;
  color: rgb(74, 74, 74) !important;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/coordinate-input/coordinate-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,uCAAuC;EACvC,eAAe;AACjB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,iCAAiC;AACnC","sourcesContent":["coordinate-input {\n  display: block;\n}\n\n.coordinate-input--Container {\n  display: flex;\n  align-items: baseline;\n}\n\ncoordinate-input[data-inline] .coordinate-input--CoordinateContainer {\n  display: flex;\n  align-items: baseline;\n}\n\n.coordinate-input--PositionInputSeparator {\n  padding: 0px 7px;\n  display: none;\n}\n\ncoordinate-input[data-inline] .coordinate-input--PositionInputSeparator {\n  display: inline-block;\n}\n\n.coordinate-input--IconButton {\n  position: relative;\n  align-self: center;\n  line-height: 0;\n  margin-top: -3px;\n}\n\n.coordinate-input--IconButton > .record-it-icon {\n  width: 34px;\n  height: 34px;\n  line-height: 34px;\n  font-size: 16px;\n  vertical-align: middle;\n  color: var(--record-it-color-secondary);\n  cursor: pointer;\n}\n\n.coordinate-input--IconButton > .record-it-icon:hover {\n  color: var(--record-it-color-primary);\n}\n\nfloating-label-input.coordinate-input--InputNotEditing * {\n  border-color: transparent;\n  color: rgb(74, 74, 74) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
