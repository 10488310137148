// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-position-detail-entries-widget {
  display: block;
}

.process-task-position-detail-entries-widget--Row {
  display: flex;
  flex-flow: row nowrap;
  padding: 17px 0;
}

.process-task-position-detail-entries-widget--PropertyColumn {
  align-self: center;
  flex: 0 0
    clamp(50px, calc(11px * var(--maximum-property-value-length, 0)), 200px);
}

.process-task-position-detail-entries-widget--AmountColumn {
  flex: 0 0 100px;
  padding-left: 24px;
}

.process-task-position-detail-entries-widget--UnitColumn {
  align-self: center;
  flex: 1 1 auto;
  padding-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-position-detail-entries-widget/process-task-position-detail-entries-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB;4EAC0E;AAC5E;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["process-task-position-detail-entries-widget {\n  display: block;\n}\n\n.process-task-position-detail-entries-widget--Row {\n  display: flex;\n  flex-flow: row nowrap;\n  padding: 17px 0;\n}\n\n.process-task-position-detail-entries-widget--PropertyColumn {\n  align-self: center;\n  flex: 0 0\n    clamp(50px, calc(11px * var(--maximum-property-value-length, 0)), 200px);\n}\n\n.process-task-position-detail-entries-widget--AmountColumn {\n  flex: 0 0 100px;\n  padding-left: 24px;\n}\n\n.process-task-position-detail-entries-widget--UnitColumn {\n  align-self: center;\n  flex: 1 1 auto;\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
