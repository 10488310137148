// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-configuration-dialog--CheckboxTable {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  gap: 8px;
  align-items: center;
}

.edit-process-configuration-dialog--MissedAppointmentsWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.edit-process-configuration-dialog--MissedAppointmentsWrapperUserSelect {
  flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-configuration-dialog/edit-process-configuration-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6DAA6D;EAC7D,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".edit-process-configuration-dialog--CheckboxTable {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));\n  gap: 8px;\n  align-items: center;\n}\n\n.edit-process-configuration-dialog--MissedAppointmentsWrapper {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.edit-process-configuration-dialog--MissedAppointmentsWrapperUserSelect {\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
