// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist-project-question-pictures-widget {
  display: block;
}

.checklist-project-question-pictures-widget--Pictures {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
}

.checklist-project-question-pictures-widget--Picture {
  height: 140px;
  width: 140px;
  aspect-ratio: 1;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/checklistComponents/checklist-project-question-pictures-widget/checklist-project-question-pictures-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,eAAe;AACjB","sourcesContent":["checklist-project-question-pictures-widget {\n  display: block;\n}\n\n.checklist-project-question-pictures-widget--Pictures {\n  display: flex;\n  gap: 1.2rem;\n  flex-wrap: wrap;\n}\n\n.checklist-project-question-pictures-widget--Picture {\n  height: 140px;\n  width: 140px;\n  aspect-ratio: 1;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
