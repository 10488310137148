// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-group-authorizations-widget {
  display: block;
}

.process-task-group-authorizations-widget--AddButton {
  margin-top: 12px;

  /* add a little bit of margin, so the border around the button will not land outside the container and vanish if the parent container has overflow: hidden */
  margin-left: 2px;
  margin-bottom: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-group-authorizations-widget/process-task-group-authorizations-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;;EAEhB,4JAA4J;EAC5J,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["process-task-group-authorizations-widget {\n  display: block;\n}\n\n.process-task-group-authorizations-widget--AddButton {\n  margin-top: 12px;\n\n  /* add a little bit of margin, so the border around the button will not land outside the container and vanish if the parent container has overflow: hidden */\n  margin-left: 2px;\n  margin-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
