// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `date-time-picker-number-input {
  display: inline-block;
  border-bottom: 1px solid var(--record-it-color-gray-500);
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 1px; /* extra space for the focused line */
  position: relative;
}

.date-time-picker-number-input--Input {
  background: transparent;
  border: none;
  caret-color: var(--record-it-color-primary);
  height: 30px;
  text-overflow: ellipsis;
  text-align: center;
}

.date-time-picker-number-input--Input:disabled {
  cursor: not-allowed;
}

.date-time-picker-number-input--Input:disabled,
.date-time-picker-number-input--Input:disabled::placeholder {
  color: var(--record-it-color-disabled);
}

.date-time-picker-number-input--FocusLine {
  background: var(--record-it-color-primary);
  content: ' ';
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 100%;
  transform: scaleX(0);
  transition:
    transform 0.3s,
    opacity 0.3s ease;
}

.date-time-picker-number-input--Input:focus
  + .date-time-picker-number-input--FocusLine {
  opacity: 1;
  transform: scaleX(1);
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/date-time-picker/date-time-picker-number-input/date-time-picker-number-input.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,wDAAwD;EACxD,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB,EAAE,qCAAqC;EACzD,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,2CAA2C;EAC3C,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,sCAAsC;AACxC;;AAEA;EACE,0CAA0C;EAC1C,YAAY;EACZ,WAAW;EACX,OAAO;EACP,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,oBAAoB;EACpB;;qBAEmB;AACrB;;AAEA;;EAEE,UAAU;EACV,oBAAoB;AACtB","sourcesContent":["date-time-picker-number-input {\n  display: inline-block;\n  border-bottom: 1px solid var(--record-it-color-gray-500);\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  margin-bottom: 1px; /* extra space for the focused line */\n  position: relative;\n}\n\n.date-time-picker-number-input--Input {\n  background: transparent;\n  border: none;\n  caret-color: var(--record-it-color-primary);\n  height: 30px;\n  text-overflow: ellipsis;\n  text-align: center;\n}\n\n.date-time-picker-number-input--Input:disabled {\n  cursor: not-allowed;\n}\n\n.date-time-picker-number-input--Input:disabled,\n.date-time-picker-number-input--Input:disabled::placeholder {\n  color: var(--record-it-color-disabled);\n}\n\n.date-time-picker-number-input--FocusLine {\n  background: var(--record-it-color-primary);\n  content: ' ';\n  height: 2px;\n  left: 0;\n  opacity: 0;\n  position: absolute;\n  right: 0;\n  top: 100%;\n  transform: scaleX(0);\n  transition:\n    transform 0.3s,\n    opacity 0.3s ease;\n}\n\n.date-time-picker-number-input--Input:focus\n  + .date-time-picker-number-input--FocusLine {\n  opacity: 1;\n  transform: scaleX(1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
