// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-process-task-position-dialog--CreationInputFieldsWrapper
  > *:first-child {
  margin-top: 0;
}

.create-process-task-position-dialog--CreationInputFieldsWrapper
  > *:last-child {
  margin-bottom: 7px;
}

.create-process-task-position-dialog--ShowPicturesBtn {
  display: inline-block;
  margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/create-process-task-position-dialog/create-process-task-position-dialog.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;AACf;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".create-process-task-position-dialog--CreationInputFieldsWrapper\n  > *:first-child {\n  margin-top: 0;\n}\n\n.create-process-task-position-dialog--CreationInputFieldsWrapper\n  > *:last-child {\n  margin-bottom: 7px;\n}\n\n.create-process-task-position-dialog--ShowPicturesBtn {\n  display: inline-block;\n  margin-bottom: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
