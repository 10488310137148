// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `positioned-picture-zoom-box-overlay-marker {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
}

.positioned-picture-zoom-box-overlay-marker--NoPositionAvailable {
  visibility: hidden;
}

.positioned-picture-zoom-box-overlay-marker--Marker {
  width: 25px;
  transform: translate(-50%, -100%);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/positioned-picture-zoom-box-overlay-marker/positioned-picture-zoom-box-overlay-marker.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iCAAiC;AACnC","sourcesContent":["positioned-picture-zoom-box-overlay-marker {\n  display: block;\n  position: absolute;\n  height: 0;\n  width: 0;\n}\n\n.positioned-picture-zoom-box-overlay-marker--NoPositionAvailable {\n  visibility: hidden;\n}\n\n.positioned-picture-zoom-box-overlay-marker--Marker {\n  width: 25px;\n  transform: translate(-50%, -100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
