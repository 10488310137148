// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-text-brick-templates-dialog {
  display: block;
}

.edit-text-brick-templates-dialog--AddTextBrickTemplateButton {
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-text-brick-templates-dialog/edit-text-brick-templates-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["edit-text-brick-templates-dialog {\n  display: block;\n}\n\n.edit-text-brick-templates-dialog--AddTextBrickTemplateButton {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
