// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `focus-set-feedback {
  display: block;
  --focus-set-feedback--icon-size: 85px;
}

.focus-set-feedback--Icon {
  font-size: 55px;
  text-align: center;
  line-height: var(--focus-set-feedback--icon-size);
  height: var(--focus-set-feedback--icon-size);
  width: var(--focus-set-feedback--icon-size);
}

.focus-set-feedback--FocusSetIconContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/camera-overlay/focus-set-feedback/focus-set-feedback.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,iDAAiD;EACjD,4CAA4C;EAC5C,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;AACd","sourcesContent":["focus-set-feedback {\n  display: block;\n  --focus-set-feedback--icon-size: 85px;\n}\n\n.focus-set-feedback--Icon {\n  font-size: 55px;\n  text-align: center;\n  line-height: var(--focus-set-feedback--icon-size);\n  height: var(--focus-set-feedback--icon-size);\n  width: var(--focus-set-feedback--icon-size);\n}\n\n.focus-set-feedback--FocusSetIconContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
