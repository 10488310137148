// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `text-brick-widget {
  display: block;
}

.text-brick-widget--SelectedTextBricksContainer {
  margin-bottom: 50px;
}

.text-brick-widget--TextBrick {
  padding: 10px;
  border: 1px solid var(--record-it-color-gray-300);
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
  margin-bottom: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 10px;
}

.text-brick-widget--TextBrickValue {
  flex: 1 1 auto;
}

.text-brick-widget--TextBrickValueDiv {
  white-space: pre-line;
}

.text-brick-widget--TextBrickDeleteIcon {
  color: var(--record-it-color-red);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/text-brick-widget/text-brick-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iDAAiD;EACjD,uDAAuD;EACvD,mBAAmB;EACnB,eAAe;;EAEf,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["text-brick-widget {\n  display: block;\n}\n\n.text-brick-widget--SelectedTextBricksContainer {\n  margin-bottom: 50px;\n}\n\n.text-brick-widget--TextBrick {\n  padding: 10px;\n  border: 1px solid var(--record-it-color-gray-300);\n  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);\n  margin-bottom: 10px;\n  cursor: pointer;\n\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.text-brick-widget--TextBrickValue {\n  flex: 1 1 auto;\n}\n\n.text-brick-widget--TextBrickValueDiv {\n  white-space: pre-line;\n}\n\n.text-brick-widget--TextBrickDeleteIcon {\n  color: var(--record-it-color-red);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
