// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ultra-rapid-fire-widget-overlay--RegionSelect {
  height: 100%;
}

.ultra-rapid-fire-widget-overlay--RegionSelect > custom-select {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/overlay-widget-templates/region-select.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".ultra-rapid-fire-widget-overlay--RegionSelect {\n  height: 100%;\n}\n\n.ultra-rapid-fire-widget-overlay--RegionSelect > custom-select {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
