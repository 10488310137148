// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `manage-value-calculation-config-definitions-widget {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/valueCalculationComponents/manage-value-calculation-config-definitions-widget/manage-value-calculation-config-definitions-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":["manage-value-calculation-config-definitions-widget {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
