// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `corresponding-appointments-widget {
  display: block;
}

.corresponding-appointments-widget--CorrespondingAppointmentsWrapper {
  display: flex;
}

.corresponding-appointments-widget--CorrespondingAppointment {
  flex: 1 1 auto;
  margin: 24px 48px;
  padding: 12px;
  border-radius: 3px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-task-recurring-appointment-widget/corresponding-appointments-widget/corresponding-appointments-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;AAC1C","sourcesContent":["corresponding-appointments-widget {\n  display: block;\n}\n\n.corresponding-appointments-widget--CorrespondingAppointmentsWrapper {\n  display: flex;\n}\n\n.corresponding-appointments-widget--CorrespondingAppointment {\n  flex: 1 1 auto;\n  margin: 24px 48px;\n  padding: 12px;\n  border-radius: 3px;\n  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
