// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `config-list-item {
  display: block;
}

.config-list-item--Wrapper {
  display: flex;
  gap: 0.4em;
  align-items: center;
  justify-content: space-between;
}

.config-list-item--DeleteButton {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--record-it-color-gray-800);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/config-list-item/config-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,cAAc;EACd,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sCAAsC;AACxC","sourcesContent":["config-list-item {\n  display: block;\n}\n\n.config-list-item--Wrapper {\n  display: flex;\n  gap: 0.4em;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.config-list-item--DeleteButton {\n  width: 1.5rem;\n  height: 1.5rem;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--record-it-color-gray-800);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
