// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `drop-target {
  display: block;
  position: relative;
  --hit-box-extra-size: var(--drop-target--hit-box-extra-size, 20px);
}

.drop-target--ContainerElementNotAnimated {
  position: relative;
  height: inherit;
}

.drop-target--ActiveContainer {
  position: absolute;

  top: calc(var(--hit-box-extra-size) * -1);
  bottom: calc(var(--hit-box-extra-size) * -1);
  left: 0;
  right: 0;

  z-index: 1;
}

.drop-target--Inside::before {
  box-shadow: 0 0 10px 3px var(--record-it-color-primary);
  opacity: 0.5;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: ' ';
}

.drop-target--InvalidData {
  box-shadow: 0 0 10px 3px rgba(255, 0, 0, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/drop-target/drop-target.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,kEAAkE;AACpE;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;;EAElB,yCAAyC;EACzC,4CAA4C;EAC5C,OAAO;EACP,QAAQ;;EAER,UAAU;AACZ;;AAEA;EACE,uDAAuD;EACvD,YAAY;;EAEZ,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;;EAEP,YAAY;AACd;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":["drop-target {\n  display: block;\n  position: relative;\n  --hit-box-extra-size: var(--drop-target--hit-box-extra-size, 20px);\n}\n\n.drop-target--ContainerElementNotAnimated {\n  position: relative;\n  height: inherit;\n}\n\n.drop-target--ActiveContainer {\n  position: absolute;\n\n  top: calc(var(--hit-box-extra-size) * -1);\n  bottom: calc(var(--hit-box-extra-size) * -1);\n  left: 0;\n  right: 0;\n\n  z-index: 1;\n}\n\n.drop-target--Inside::before {\n  box-shadow: 0 0 10px 3px var(--record-it-color-primary);\n  opacity: 0.5;\n\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  content: ' ';\n}\n\n.drop-target--InvalidData {\n  box-shadow: 0 0 10px 3px rgba(255, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
