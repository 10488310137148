// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-thing-section-config-properties-dialog-property-configs {
  display: block;
}

edit-thing-section-config-properties-dialog-property-configs:first-child
  .edit-thing-section-config-properties-dialog-property-configs--ExpandableContainer {
  margin-top: 0;
}

edit-thing-section-config-properties-dialog-property-configs:last-child
  .edit-thing-section-config-properties-dialog-property-configs--ExpandableContainer {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/thingSectionComponents/edit-thing-section-config-properties-dialog/edit-thing-section-config-properties-dialog-property-configs/edit-thing-section-config-properties-dialog-property-configs.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,gBAAgB;AAClB","sourcesContent":["edit-thing-section-config-properties-dialog-property-configs {\n  display: block;\n}\n\nedit-thing-section-config-properties-dialog-property-configs:first-child\n  .edit-thing-section-config-properties-dialog-property-configs--ExpandableContainer {\n  margin-top: 0;\n}\n\nedit-thing-section-config-properties-dialog-property-configs:last-child\n  .edit-thing-section-config-properties-dialog-property-configs--ExpandableContainer {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
