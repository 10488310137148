// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-details-new-comment {
  display: block;
  margin-bottom: 70px;
}

.defect-details-new-comment--Attachments {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
}

.defect-details-new-comment--Actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.defect-details-new-comment--StateChange {
  font-size: 13px;
}

.defect-details-new-comment--StateChangeDescription {
  color: var(--record-it-color-gray-600);
}

.defect-details-new-comment--StateChangeChoices {
  margin-top: 10px;
}

.defect-details-new-comment--StateChangeChoice {
  margin-right: 12px;
}

/*
  Some more specificity because it conflicts with the base css otherwise
*/
.defect-details-new-comment--StateChangeChoice
  .defect-details-new-comment--StateChangeChoiceRadio {
  display: initial; /* Because custom-radiobutton would set it to block */
  height: 100%;
  margin-top: -2px;
  margin-right: 4px;
  vertical-align: middle;
}

.defect-details-new-comment--SendButton {
  padding-left: 42px;
  padding-right: 42px;
}

@media (max-width: 767px) {
  .defect-details-new-comment--Actions {
    flex-direction: column;
    align-items: flex-start;
  }
  .defect-details-new-comment--SendButton {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-details-new-comment/defect-details-new-comment.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;CAEC;AACD;;EAEE,gBAAgB,EAAE,qDAAqD;EACvE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;EACzB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["defect-details-new-comment {\n  display: block;\n  margin-bottom: 70px;\n}\n\n.defect-details-new-comment--Attachments {\n  margin-top: 10px;\n  display: flex;\n  align-items: flex-end;\n}\n\n.defect-details-new-comment--Actions {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 16px;\n}\n\n.defect-details-new-comment--StateChange {\n  font-size: 13px;\n}\n\n.defect-details-new-comment--StateChangeDescription {\n  color: var(--record-it-color-gray-600);\n}\n\n.defect-details-new-comment--StateChangeChoices {\n  margin-top: 10px;\n}\n\n.defect-details-new-comment--StateChangeChoice {\n  margin-right: 12px;\n}\n\n/*\n  Some more specificity because it conflicts with the base css otherwise\n*/\n.defect-details-new-comment--StateChangeChoice\n  .defect-details-new-comment--StateChangeChoiceRadio {\n  display: initial; /* Because custom-radiobutton would set it to block */\n  height: 100%;\n  margin-top: -2px;\n  margin-right: 4px;\n  vertical-align: middle;\n}\n\n.defect-details-new-comment--SendButton {\n  padding-left: 42px;\n  padding-right: 42px;\n}\n\n@media (max-width: 767px) {\n  .defect-details-new-comment--Actions {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n  .defect-details-new-comment--SendButton {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
