// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-checklist {
  display: block;
}

.process-task-checklist--ShowDoneEntriesWrapper {
  text-align: right;
  margin-bottom: 17px;
}

.process-task-checklist--CreateEntrySectionWrapper {
  border-top: 1px solid var(--record-it-color-gray-500);
  padding-top: 24px;
  margin-top: 24px;
  padding-bottom: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-checklist/process-task-checklist.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,qDAAqD;EACrD,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["process-task-checklist {\n  display: block;\n}\n\n.process-task-checklist--ShowDoneEntriesWrapper {\n  text-align: right;\n  margin-bottom: 17px;\n}\n\n.process-task-checklist--CreateEntrySectionWrapper {\n  border-top: 1px solid var(--record-it-color-gray-500);\n  padding-top: 24px;\n  margin-top: 24px;\n  padding-bottom: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
