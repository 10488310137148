// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ultra-rapid-fire-widget--TakePictureButton {
  width: 72px;
  height: 72px;
  background: var(--record-it-color-gray-600);
  border-radius: 50%;
  border: 0;
  outline-style: solid !important;
  outline-width: 7px !important;
  outline-offset: 3px !important;
  outline-color: var(--record-it-color-primary) !important;
}

.ultra-rapid-fire-widget--TakePictureButton:hover {
  background: var(--record-it-color-primary-light);
}

.ultra-rapid-fire-widget--TakePictureButton:active {
  background: var(--record-it-color-primary);
}

.ultra-rapid-fire-widget--TakePictureButtonAbsPosition {
  position: absolute;
  right: 44px;
  top: 50%;
  transform: translateY(-50%);
}

@media (orientation: portrait) {
  .ultra-rapid-fire-widget--TakePictureButtonAbsPosition {
    top: unset;
    right: unset;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/urfw-take-picture-button/urfw-take-picture-button.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,kBAAkB;EAClB,SAAS;EACT,+BAA+B;EAC/B,6BAA6B;EAC7B,8BAA8B;EAC9B,wDAAwD;AAC1D;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE;IACE,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,2BAA2B;EAC7B;AACF","sourcesContent":[".ultra-rapid-fire-widget--TakePictureButton {\n  width: 72px;\n  height: 72px;\n  background: var(--record-it-color-gray-600);\n  border-radius: 50%;\n  border: 0;\n  outline-style: solid !important;\n  outline-width: 7px !important;\n  outline-offset: 3px !important;\n  outline-color: var(--record-it-color-primary) !important;\n}\n\n.ultra-rapid-fire-widget--TakePictureButton:hover {\n  background: var(--record-it-color-primary-light);\n}\n\n.ultra-rapid-fire-widget--TakePictureButton:active {\n  background: var(--record-it-color-primary);\n}\n\n.ultra-rapid-fire-widget--TakePictureButtonAbsPosition {\n  position: absolute;\n  right: 44px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n@media (orientation: portrait) {\n  .ultra-rapid-fire-widget--TakePictureButtonAbsPosition {\n    top: unset;\n    right: unset;\n    bottom: 25px;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
