// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-thing-plan-based-edit-defect-overlay--Title {
  font-weight: 500;
}

.gallery-thing-plan-based-edit-defect-overlay--DefectProtocolWrapper {
  --defect-comment-color-background: white;
}

.gallery-thing-plan-based-edit-defect-overlay--Separator {
  border-top: 1px solid var(--record-it-color-gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-plan-based-edit-defect-overlay/gallery-thing-plan-based-edit-defect-overlay.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,qDAAqD;AACvD","sourcesContent":[".gallery-thing-plan-based-edit-defect-overlay--Title {\n  font-weight: 500;\n}\n\n.gallery-thing-plan-based-edit-defect-overlay--DefectProtocolWrapper {\n  --defect-comment-color-background: white;\n}\n\n.gallery-thing-plan-based-edit-defect-overlay--Separator {\n  border-top: 1px solid var(--record-it-color-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
