// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `swipeable-container {
  display: block;
  position: relative;
}

.swipeable-container--LeftBoundary,
.swipeable-container--RightBoundary {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5%;
}

.swipeable-container--LeftBoundary {
  left: 0;
  background: radial-gradient(
    ellipse farthest-side at 0% 50%,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9) 35%,
    #ffffff00
  );
}

.swipeable-container--RightBoundary {
  right: 0;
  background: radial-gradient(
    ellipse farthest-side at 100% 50%,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9) 35%,
    #ffffff00
  );
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/swipeable-container/swipeable-container.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;;EAEE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,SAAS;AACX;;AAEA;EACE,OAAO;EACP;;;;;GAKC;AACH;;AAEA;EACE,QAAQ;EACR;;;;;GAKC;AACH","sourcesContent":["swipeable-container {\n  display: block;\n  position: relative;\n}\n\n.swipeable-container--LeftBoundary,\n.swipeable-container--RightBoundary {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  width: 5%;\n}\n\n.swipeable-container--LeftBoundary {\n  left: 0;\n  background: radial-gradient(\n    ellipse farthest-side at 0% 50%,\n    rgba(0, 0, 0, 0.9),\n    rgba(0, 0, 0, 0.9) 35%,\n    #ffffff00\n  );\n}\n\n.swipeable-container--RightBoundary {\n  right: 0;\n  background: radial-gradient(\n    ellipse farthest-side at 100% 50%,\n    rgba(0, 0, 0, 0.9),\n    rgba(0, 0, 0, 0.9) 35%,\n    #ffffff00\n  );\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
