// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `logout-lock-dialog {
  display: block;
}

.logout-lock-dialog--ContentWrapper {
  text-align: center;
  font-size: 1.4em;
}

.logout-lock-dialog--Message {
  font-weight: 500;
  margin-bottom: 25px;
}

.logout-lock-dialog--LockInfos {
  margin-bottom: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/logout-lock-dialog/logout-lock-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["logout-lock-dialog {\n  display: block;\n}\n\n.logout-lock-dialog--ContentWrapper {\n  text-align: center;\n  font-size: 1.4em;\n}\n\n.logout-lock-dialog--Message {\n  font-weight: 500;\n  margin-bottom: 25px;\n}\n\n.logout-lock-dialog--LockInfos {\n  margin-bottom: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
