// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-follow-up-buttons {
  display: block;
  overflow: hidden;
}

.process-task-appointment-follow-up-buttons--Label {
  color: var(--record-it-color-gray-600);
  font-size: 13px;
  margin-bottom: 11px;
  text-transform: uppercase;
}

.process-task-appointment-follow-up-buttons--RelativeToSelect {
  color: var(--record-it-color-gray-600);
  display: none;
  font-size: 12px;
  margin-bottom: 6px;
}

.process-task-appointment-follow-up-buttons--RelativeToSelectRadiobutton {
  display: inline-block;
  margin-left: 5px;
}

.process-task-appointment-follow-up-buttons--RelativeToSelectRadiobutton
  + .process-task-appointment-follow-up-buttons--RelativeToSelectRadiobutton {
  margin-left: 10px;
}

.process-task-appointment-follow-up-buttons--ButtonContainer {
  margin-left: -10px;
  margin-top: -17px;
}

.process-task-appointment-follow-up-buttons--Button {
  margin-left: 10px;
  margin-top: 17px;
}

.process-task-appointment-follow-up-buttons--RelativeToSelectVisible
  .process-task-appointment-follow-up-buttons--Label {
  margin-bottom: 5px;
}

.process-task-appointment-follow-up-buttons--RelativeToSelectVisible
  .process-task-appointment-follow-up-buttons--RelativeToSelect {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-follow-up-buttons/process-task-appointment-follow-up-buttons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":["process-task-appointment-follow-up-buttons {\n  display: block;\n  overflow: hidden;\n}\n\n.process-task-appointment-follow-up-buttons--Label {\n  color: var(--record-it-color-gray-600);\n  font-size: 13px;\n  margin-bottom: 11px;\n  text-transform: uppercase;\n}\n\n.process-task-appointment-follow-up-buttons--RelativeToSelect {\n  color: var(--record-it-color-gray-600);\n  display: none;\n  font-size: 12px;\n  margin-bottom: 6px;\n}\n\n.process-task-appointment-follow-up-buttons--RelativeToSelectRadiobutton {\n  display: inline-block;\n  margin-left: 5px;\n}\n\n.process-task-appointment-follow-up-buttons--RelativeToSelectRadiobutton\n  + .process-task-appointment-follow-up-buttons--RelativeToSelectRadiobutton {\n  margin-left: 10px;\n}\n\n.process-task-appointment-follow-up-buttons--ButtonContainer {\n  margin-left: -10px;\n  margin-top: -17px;\n}\n\n.process-task-appointment-follow-up-buttons--Button {\n  margin-left: 10px;\n  margin-top: 17px;\n}\n\n.process-task-appointment-follow-up-buttons--RelativeToSelectVisible\n  .process-task-appointment-follow-up-buttons--Label {\n  margin-bottom: 5px;\n}\n\n.process-task-appointment-follow-up-buttons--RelativeToSelectVisible\n  .process-task-appointment-follow-up-buttons--RelativeToSelect {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
