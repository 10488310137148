// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `report-list-item {
  display: block;
}

.report-list-item--IconDisabled {
  opacity: 0.3;
}

.report-list-item--UploadSuccessfulIcon {
  color: var(--record-it-color-green);
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: 1.5em;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/report-list-item/report-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["report-list-item {\n  display: block;\n}\n\n.report-list-item--IconDisabled {\n  opacity: 0.3;\n}\n\n.report-list-item--UploadSuccessfulIcon {\n  color: var(--record-it-color-green);\n  position: absolute;\n  bottom: 1em;\n  right: 1em;\n  font-size: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
