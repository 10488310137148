// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-positions-widget {
  display: block;
}

.process-task-appointment-position-widget-list-item--CheckboxColumn {
  flex: 0 0 auto;
  width: auto;

  padding-left: 10px;
  padding-right: 0px;

  line-height: 0;
}

.process-task-appointment-position-widget-list-item--CheckboxColumn
  > custom-checkbox {
  display: inline-block;
}

.process-task-appointment-position-widget-list-item--TitleColumn {
  padding-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-positions-widget/process-task-appointment-positions-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,WAAW;;EAEX,kBAAkB;EAClB,kBAAkB;;EAElB,cAAc;AAChB;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["process-task-appointment-positions-widget {\n  display: block;\n}\n\n.process-task-appointment-position-widget-list-item--CheckboxColumn {\n  flex: 0 0 auto;\n  width: auto;\n\n  padding-left: 10px;\n  padding-right: 0px;\n\n  line-height: 0;\n}\n\n.process-task-appointment-position-widget-list-item--CheckboxColumn\n  > custom-checkbox {\n  display: inline-block;\n}\n\n.process-task-appointment-position-widget-list-item--TitleColumn {\n  padding-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
