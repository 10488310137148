// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `sketcher-overlay-tool-bar-action-buttons {
  display: block;
}

.sketcher-overlay-tool-bar-action-buttons--SingleButton {
  margin-left: 4px;
}

.sketcher-overlay-tool-bar-action-buttons--CombinedActionButton {
  display: none;
}

.sketcher-overlay-tool-bar-action-buttons--CombinedActionButtonMoreButton {
  position: relative;
}

@media all and (max-width: 767px) {
  .sketcher-overlay-tool-bar-action-buttons--SingleButton {
    display: none;
  }

  .sketcher-overlay-tool-bar-action-buttons--CombinedActionButton {
    display: flex;
  }
}
`, "",{"version":3,"sources":["webpack://./src/drawingComponents/sketcher-overlay-tool-bar/sketcher-overlay-tool-bar-action-buttons/sketcher-overlay-tool-bar-action-buttons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["sketcher-overlay-tool-bar-action-buttons {\n  display: block;\n}\n\n.sketcher-overlay-tool-bar-action-buttons--SingleButton {\n  margin-left: 4px;\n}\n\n.sketcher-overlay-tool-bar-action-buttons--CombinedActionButton {\n  display: none;\n}\n\n.sketcher-overlay-tool-bar-action-buttons--CombinedActionButtonMoreButton {\n  position: relative;\n}\n\n@media all and (max-width: 767px) {\n  .sketcher-overlay-tool-bar-action-buttons--SingleButton {\n    display: none;\n  }\n\n  .sketcher-overlay-tool-bar-action-buttons--CombinedActionButton {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
