// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `search-result-reason {
  display: block;
}

.search-result-reason--Container {
  display: flex;
  flex-wrap: wrap;
}

.search-result-reason--Container > * {
  flex: 1 1 auto;
  margin-bottom: -15px;
}

.search-result-reason--Picture {
  flex: 0 0 auto;
  height: 125px;
  width: 125px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.search-result-reason--ScaledImageSize {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/listItems/globalSearch/search-result-reason/search-result-reason.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["search-result-reason {\n  display: block;\n}\n\n.search-result-reason--Container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.search-result-reason--Container > * {\n  flex: 1 1 auto;\n  margin-bottom: -15px;\n}\n\n.search-result-reason--Picture {\n  flex: 0 0 auto;\n  height: 125px;\n  width: 125px;\n  margin-right: 15px;\n  margin-bottom: 15px;\n}\n\n.search-result-reason--ScaledImageSize {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
