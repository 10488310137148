// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-tooltip {
  display: none;
}

.custom-tooltip--Content {
  background: var(--record-it-color-gray-800);
  color: white;
  padding: 4px 11px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/custom-tooltip/custom-tooltip.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,2CAA2C;EAC3C,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["custom-tooltip {\n  display: none;\n}\n\n.custom-tooltip--Content {\n  background: var(--record-it-color-gray-800);\n  color: white;\n  padding: 4px 11px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
