// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `upload-widget {
  display: block;
}

.upload-widget--Wrapper {
  display: block;
  position: relative;
  background: var(--record-it-color-gray-200);
  border: dashed 1px var(--record-it-color-gray-500);
  margin: 10px 0px;
  padding: 10px;
  text-align: center;
}

.upload-widget--Input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/upload-widget/upload-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,2CAA2C;EAC3C,kDAAkD;EAClD,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,eAAe;AACjB","sourcesContent":["upload-widget {\n  display: block;\n}\n\n.upload-widget--Wrapper {\n  display: block;\n  position: relative;\n  background: var(--record-it-color-gray-200);\n  border: dashed 1px var(--record-it-color-gray-500);\n  margin: 10px 0px;\n  padding: 10px;\n  text-align: center;\n}\n\n.upload-widget--Input {\n  position: absolute;\n  opacity: 0;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
