// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picture-full-screen-overlay--ZoomBox {
  height: 90vh;
  width: 100%;

  background: var(--record-it-color-half-transparent-background);
}

.picture-full-screen-overlay--Picture {
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/picture-full-screen-overlay/picture-full-screen-overlay.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;;EAEX,8DAA8D;AAChE;;AAEA;EACE,YAAY;AACd","sourcesContent":[".picture-full-screen-overlay--ZoomBox {\n  height: 90vh;\n  width: 100%;\n\n  background: var(--record-it-color-half-transparent-background);\n}\n\n.picture-full-screen-overlay--Picture {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
