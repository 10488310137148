// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `autocomplete-input {
  display: block;
}

.autocomplete-input--Input {
  display: block;
  border: 0;
  padding: 5px 10px;
  cursor: inherit;
  background: none;
  width: 100%;
  height: 100%;
  min-width: 0; /* to convice the input element to shrink in flexbox */
}

.autocomplete-input--Input:disabled {
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/autocomplete-input/autocomplete-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,SAAS;EACT,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,YAAY,EAAE,sDAAsD;AACtE;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["autocomplete-input {\n  display: block;\n}\n\n.autocomplete-input--Input {\n  display: block;\n  border: 0;\n  padding: 5px 10px;\n  cursor: inherit;\n  background: none;\n  width: 100%;\n  height: 100%;\n  min-width: 0; /* to convice the input element to shrink in flexbox */\n}\n\n.autocomplete-input--Input:disabled {\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
