// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-checklist-entry-list-item {
  display: block;
}

.process-task-checklist-entry-list-item--UserNameAndDate {
  font-size: 11px;
  color: var(--record-it-color-gray-600);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-checklist/process-task-checklist-entry-list-item/process-task-checklist-entry-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC","sourcesContent":["process-task-checklist-entry-list-item {\n  display: block;\n}\n\n.process-task-checklist-entry-list-item--UserNameAndDate {\n  font-size: 11px;\n  color: var(--record-it-color-gray-600);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
