// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-group {
  display: block;
}

.defect-group--Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.defect-group--TitleHeader {
  margin: 0;
  font-weight: bold;
  color: var(--record-it-color-secondary);
}

.defect-group--TitleSubtitle {
  margin: 0;
  color: var(--record-it-color-gray-700);
}

@media (min-width: 767px) {
  .defect-group--Wrapper {
    flex-direction: row;
    margin-bottom: 16px;
  }

  .defect-group--Title {
    flex: 0 0 25%;
    margin-bottom: 8px;
  }

  .defect-group--Content {
    flex: 0 0 75%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-group/defect-group.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,iBAAiB;EACjB,uCAAuC;AACzC;;AAEA;EACE,SAAS;EACT,sCAAsC;AACxC;;AAEA;EACE;IACE,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["defect-group {\n  display: block;\n}\n\n.defect-group--Wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.defect-group--TitleHeader {\n  margin: 0;\n  font-weight: bold;\n  color: var(--record-it-color-secondary);\n}\n\n.defect-group--TitleSubtitle {\n  margin: 0;\n  color: var(--record-it-color-gray-700);\n}\n\n@media (min-width: 767px) {\n  .defect-group--Wrapper {\n    flex-direction: row;\n    margin-bottom: 16px;\n  }\n\n  .defect-group--Title {\n    flex: 0 0 25%;\n    margin-bottom: 8px;\n  }\n\n  .defect-group--Content {\n    flex: 0 0 75%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
