// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-group-relations-widget {
  display: block;
}

.process-task-group-relations-widget--ProcessTaskLink {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.process-task-group-relations-widget--DisabledLink {
  pointer-events: none;
  cursor: default;
  color: var(--record-it-color-gray-800);
}

.process-task-group-relations-widget--SubInfoText {
  display: block;
  font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-group-relations-widget/process-task-group-relations-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":["process-task-group-relations-widget {\n  display: block;\n}\n\n.process-task-group-relations-widget--ProcessTaskLink {\n  display: block;\n  font-size: 14px;\n  font-weight: 400;\n  margin-bottom: 10px;\n}\n\n.process-task-group-relations-widget--DisabledLink {\n  pointer-events: none;\n  cursor: default;\n  color: var(--record-it-color-gray-800);\n}\n\n.process-task-group-relations-widget--SubInfoText {\n  display: block;\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
