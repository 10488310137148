// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thing-and-project-select {
  display: block;
}

.thing-and-project-select--WarningBox {
  margin-bottom: 47px;
}

.thing-and-project-select--FirstInputField {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/thing-and-project-select/thing-and-project-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":["thing-and-project-select {\n  display: block;\n}\n\n.thing-and-project-select--WarningBox {\n  margin-bottom: 47px;\n}\n\n.thing-and-project-select--FirstInputField {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
