// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `highlightable-text {
  display: inline;
}

.highlightable-text--Highlighted {
  color: var(--record-it-color-primary);
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/highlightable-text/highlightable-text.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;AACnB","sourcesContent":["highlightable-text {\n  display: inline;\n}\n\n.highlightable-text--Highlighted {\n  color: var(--record-it-color-primary);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
