// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `value-calculation-config-definition-widget {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/valueCalculationComponents/value-calculation-config-definition-widget/value-calculation-config-definition-widget.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["value-calculation-config-definition-widget {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
