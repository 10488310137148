// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `base-property-radiobutton-widget {
  display: block;
}

.base-property-radiobutton-widget--RadioButtonGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.base-property-radiobutton-widget--RadioButton {
  flex: 1 1 150px;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/propertyComponents/base-property-radiobutton-widget/base-property-radiobutton-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["base-property-radiobutton-widget {\n  display: block;\n}\n\n.base-property-radiobutton-widget--RadioButtonGroup {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 15px;\n}\n\n.base-property-radiobutton-widget--RadioButton {\n  flex: 1 1 150px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
