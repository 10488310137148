// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `sketcher-overlay-sketch-area {
  display: block;
  min-height: 0;
}

.sketcher-overlay-sketch-area--ZoomBox {
  display: block;
  height: 100%;
}

.sketcher-overlay-sketch-area--SketchingContainer {
  position: relative;
}

.sketcher-overlay-sketch-area--SketchingContainer > img {
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sketcher-overlay-sketch-area--SketchingContainer > drawing-area,
.sketcher-overlay-sketch-area--SketchingContainer > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sketcher-overlay-sketch-area--SketchingContainer > svg {
  width: 100%;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/drawingComponents/sketcher-overlay/sketcher-overlay-sketch-area/sketcher-overlay-sketch-area.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;;EAEE,cAAc;EACd,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB","sourcesContent":["sketcher-overlay-sketch-area {\n  display: block;\n  min-height: 0;\n}\n\n.sketcher-overlay-sketch-area--ZoomBox {\n  display: block;\n  height: 100%;\n}\n\n.sketcher-overlay-sketch-area--SketchingContainer {\n  position: relative;\n}\n\n.sketcher-overlay-sketch-area--SketchingContainer > img {\n  width: 100%;\n  user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n}\n\n.sketcher-overlay-sketch-area--SketchingContainer > drawing-area,\n.sketcher-overlay-sketch-area--SketchingContainer > svg {\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.sketcher-overlay-sketch-area--SketchingContainer > svg {\n  width: 100%;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
