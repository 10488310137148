// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `sketcher-overlay {
  display: block;
}

full-screen-content.sketcher-overlay--FullScreenContainer {
  display: none;
}

full-screen-content.sketcher-overlay--FullScreenContainer_opened {
  display: flex;
  flex-flow: column;
  position: fixed;

  background: var(--record-it-color-gray-200);

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.sketcher-overlay--ToolBar {
  margin-bottom: 10px;
  flex: 0 0 auto;
}

.sketcher-overlay--OldSketchWarning {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  flex: 0 0 auto;
}

.sketcher-overlay--SketchArea {
  flex: 1 1 auto;
  padding: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/drawingComponents/sketcher-overlay/sketcher-overlay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;;EAEf,2CAA2C;;EAE3C,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":["sketcher-overlay {\n  display: block;\n}\n\nfull-screen-content.sketcher-overlay--FullScreenContainer {\n  display: none;\n}\n\nfull-screen-content.sketcher-overlay--FullScreenContainer_opened {\n  display: flex;\n  flex-flow: column;\n  position: fixed;\n\n  background: var(--record-it-color-gray-200);\n\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n}\n\n.sketcher-overlay--ToolBar {\n  margin-bottom: 10px;\n  flex: 0 0 auto;\n}\n\n.sketcher-overlay--OldSketchWarning {\n  max-width: 1280px;\n  margin-left: auto;\n  margin-right: auto;\n  flex: 0 0 auto;\n}\n\n.sketcher-overlay--SketchArea {\n  flex: 1 1 auto;\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
