// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `items-to-synchronize-list {
  display: block;
}

.items-to-synchronize-list--ListItem + .items-to-synchronize-list--ListItem {
  margin-top: 10px;
}

.items-to-synchronize-list--ListItemTitle {
  font-weight: 500;
}

.items-to-synchronize-list--ListItemFailedIcon {
  color: var(--record-it-color-red);
}

.items-to-synchronize-list--ListItemEntityId {
  word-break: break-all;
}
`, "",{"version":3,"sources":["webpack://./src/synchronizationComponents/items-to-synchronize-list/items-to-synchronize-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["items-to-synchronize-list {\n  display: block;\n}\n\n.items-to-synchronize-list--ListItem + .items-to-synchronize-list--ListItem {\n  margin-top: 10px;\n}\n\n.items-to-synchronize-list--ListItemTitle {\n  font-weight: 500;\n}\n\n.items-to-synchronize-list--ListItemFailedIcon {\n  color: var(--record-it-color-red);\n}\n\n.items-to-synchronize-list--ListItemEntityId {\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
