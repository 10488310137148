// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-thing-sections-widget-thing-section {
  display: block;
}

.edit-thing-sections-widget-thing-section--PicturesWidget {
  margin-bottom: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/thingSectionComponents/edit-thing-sections-widget/edit-thing-sections-widget-thing-section/edit-thing-sections-widget-thing-section.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["edit-thing-sections-widget-thing-section {\n  display: block;\n}\n\n.edit-thing-sections-widget-thing-section--PicturesWidget {\n  margin-bottom: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
