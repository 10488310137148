// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picture-map-positioning-dialog--MapAndPictureContainer {
  position: relative;
  overflow: hidden;
}

.picture-map-positioning-dialog--MapNoUserSelect {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.picture-map-positioning-dialog--RepositionablePicture {
  pointer-events: none;
  opacity: 0.4;
}

.picture-map-positioning-dialog--PictureMode
  .picture-map-positioning-dialog--RepositionablePicture {
  pointer-events: initial;
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/picture-map-positioning-dialog/picture-map-positioning-dialog.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;;EAEE,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".picture-map-positioning-dialog--MapAndPictureContainer {\n  position: relative;\n  overflow: hidden;\n}\n\n.picture-map-positioning-dialog--MapNoUserSelect {\n  -ms-user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  user-select: none;\n}\n\n.picture-map-positioning-dialog--RepositionablePicture {\n  pointer-events: none;\n  opacity: 0.4;\n}\n\n.picture-map-positioning-dialog--PictureMode\n  .picture-map-positioning-dialog--RepositionablePicture {\n  pointer-events: initial;\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
