// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-project-dialog--FormLine {
  display: flex;
  width: 100%;
  margin: 25px 0;
  align-items: baseline;
  flex-wrap: wrap;
}

.copy-project-dialog--FormLine > * {
  flex: 1 1 auto;
}

.copy-project-dialog--FormLine > custom-checkbox {
  width: 100%;
}

.copy-project-dialog--FormLine > label {
  text-transform: uppercase;
  flex: 0 0 140px;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: var(--record-it-color-gray-600);
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/copy-project-dialog/copy-project-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,sCAAsC;EACtC,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".copy-project-dialog--FormLine {\n  display: flex;\n  width: 100%;\n  margin: 25px 0;\n  align-items: baseline;\n  flex-wrap: wrap;\n}\n\n.copy-project-dialog--FormLine > * {\n  flex: 1 1 auto;\n}\n\n.copy-project-dialog--FormLine > custom-checkbox {\n  width: 100%;\n}\n\n.copy-project-dialog--FormLine > label {\n  text-transform: uppercase;\n  flex: 0 0 140px;\n  font-size: 13px;\n  letter-spacing: 0.3px;\n  color: var(--record-it-color-gray-600);\n  margin-right: 20px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
