// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `manage-process-task-group-relations-dialog {
  display: block;
}

.manage-process-task-group-relations-dialog--ListAndEditingWrapper {
  position: relative;
}

.manage-process-task-group-relations-dialog--FilterContainer {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/manage-process-task-group-relations-dialog/manage-process-task-group-relations-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["manage-process-task-group-relations-dialog {\n  display: block;\n}\n\n.manage-process-task-group-relations-dialog--ListAndEditingWrapper {\n  position: relative;\n}\n\n.manage-process-task-group-relations-dialog--FilterContainer {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
