// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `multi-thing-select-and-edit-widget {
  display: block;
}

.multi-thing-select-and-edit-widget--ThingItem {
  margin-bottom: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/multi-thing-select-and-edit-widget/multi-thing-select-and-edit-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["multi-thing-select-and-edit-widget {\n  display: block;\n}\n\n.multi-thing-select-and-edit-widget--ThingItem {\n  margin-bottom: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
