// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-widget {
  display: block;
}

.person-widget--ContactLine {
  margin: 0 0 0 0.5em;
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.person-widget--ContactLines {
  display: flex;
  flex-direction: column;
}

.person-widget--Icon {
  color: var(--record-it-color-gray-700);
}

.person-widget--Link {
  color: inherit;
  text-decoration: underline;
}

.person-widget--Name {
  color: var(--record-it-color-primary);
}

.person-widget--HighlightedLink {
  font-weight: bold;
}

@media (pointer: coarse) {
  .person-widget--Link {
    padding: 0.4em 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-widget/person-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,QAAQ;EACR,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["person-widget {\n  display: block;\n}\n\n.person-widget--ContactLine {\n  margin: 0 0 0 0.5em;\n  display: flex;\n  gap: 8px;\n  align-items: baseline;\n}\n\n.person-widget--ContactLines {\n  display: flex;\n  flex-direction: column;\n}\n\n.person-widget--Icon {\n  color: var(--record-it-color-gray-700);\n}\n\n.person-widget--Link {\n  color: inherit;\n  text-decoration: underline;\n}\n\n.person-widget--Name {\n  color: var(--record-it-color-primary);\n}\n\n.person-widget--HighlightedLink {\n  font-weight: bold;\n}\n\n@media (pointer: coarse) {\n  .person-widget--Link {\n    padding: 0.4em 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
