// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `categorized-tags-widget {
  display: block;
}

.categorized-tags-widget--Label {
  text-transform: none;
  font-size: 0.7em;
}

.categorized-tags-widget--TagGroup {
  border: 1px solid var(--record-it-color-gray-400);
  width: 100%;
}

.categorized-tags-widget--TagGroup > * + * {
  border-top: 1px solid var(--record-it-color-gray-400);
}

.categorized-tags-widget--Select {
  padding: 5px 10px;
  cursor: pointer;
  min-width: 140px;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/categorized-tags-widget/categorized-tags-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,iDAAiD;EACjD,WAAW;AACb;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["categorized-tags-widget {\n  display: block;\n}\n\n.categorized-tags-widget--Label {\n  text-transform: none;\n  font-size: 0.7em;\n}\n\n.categorized-tags-widget--TagGroup {\n  border: 1px solid var(--record-it-color-gray-400);\n  width: 100%;\n}\n\n.categorized-tags-widget--TagGroup > * + * {\n  border-top: 1px solid var(--record-it-color-gray-400);\n}\n\n.categorized-tags-widget--Select {\n  padding: 5px 10px;\n  cursor: pointer;\n  min-width: 140px;\n  width: 100%;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
