// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `camera-overlay {
  display: block;
}

.camera-overlay--FullScreenContent {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; /* this is here to fix a bug in edge which calculates the scrollbars wrong (seems like it has a problem with the translate) */
}

.camera-overlay--SlotWrapper {
  position: relative;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/camera-overlay/camera-overlay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,gBAAgB,EAAE,6HAA6H;AACjJ;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["camera-overlay {\n  display: block;\n}\n\n.camera-overlay--FullScreenContent {\n  z-index: 1;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow: hidden; /* this is here to fix a bug in edge which calculates the scrollbars wrong (seems like it has a problem with the translate) */\n}\n\n.camera-overlay--SlotWrapper {\n  position: relative;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
