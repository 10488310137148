// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-defect-export-panel {
  display: block;
}

.gallery-thing-defect-export--Title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.gallery-thing-defect-export-panel--SelectReportTypeRow > * {
  margin-top: 18px;
}

.gallery-thing-defect-export-panel--ActionButtonRow {
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-defect-export-panel/gallery-thing-defect-export-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":["gallery-thing-defect-export-panel {\n  display: block;\n}\n\n.gallery-thing-defect-export--Title {\n  font-weight: 500;\n  font-size: 17px;\n  margin-bottom: 10px;\n}\n\n.gallery-thing-defect-export-panel--SelectReportTypeRow > * {\n  margin-top: 18px;\n}\n\n.gallery-thing-defect-export-panel--ActionButtonRow {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
