// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finish-process-task-appointment-confirm-dialog--WarningText {
  margin-bottom: 47px;
}

.finish-process-task-appointment-confirm-dialog--PositionsExpandableContainer {
  margin-top: 0;
}

.finish-process-task-appointment-confirm-dialog--AllPositionsDoneHint {
  font-weight: 500;
  text-align: center;
}

.finish-process-task-appointment-confirm-dialog--DevicesExpandableContainer {
  margin-top: 0;
}

.finish-process-task-appointment-confirm-dialog--PositionsExpandableContainer
  + .finish-process-task-appointment-confirm-dialog--DevicesExpandableContainer {
  margin-top: 40px;
}

.finish-process-task-appointment-confirm-dialog--AllDevicesDoneHint {
  font-weight: 500;
  text-align: center;
}

.finish-process-task-appointment-confirm-dialog--FinishAsPlannedLabel {
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/finish-process-task-appointment-confirm-dialog/finish-process-task-appointment-confirm-dialog.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".finish-process-task-appointment-confirm-dialog--WarningText {\n  margin-bottom: 47px;\n}\n\n.finish-process-task-appointment-confirm-dialog--PositionsExpandableContainer {\n  margin-top: 0;\n}\n\n.finish-process-task-appointment-confirm-dialog--AllPositionsDoneHint {\n  font-weight: 500;\n  text-align: center;\n}\n\n.finish-process-task-appointment-confirm-dialog--DevicesExpandableContainer {\n  margin-top: 0;\n}\n\n.finish-process-task-appointment-confirm-dialog--PositionsExpandableContainer\n  + .finish-process-task-appointment-confirm-dialog--DevicesExpandableContainer {\n  margin-top: 40px;\n}\n\n.finish-process-task-appointment-confirm-dialog--AllDevicesDoneHint {\n  font-weight: 500;\n  text-align: center;\n}\n\n.finish-process-task-appointment-confirm-dialog--FinishAsPlannedLabel {\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
