// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `csv-import-widget {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

csv-import-widget > * {
  flex: 1 0 130px;
  margin: 5px 5px;
}

.csv-import-widget--LinePreviewContainer {
  margin: 10px 5px;
}

.csv-import-widget--line-preview {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.csv-import-widget--column-header {
  text-align: center;
  margin-top: 10px;
  margin-left: 5px;
}

.csv-import-widget--ColumnSelectContainer {
  display: flex;
}

.csv-import-widget--ColumnCustomSelect {
  flex: 1 1 auto;
}

.csv-import-widget--ColorDanger {
  color: red;
}

.csv-import-widget--ColorWarning {
  color: var(--record-it-color-orange);
}

.csv-import-widget--WarningIcon {
  color: var(--record-it-color-orange);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/csv-import-widget/csv-import-widget.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["csv-import-widget {\n  display: flex;\n  width: 100%;\n  flex-wrap: wrap;\n}\n\ncsv-import-widget > * {\n  flex: 1 0 130px;\n  margin: 5px 5px;\n}\n\n.csv-import-widget--LinePreviewContainer {\n  margin: 10px 5px;\n}\n\n.csv-import-widget--line-preview {\n  margin: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.csv-import-widget--column-header {\n  text-align: center;\n  margin-top: 10px;\n  margin-left: 5px;\n}\n\n.csv-import-widget--ColumnSelectContainer {\n  display: flex;\n}\n\n.csv-import-widget--ColumnCustomSelect {\n  flex: 1 1 auto;\n}\n\n.csv-import-widget--ColorDanger {\n  color: red;\n}\n\n.csv-import-widget--ColorWarning {\n  color: var(--record-it-color-orange);\n}\n\n.csv-import-widget--WarningIcon {\n  color: var(--record-it-color-orange);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
