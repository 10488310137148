// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `report-type-up-download {
  display: inline-block;
}

.report-type-up-download--DisabledButton {
  background: #d6d6d6;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/report-type-up-download/report-type-up-download.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["report-type-up-download {\n  display: inline-block;\n}\n\n.report-type-up-download--DisabledButton {\n  background: #d6d6d6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
