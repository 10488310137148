// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select-category-tags-dialog {
  display: block;
}

.select-category-tags-dialog--Categories {
  display: flex;
  justify-content: space-between;
}

.select-category-tags-dialog--Category {
  flex: 1 1 auto;
  margin: 0px 10px;
}

.select-category-tags-dialog--CategoryName {
  font-size: 17px;
}

.select-category-tags-dialog--EmptyCategoryName {
  color: var(--record-it-color-gray-700);
}

.select-category-tags-dialog--TagList {
  height: 65vh;
}

@media (max-width: 1024px) {
  /* So there's a right padding if the categories horizontally overflow */
  .select-category-tags-dialog--Category:last-child {
    padding-right: 45px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/select-category-tags-dialog/select-category-tags-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uEAAuE;EACvE;IACE,mBAAmB;EACrB;AACF","sourcesContent":["select-category-tags-dialog {\n  display: block;\n}\n\n.select-category-tags-dialog--Categories {\n  display: flex;\n  justify-content: space-between;\n}\n\n.select-category-tags-dialog--Category {\n  flex: 1 1 auto;\n  margin: 0px 10px;\n}\n\n.select-category-tags-dialog--CategoryName {\n  font-size: 17px;\n}\n\n.select-category-tags-dialog--EmptyCategoryName {\n  color: var(--record-it-color-gray-700);\n}\n\n.select-category-tags-dialog--TagList {\n  height: 65vh;\n}\n\n@media (max-width: 1024px) {\n  /* So there's a right padding if the categories horizontally overflow */\n  .select-category-tags-dialog--Category:last-child {\n    padding-right: 45px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
