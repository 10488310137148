// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-coordinates-on-positioned-picture-overlay--ContentWrapper {
  position: relative;
}

.select-coordinates-on-positioned-picture-overlay--OldMarking {
  pointer-events: none;
  opacity: 0.8;
}

.select-coordinates-on-positioned-picture-overlay--WarningText {
  text-align: center;
  font-size: 24px;
  color: white;
}

.select-coordinates-on-positioned-picture-overlay--ZoomBox {
  height: 90vh;
  width: 100%;

  background: var(--record-it-color-half-transparent-background);
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/select-coordinates-on-positioned-picture-overlay/select-coordinates-on-positioned-picture-overlay.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;;EAEX,8DAA8D;AAChE","sourcesContent":[".select-coordinates-on-positioned-picture-overlay--ContentWrapper {\n  position: relative;\n}\n\n.select-coordinates-on-positioned-picture-overlay--OldMarking {\n  pointer-events: none;\n  opacity: 0.8;\n}\n\n.select-coordinates-on-positioned-picture-overlay--WarningText {\n  text-align: center;\n  font-size: 24px;\n  color: white;\n}\n\n.select-coordinates-on-positioned-picture-overlay--ZoomBox {\n  height: 90vh;\n  width: 100%;\n\n  background: var(--record-it-color-half-transparent-background);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
