// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `expandable-search-input {
  display: block;
}

.expandable-search-input--Input {
  display: block;
  border: 0;
  padding: 0;
  font-size: 16px;
  background: transparent;
  caret-color: var(--record-it-color-primary);
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/expandable-search-input/expandable-search-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,SAAS;EACT,UAAU;EACV,eAAe;EACf,uBAAuB;EACvB,2CAA2C;EAC3C,kBAAkB;AACpB","sourcesContent":["expandable-search-input {\n  display: block;\n}\n\n.expandable-search-input--Input {\n  display: block;\n  border: 0;\n  padding: 0;\n  font-size: 16px;\n  background: transparent;\n  caret-color: var(--record-it-color-primary);\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
