// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-task-position-custom-type-icon {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-position-custom-type-icon/process-task-position-custom-type-icon.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB","sourcesContent":[".process-task-position-custom-type-icon {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
