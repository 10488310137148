// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `color-input {
  display: block;
}

.color-input--HiddenColorInput {
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.color-input--ColorPreview {
  width: 36px;
  height: 36px;
  margin-right: 16px;
  border: 3px solid white;
  border-radius: 8px;
}

.color-input--PreviewLabel {
  align-self: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/color-input/color-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["color-input {\n  display: block;\n}\n\n.color-input--HiddenColorInput {\n  cursor: pointer;\n  opacity: 0;\n  height: 100%;\n  width: 100%;\n}\n\n.color-input--ColorPreview {\n  width: 36px;\n  height: 36px;\n  margin-right: 16px;\n  border: 3px solid white;\n  border-radius: 8px;\n}\n\n.color-input--PreviewLabel {\n  align-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
