// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-task-invoice-dialog--NotDoneChecklistEntriesTitle {
  font-weight: 500;
  margin-bottom: 9px;
}

.edit-process-task-invoice-dialog--NotDoneChecklistEntriesMessageBox {
  margin-bottom: 47px;
}

.edit-process-task-invoice-dialog--BottomButtonWrapper {
  margin-top: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-task-invoice-dialog/edit-process-task-invoice-dialog.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".edit-process-task-invoice-dialog--NotDoneChecklistEntriesTitle {\n  font-weight: 500;\n  margin-bottom: 9px;\n}\n\n.edit-process-task-invoice-dialog--NotDoneChecklistEntriesMessageBox {\n  margin-bottom: 47px;\n}\n\n.edit-process-task-invoice-dialog--BottomButtonWrapper {\n  margin-top: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
