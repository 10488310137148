// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-select-and-contact-widget {
  display: block;
}

.person-select-and-contact-widget--InfoLabel {
  margin-bottom: 5px;
  font-size: 13px;
}

.person-select-and-contact-widget--InfoContent {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.person-select-and-contact-widget--InfoContentText {
  vertical-align: middle;
}

.person-select-and-contact-widget--InfoContentHint {
  font-weight: 400;
  font-size: 13px;
  color: var(--record-it-color-gray-600);
}

.person-select-and-contact-widget--InfoContentIcon {
  vertical-align: middle;
  cursor: pointer;
}

.person-select-and-contact-widget--InfoContentSelectPerson {
  vertical-align: middle;
  font-weight: 400;
  cursor: pointer;
}

.person-select-and-contact-widget--ContactSelect {
  font-size: 14px;
  font-weight: 400;
  max-width: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-select-and-contact-widget/person-select-and-contact-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["person-select-and-contact-widget {\n  display: block;\n}\n\n.person-select-and-contact-widget--InfoLabel {\n  margin-bottom: 5px;\n  font-size: 13px;\n}\n\n.person-select-and-contact-widget--InfoContent {\n  color: #4a4a4a;\n  font-size: 16px;\n  font-weight: 600;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.person-select-and-contact-widget--InfoContentText {\n  vertical-align: middle;\n}\n\n.person-select-and-contact-widget--InfoContentHint {\n  font-weight: 400;\n  font-size: 13px;\n  color: var(--record-it-color-gray-600);\n}\n\n.person-select-and-contact-widget--InfoContentIcon {\n  vertical-align: middle;\n  cursor: pointer;\n}\n\n.person-select-and-contact-widget--InfoContentSelectPerson {\n  vertical-align: middle;\n  font-weight: 400;\n  cursor: pointer;\n}\n\n.person-select-and-contact-widget--ContactSelect {\n  font-size: 14px;\n  font-weight: 400;\n  max-width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
