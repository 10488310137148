// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `create-appointment-worker-dialog {
  display: block;
}

.create-appointment-worker-dialog--Columns {
  display: flex;
  flex-direction: column;
}

@media (min-width: 830px) {
  .create-appointment-worker-dialog--Columns {
    flex-direction: row;
    gap: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/create-appointment-worker-dialog/create-appointment-worker-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,mBAAmB;IACnB,SAAS;EACX;AACF","sourcesContent":["create-appointment-worker-dialog {\n  display: block;\n}\n\n.create-appointment-worker-dialog--Columns {\n  display: flex;\n  flex-direction: column;\n}\n\n@media (min-width: 830px) {\n  .create-appointment-worker-dialog--Columns {\n    flex-direction: row;\n    gap: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
