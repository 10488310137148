// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-upload-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

picture-upload-buttons > .record-it-button {
  flex: 1 1 auto;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
}

picture-upload-buttons > .record-it-button + .record-it-button {
  margin-left: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/picture-upload-buttons/picture-upload-buttons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["picture-upload-buttons {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n}\n\npicture-upload-buttons > .record-it-button {\n  flex: 1 1 auto;\n  min-width: 0;\n  padding-left: 0;\n  padding-right: 0;\n}\n\npicture-upload-buttons > .record-it-button + .record-it-button {\n  margin-left: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
