// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-revision-comparison-full-screen-dialog {
  display: none;
}

.picture-revision-comparison-full-screen-dialog--TopBarContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 8px 45px;
  align-items: center;
}

.picture-revision-comparison-full-screen-dialog--MainContent {
  display: flex;
  gap: 10px;
  padding: 10px 10px;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--record-it-color-gray-400);
}

.picture-revision-comparison-full-screen-dialog--ComparisonPicture {
  flex: 1 1 100%;
  min-width: 0;
}

@media (max-width: 767px) {
  .picture-revision-comparison-full-screen-dialog--MainContent {
    flex-direction: column;
    width: calc(100% - 30px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pictureRevisionComponents/picture-revision-comparison-full-screen-dialog/picture-revision-comparison-full-screen-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;IACtB,wBAAwB;EAC1B;AACF","sourcesContent":["picture-revision-comparison-full-screen-dialog {\n  display: none;\n}\n\n.picture-revision-comparison-full-screen-dialog--TopBarContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  gap: 8px 45px;\n  align-items: center;\n}\n\n.picture-revision-comparison-full-screen-dialog--MainContent {\n  display: flex;\n  gap: 10px;\n  padding: 10px 10px;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background: var(--record-it-color-gray-400);\n}\n\n.picture-revision-comparison-full-screen-dialog--ComparisonPicture {\n  flex: 1 1 100%;\n  min-width: 0;\n}\n\n@media (max-width: 767px) {\n  .picture-revision-comparison-full-screen-dialog--MainContent {\n    flex-direction: column;\n    width: calc(100% - 30px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
