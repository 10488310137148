// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `rapid-fire-entry-select {
  display: block;
  width: 100%;

  --rapid-fire-entry-select--EntryButtonDefaultWidth: 30%;
}

@media (max-width: 767px) {
  rapid-fire-entry-select {
    --rapid-fire-entry-select--EntryButtonDefaultWidth: 50%;
  }
}

.rapid-fire-entry-select--SelectContainer {
  display: flex;
  flex-flow: row nowrap;
}

.rapid-fire-entry-select--CustomSelect {
  flex: 1 1 auto;
  min-width: 0;
}

.rapid-fire-entry-select--RemoveIcon {
  flex: 0 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.rapid-fire-entry-select--EntryButtonsLabel {
  flex: 0 0 100%;
  margin: 10px;
  font-weight: bold;
}

.rapid-fire-entry-select--EntryButtons {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.rapid-fire-entry-select--EntryButton {
  flex: 1 1 var(--rapid-fire-entry-select--EntryButtonDefaultWidth);
  margin: 10px;
}

.rapid-fire-entry-select--MoreButton {
  flex: 1 1 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/rapid-fire-entry-select/rapid-fire-entry-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;;EAEX,uDAAuD;AACzD;;AAEA;EACE;IACE,uDAAuD;EACzD;AACF;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,aAAa;AACf;;AAEA;EACE,iEAAiE;EACjE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":["rapid-fire-entry-select {\n  display: block;\n  width: 100%;\n\n  --rapid-fire-entry-select--EntryButtonDefaultWidth: 30%;\n}\n\n@media (max-width: 767px) {\n  rapid-fire-entry-select {\n    --rapid-fire-entry-select--EntryButtonDefaultWidth: 50%;\n  }\n}\n\n.rapid-fire-entry-select--SelectContainer {\n  display: flex;\n  flex-flow: row nowrap;\n}\n\n.rapid-fire-entry-select--CustomSelect {\n  flex: 1 1 auto;\n  min-width: 0;\n}\n\n.rapid-fire-entry-select--RemoveIcon {\n  flex: 0 0 auto;\n  margin-left: 5px;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.rapid-fire-entry-select--EntryButtonsLabel {\n  flex: 0 0 100%;\n  margin: 10px;\n  font-weight: bold;\n}\n\n.rapid-fire-entry-select--EntryButtons {\n  display: flex;\n  flex-wrap: wrap;\n  padding: 20px;\n}\n\n.rapid-fire-entry-select--EntryButton {\n  flex: 1 1 var(--rapid-fire-entry-select--EntryButtonDefaultWidth);\n  margin: 10px;\n}\n\n.rapid-fire-entry-select--MoreButton {\n  flex: 1 1 100%;\n  padding-top: 16px;\n  padding-bottom: 16px;\n  margin: 10px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
