// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `user-user-roles-widget {
  display: block;
}

.user-user-roles-widget--Roles {
  display: flex;
  flex-flow: row wrap;
  gap: 17px;
}

.user-user-roles-widget--AddRoleRow {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 17px;
  margin-top: 17px;
}

.user-user-roles-widget--RoleToAddSelect {
  flex: 0 1 300px;
}
`, "",{"version":3,"sources":["webpack://./src/userRoleComponents/user-user-roles-widget/user-user-roles-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["user-user-roles-widget {\n  display: block;\n}\n\n.user-user-roles-widget--Roles {\n  display: flex;\n  flex-flow: row wrap;\n  gap: 17px;\n}\n\n.user-user-roles-widget--AddRoleRow {\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n  justify-content: center;\n  gap: 17px;\n  margin-top: 17px;\n}\n\n.user-user-roles-widget--RoleToAddSelect {\n  flex: 0 1 300px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
