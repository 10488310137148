// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `property-styled-text-widget {
  display: block;
}

.property-styled-text-widget--ThemeHeading1 {
  font-size: 24px;
  font-weight: 500;
}

.property-styled-text-widget--ThemeHeading2 {
  font-size: 20px;
  font-weight: 500;
}

.property-styled-text-widget--ThemeHeading3 {
  font-size: 17px;
  font-weight: 500;
}

.property-styled-text-widget--ThemeHeading4 {
  font-weight: 500;
}

.property-styled-text-widget--TextDecorationUnderline {
  text-decoration: underline;
}

.property-styled-text-widget--FontStyleItalic {
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/propertyComponents/property-styled-text-widget/property-styled-text-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["property-styled-text-widget {\n  display: block;\n}\n\n.property-styled-text-widget--ThemeHeading1 {\n  font-size: 24px;\n  font-weight: 500;\n}\n\n.property-styled-text-widget--ThemeHeading2 {\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.property-styled-text-widget--ThemeHeading3 {\n  font-size: 17px;\n  font-weight: 500;\n}\n\n.property-styled-text-widget--ThemeHeading4 {\n  font-weight: 500;\n}\n\n.property-styled-text-widget--TextDecorationUnderline {\n  text-decoration: underline;\n}\n\n.property-styled-text-widget--FontStyleItalic {\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
