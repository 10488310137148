// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thing-group-fields-property {
  display: flex;
  flex-flow: row wrap;
}

thing-group-fields-property > * {
  flex: 1 1 200px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/thing-group-fields/thing-group-fields-property/thing-group-fields-property.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["thing-group-fields-property {\n  display: flex;\n  flex-flow: row wrap;\n}\n\nthing-group-fields-property > * {\n  flex: 1 1 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
