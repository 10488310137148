// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-picture-area-box {
  display: block;
  border: 1px dashed grey;
}

.structure-picture-area-box_active {
  border-width: 3px;
  border-color: var(--record-it-color-primary);
}

.structure-picture-area-box--Label {
  position: absolute;
  top: 2px;
  left: 2px;
  color: grey;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.structure-picture-area-box_active .structure-picture-area-box--Label {
  font-weight: 500;
  color: var(--record-it-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/structurePictureAreaComponents/structure-picture-area-box/structure-picture-area-box.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,qCAAqC;AACvC","sourcesContent":["structure-picture-area-box {\n  display: block;\n  border: 1px dashed grey;\n}\n\n.structure-picture-area-box_active {\n  border-width: 3px;\n  border-color: var(--record-it-color-primary);\n}\n\n.structure-picture-area-box--Label {\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  color: grey;\n  max-width: 200px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.structure-picture-area-box_active .structure-picture-area-box--Label {\n  font-weight: 500;\n  color: var(--record-it-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
