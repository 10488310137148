// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-defect-details-widget {
  display: block;
}

.edit-defect-details-widget--MainDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.edit-defect-details-widget--MainPicture {
  flex: 0 0 100%;
  min-height: 200px;
}

.edit-defect-details-widget--Title {
  margin: 0px;
}

.edit-defect-details-widget--NoTagsLabel {
  line-height: 1;
}

.edit-defect-details-widget--Status {
  text-transform: uppercase;
  color: var(--record-it-color-gray-600);
}

.edit-defect-details-widget--SidePictures {
  display: flex;
  flex-wrap: wrap;
}

.edit-defect-details-widget--SidePicture {
  height: 140px;
  width: 140px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.edit-defect-details-widget--PictureBorder {
  border: 1px solid var(--record-it-color-gray-300);
}

.edit-defect-details-widget--SidePicture.disabled {
  cursor: not-allowed;
}

.edit-defect-details-widget--EditArea {
  margin-top: 24px;
}

@media (min-width: 767px) {
  .edit-defect-details-widget--MainDetails {
    flex-direction: row;
  }

  .edit-defect-details-widget--MainPicture {
    flex: 0 0 40%;
  }

  .edit-defect-details-widget--MainInfo {
    flex: 1 0 60%;
    padding: 0px 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/edit-defect-details-widget/edit-defect-details-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,iBAAiB;EACnB;AACF","sourcesContent":["edit-defect-details-widget {\n  display: block;\n}\n\n.edit-defect-details-widget--MainDetails {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 24px;\n}\n\n.edit-defect-details-widget--MainPicture {\n  flex: 0 0 100%;\n  min-height: 200px;\n}\n\n.edit-defect-details-widget--Title {\n  margin: 0px;\n}\n\n.edit-defect-details-widget--NoTagsLabel {\n  line-height: 1;\n}\n\n.edit-defect-details-widget--Status {\n  text-transform: uppercase;\n  color: var(--record-it-color-gray-600);\n}\n\n.edit-defect-details-widget--SidePictures {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.edit-defect-details-widget--SidePicture {\n  height: 140px;\n  width: 140px;\n  margin-right: 8px;\n  margin-bottom: 8px;\n  cursor: pointer;\n}\n\n.edit-defect-details-widget--PictureBorder {\n  border: 1px solid var(--record-it-color-gray-300);\n}\n\n.edit-defect-details-widget--SidePicture.disabled {\n  cursor: not-allowed;\n}\n\n.edit-defect-details-widget--EditArea {\n  margin-top: 24px;\n}\n\n@media (min-width: 767px) {\n  .edit-defect-details-widget--MainDetails {\n    flex-direction: row;\n  }\n\n  .edit-defect-details-widget--MainPicture {\n    flex: 0 0 40%;\n  }\n\n  .edit-defect-details-widget--MainInfo {\n    flex: 1 0 60%;\n    padding: 0px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
