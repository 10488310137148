// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `change-password-panel {
  display: block;
}

.change-password-panel--WarningText {
  margin-bottom: 20px;
}

.change-password-panel--ButtonContainer {
  flex: 1 0 100%;
}

.change-password-panel--PasswordInputPanel {
  flex: 1 0 100%;
  background: var(--record-it-color-gray-300);
  padding: 25px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/change-password-panel/change-password-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,2CAA2C;EAC3C,kBAAkB;AACpB","sourcesContent":["change-password-panel {\n  display: block;\n}\n\n.change-password-panel--WarningText {\n  margin-bottom: 20px;\n}\n\n.change-password-panel--ButtonContainer {\n  flex: 1 0 100%;\n}\n\n.change-password-panel--PasswordInputPanel {\n  flex: 1 0 100%;\n  background: var(--record-it-color-gray-300);\n  padding: 25px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
