// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `operations-calendar-user-legend {
  display: block;
}

.operations-calendar-user-legend--Group {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/operations-calendar-user-legend/operations-calendar-user-legend.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["operations-calendar-user-legend {\n  display: block;\n}\n\n.operations-calendar-user-legend--Group {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
