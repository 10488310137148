// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.defect-status-icon--Icon {
  display: inline-block;
  position: relative;
  top: -1px;
  color: white;
  font-size: 10px;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0px 2px;
  border-radius: 50%;
}

.defect-status-icon--Icon[data-status='open'] {
  background-color: var(--record-it-color-defect-state-open);
}

.defect-status-icon--Icon[data-status='processed'] {
  background-color: var(--record-it-color-defect-state-processed);
}

.defect-status-icon--Icon[data-status='done'] {
  background-color: var(--record-it-color-defect-state-done);
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-status-icon/defect-status-icon.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,0DAA0D;AAC5D;;AAEA;EACE,+DAA+D;AACjE;;AAEA;EACE,0DAA0D;AAC5D","sourcesContent":[".defect-status-icon--Icon {\n  display: inline-block;\n  position: relative;\n  top: -1px;\n  color: white;\n  font-size: 10px;\n  width: 24px;\n  height: 24px;\n  text-align: center;\n  line-height: 24px;\n  margin: 0px 2px;\n  border-radius: 50%;\n}\n\n.defect-status-icon--Icon[data-status='open'] {\n  background-color: var(--record-it-color-defect-state-open);\n}\n\n.defect-status-icon--Icon[data-status='processed'] {\n  background-color: var(--record-it-color-defect-state-processed);\n}\n\n.defect-status-icon--Icon[data-status='done'] {\n  background-color: var(--record-it-color-defect-state-done);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
