// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `expression-editor-text-input {
  display: flex;
  gap: 15px;
  align-items: center;
}

.expression-editor-text-input--TextInput {
  flex-grow: 1;
}
.expression-editor-text-input--InfoTextButton {
  flex: 0 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/expression-editor-text-input/expression-editor-text-input.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb","sourcesContent":["expression-editor-text-input {\n  display: flex;\n  gap: 15px;\n  align-items: center;\n}\n\n.expression-editor-text-input--TextInput {\n  flex-grow: 1;\n}\n.expression-editor-text-input--InfoTextButton {\n  flex: 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
