// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `selectable-item-list {
  display: block;
}

.selectable-item-list--ItemWrapper {
  display: flex;
}

.selectable-item-list--Item {
  flex: 1 1 auto;
  min-width: 0;
}

.selectable-item-list--NonVisibleItem {
  display: none;
}

.selectable-item-list--SelectionEnableButton {
  width: 100%;
  padding-top: 9px;
  padding-bottom: 7px;
}

.selectable-item-list--SelectionButtonAboveHeader {
  margin: 10px 0;
}

.selectable-item-list--SelectionColumn {
  width: 70px;
  align-self: center;
  flex: 0 0 auto;
}

.selectable-item-list--ListAndEditingWrapper {
  position: relative;
}

@media (max-width: 767px) {
  .selectable-item-list--SelectionColumn {
    width: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/selectable-item-list/selectable-item-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["selectable-item-list {\n  display: block;\n}\n\n.selectable-item-list--ItemWrapper {\n  display: flex;\n}\n\n.selectable-item-list--Item {\n  flex: 1 1 auto;\n  min-width: 0;\n}\n\n.selectable-item-list--NonVisibleItem {\n  display: none;\n}\n\n.selectable-item-list--SelectionEnableButton {\n  width: 100%;\n  padding-top: 9px;\n  padding-bottom: 7px;\n}\n\n.selectable-item-list--SelectionButtonAboveHeader {\n  margin: 10px 0;\n}\n\n.selectable-item-list--SelectionColumn {\n  width: 70px;\n  align-self: center;\n  flex: 0 0 auto;\n}\n\n.selectable-item-list--ListAndEditingWrapper {\n  position: relative;\n}\n\n@media (max-width: 767px) {\n  .selectable-item-list--SelectionColumn {\n    width: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
