// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `widget-overlay {
  display: block;
  height: 100vh;
}

.widget-overlay--WidgetWrapper {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/widget-overlay/widget-overlay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,OAAO;EACP,gBAAgB;AAClB","sourcesContent":["widget-overlay {\n  display: block;\n  height: 100vh;\n}\n\n.widget-overlay--WidgetWrapper {\n  position: absolute;\n  top: 10px;\n  right: 0;\n  left: 0;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
