// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `no-coordinates-dialog {
  display: block;
}

.no-coordinates-dialog--Message {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 35px;
}

.no-coordinates-dialog--MapWrapper {
  height: 30vh;
  position: relative;
  margin-bottom: 15px;
}

.no-coordinates-dialog--Map {
  height: 100%;
}

.no-coordinates-dialog--MapMarker {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  pointer-events: none;
  width: 35px;
  height: 35px;
}

.no-coordinates-dialog--ButtonsContainer {
  margin-top: 30px;
}

.no-coordinates-dialog--ButtonsText {
  margin: 20px 0;
  text-align: center;
  font-size: 1.2em;
}

.no-coordinates-dialog--Buttons {
  display: flex;
  flex-wrap: wrap;
}

.no-coordinates-dialog--Buttons > * {
  flex: 1 1 210px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/no-coordinates-dialog/no-coordinates-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;;EAElB,QAAQ;EACR,SAAS;EACT,iCAAiC;EACjC,oBAAoB;EACpB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["no-coordinates-dialog {\n  display: block;\n}\n\n.no-coordinates-dialog--Message {\n  font-size: 1.2em;\n  text-align: center;\n  margin-bottom: 35px;\n}\n\n.no-coordinates-dialog--MapWrapper {\n  height: 30vh;\n  position: relative;\n  margin-bottom: 15px;\n}\n\n.no-coordinates-dialog--Map {\n  height: 100%;\n}\n\n.no-coordinates-dialog--MapMarker {\n  position: absolute;\n\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -100%);\n  pointer-events: none;\n  width: 35px;\n  height: 35px;\n}\n\n.no-coordinates-dialog--ButtonsContainer {\n  margin-top: 30px;\n}\n\n.no-coordinates-dialog--ButtonsText {\n  margin: 20px 0;\n  text-align: center;\n  font-size: 1.2em;\n}\n\n.no-coordinates-dialog--Buttons {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.no-coordinates-dialog--Buttons > * {\n  flex: 1 1 210px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
