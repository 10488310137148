// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-thing-plan-based-overview-overlay--TopBarContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 8px 45px;
  align-items: center;
}

.gallery-thing-plan-based-overview-overlay--PictureSelectContainer {
  flex: 1 1 300px;
  display: flex;
  flex-wrap: nowrap;
}

.gallery-thing-plan-based-overview-overlay--PictureSelectContainer
  > .record-it-input-and-label-container-label {
  flex: 0 0 auto;
}

.gallery-thing-plan-based-overview-overlay--ShowAllDefectsCheckbox {
  display: flex;
}

.gallery-thing-plan-based-overview-overlay--MainContent {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallery-thing-plan-based-overview-overlay--ZoomBox {
  width: 100%;
  height: 100%;
  background: var(--record-it-color-gray-800);
}

.gallery-thing-plan-based-overview-overlay--ZoomBox_hidden {
  visibility: hidden;
}

.gallery-thing-plan-based-overview-overlay--NoLocationInfoWarning {
  position: absolute;
  background-color: rgba(74, 74, 74, 0.53);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gallery-thing-plan-based-overview-overlay--NoLocationInfoWarningText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  color: white;
  font-size: 17px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-plan-based-overview-overlay/gallery-thing-plan-based-overview-overlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,wCAAwC;EACxC,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".gallery-thing-plan-based-overview-overlay--TopBarContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  gap: 8px 45px;\n  align-items: center;\n}\n\n.gallery-thing-plan-based-overview-overlay--PictureSelectContainer {\n  flex: 1 1 300px;\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.gallery-thing-plan-based-overview-overlay--PictureSelectContainer\n  > .record-it-input-and-label-container-label {\n  flex: 0 0 auto;\n}\n\n.gallery-thing-plan-based-overview-overlay--ShowAllDefectsCheckbox {\n  display: flex;\n}\n\n.gallery-thing-plan-based-overview-overlay--MainContent {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.gallery-thing-plan-based-overview-overlay--ZoomBox {\n  width: 100%;\n  height: 100%;\n  background: var(--record-it-color-gray-800);\n}\n\n.gallery-thing-plan-based-overview-overlay--ZoomBox_hidden {\n  visibility: hidden;\n}\n\n.gallery-thing-plan-based-overview-overlay--NoLocationInfoWarning {\n  position: absolute;\n  background-color: rgba(74, 74, 74, 0.53);\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n.gallery-thing-plan-based-overview-overlay--NoLocationInfoWarningText {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  max-width: 100%;\n  color: white;\n  font-size: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
