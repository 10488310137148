// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extract-process-task-offer-dialog--WarningTextMessageBox {
  margin-bottom: 52px;
  font-size: 15px;
}

.extract-process-task-offer-dialog--IntroText {
  margin-bottom: 14px;
  font-weight: 500;
}

.extract-process-task-offer-dialog--ValidationErrorGroup
  + .extract-process-task-offer-dialog--ValidationErrorGroup {
  margin-top: 14px;
}

.extract-process-task-offer-dialog--OutroText {
  margin-top: 14px;
}

.extract-process-task-offer-dialog--ReadyText {
  margin-bottom: 52px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/extract-process-task-offer-dialog/extract-process-task-offer-dialog.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".extract-process-task-offer-dialog--WarningTextMessageBox {\n  margin-bottom: 52px;\n  font-size: 15px;\n}\n\n.extract-process-task-offer-dialog--IntroText {\n  margin-bottom: 14px;\n  font-weight: 500;\n}\n\n.extract-process-task-offer-dialog--ValidationErrorGroup\n  + .extract-process-task-offer-dialog--ValidationErrorGroup {\n  margin-top: 14px;\n}\n\n.extract-process-task-offer-dialog--OutroText {\n  margin-top: 14px;\n}\n\n.extract-process-task-offer-dialog--ReadyText {\n  margin-bottom: 52px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
