// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-template-rating-category-widget {
  display: block;
}

.structure-template-rating-category-widget--IconContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.structure-template-rating-category-widget--IconInput {
  flex: 1 1 auto;
  display: flex;
  gap: 24px;
}

.structure-template-rating-category-widget--Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #cccccc;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/structureTemplateComponents/structure-template-rating-category-widget/structure-template-rating-category-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":["structure-template-rating-category-widget {\n  display: block;\n}\n\n.structure-template-rating-category-widget--IconContainer {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n.structure-template-rating-category-widget--IconInput {\n  flex: 1 1 auto;\n  display: flex;\n  gap: 24px;\n}\n\n.structure-template-rating-category-widget--Icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 8px;\n  width: 40px;\n  height: 40px;\n  border-radius: 100%;\n  background-color: #cccccc;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
