// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointments-widget {
  display: block;
}

.process-task-appointments-widget--ButtonWrapper {
  margin: 60px 0;
}

.process-task-appointments-widget--NoElementsItem {
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointments-widget/process-task-appointments-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["process-task-appointments-widget {\n  display: block;\n}\n\n.process-task-appointments-widget--ButtonWrapper {\n  margin: 60px 0;\n}\n\n.process-task-appointments-widget--NoElementsItem {\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
