// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-offer-expiration-date-input {
  display: block;
}

.process-task-offer-expiration-date-input--ButtonInputAndLabelContainer {
  margin-top: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-offer-expiration-date-input/process-task-offer-expiration-date-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["process-task-offer-expiration-date-input {\n  display: block;\n}\n\n.process-task-offer-expiration-date-input--ButtonInputAndLabelContainer {\n  margin-top: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
