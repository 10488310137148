// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `file-list-with-bottom-right-content {
  display: flex;
}

.file-list-with-bottom-right-content--Files {
  flex: 1 1 auto;
  display: flex;
  gap: 10px 20px;
  flex-flow: wrap;
  min-width: 0;
}

.file-list-with-bottom-right-content--FileLink {
  font-size: 11px;
  flex: 0 0 auto;
}

.file-list-with-bottom-right-content--BottomRightContent {
  flex: 0 0 auto;
  font-size: 11px;
  color: var(--record-it-color-gray-600);
  align-self: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/file-list-with-bottom-right-content/file-list-with-bottom-right-content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;EACb,cAAc;EACd,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,sCAAsC;EACtC,oBAAoB;AACtB","sourcesContent":["file-list-with-bottom-right-content {\n  display: flex;\n}\n\n.file-list-with-bottom-right-content--Files {\n  flex: 1 1 auto;\n  display: flex;\n  gap: 10px 20px;\n  flex-flow: wrap;\n  min-width: 0;\n}\n\n.file-list-with-bottom-right-content--FileLink {\n  font-size: 11px;\n  flex: 0 0 auto;\n}\n\n.file-list-with-bottom-right-content--BottomRightContent {\n  flex: 0 0 auto;\n  font-size: 11px;\n  color: var(--record-it-color-gray-600);\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
