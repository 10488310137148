// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-user-profile-dialog {
  display: block;
}

.edit-user-profile-dialog--UserGroupBadges {
  font-size: 13px;
}

.edit-user-profile-dialog--SubscriptionInfo
  + .edit-user-profile-dialog--SubscriptionInfo {
  margin-top: 5px;
}

.edit-user-profile-dialog--SubscriptionInfoTitle {
  display: inline-block;
  font-weight: 500;
}

.edit-user-profile-dialog--ChangePasswordPanel {
  flex: 1 0 100%;
}

.edit-user-profile-dialog--SaveBiometricCredentialsPanel {
  flex: 1 0 100%;
}

.edit-user-profile-dialog--RequestAccountDeletionText {
  cursor: pointer;
  color: red;
  margin-top: 15px;
}

.edit-user-profile-dialog--RequestAccountDeletionText.disabled {
  cursor: not-allowed;
  color: var(--record-it-color-gray-600);
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-user-profile-dialog/edit-user-profile-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,sCAAsC;AACxC","sourcesContent":["edit-user-profile-dialog {\n  display: block;\n}\n\n.edit-user-profile-dialog--UserGroupBadges {\n  font-size: 13px;\n}\n\n.edit-user-profile-dialog--SubscriptionInfo\n  + .edit-user-profile-dialog--SubscriptionInfo {\n  margin-top: 5px;\n}\n\n.edit-user-profile-dialog--SubscriptionInfoTitle {\n  display: inline-block;\n  font-weight: 500;\n}\n\n.edit-user-profile-dialog--ChangePasswordPanel {\n  flex: 1 0 100%;\n}\n\n.edit-user-profile-dialog--SaveBiometricCredentialsPanel {\n  flex: 1 0 100%;\n}\n\n.edit-user-profile-dialog--RequestAccountDeletionText {\n  cursor: pointer;\n  color: red;\n  margin-top: 15px;\n}\n\n.edit-user-profile-dialog--RequestAccountDeletionText.disabled {\n  cursor: not-allowed;\n  color: var(--record-it-color-gray-600);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
