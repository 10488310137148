// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-picture-area-position-editor {
  display: block;
}

.structure-picture-area-position-editor--ZoomBox {
  display: block;
  width: 100%;
  height: 100%;
  background: var(--record-it-color-gray-800);
}

.structure-picture-area-position-editor--CutoutSelectionContainer {
  position: absolute;
}

.structure-picture-area-position-editor--RepositionableElementAreaBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/structurePictureAreaComponents/structure-picture-area-position-editor/structure-picture-area-position-editor.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX","sourcesContent":["structure-picture-area-position-editor {\n  display: block;\n}\n\n.structure-picture-area-position-editor--ZoomBox {\n  display: block;\n  width: 100%;\n  height: 100%;\n  background: var(--record-it-color-gray-800);\n}\n\n.structure-picture-area-position-editor--CutoutSelectionContainer {\n  position: absolute;\n}\n\n.structure-picture-area-position-editor--RepositionableElementAreaBox {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
