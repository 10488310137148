// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit_catastrophe {
  display: block;
}

.edit-catastrophe--HeaderContent {
  display: flex;
  gap: 0.4em;
  align-items: center;
}

.edit-catastrophe--SiteContainer {
  margin-bottom: 20vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/edit_catastrophe/edit_catastrophe.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["edit_catastrophe {\n  display: block;\n}\n\n.edit-catastrophe--HeaderContent {\n  display: flex;\n  gap: 0.4em;\n  align-items: center;\n}\n\n.edit-catastrophe--SiteContainer {\n  margin-bottom: 20vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
