// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-thing-move-or-copy-entry-widget {
  display: block;
}

.structure-thing-move-or-copy-entry-widget--Container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-left: -20px;
}

.structure-thing-move-or-copy-entry-widget--Container > * {
  margin-left: 20px;
}

.structure-thing-move-or-copy-entry-widget--Selector {
  flex: 1 1 auto;
}

.structure-thing-move-or-copy-entry-widget--Buttons {
  flex: 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/structureThing/structure-thing-move-or-copy-entry-widget/structure-thing-move-or-copy-entry-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;;EAEf,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["structure-thing-move-or-copy-entry-widget {\n  display: block;\n}\n\n.structure-thing-move-or-copy-entry-widget--Container {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n\n  margin-left: -20px;\n}\n\n.structure-thing-move-or-copy-entry-widget--Container > * {\n  margin-left: 20px;\n}\n\n.structure-thing-move-or-copy-entry-widget--Selector {\n  flex: 1 1 auto;\n}\n\n.structure-thing-move-or-copy-entry-widget--Buttons {\n  flex: 0 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
