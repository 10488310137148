// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-task--PageHeader {
  margin-bottom: 0;
}

.edit-process-task--StepBar {
  margin-bottom: 70px;
}

.edit-process-task--TabSwitch {
  margin-bottom: 35px;
}

.edit-process-task--TabPageSwitcher {
  padding-bottom: 50px;
  min-height: var(--record-it-header-height);
  min-height: calc(
    100vh - var(--record-it-header-height) - 50px - 35px
  ); /* show 50px of the tab switch when scrolled to bottom (35px is space between tabs and switcher) */
}
`, "",{"version":3,"sources":["webpack://./src/pages/edit_process_task/edit_process_task.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,0CAA0C;EAC1C;;GAEC,EAAE,kGAAkG;AACvG","sourcesContent":[".edit-process-task--PageHeader {\n  margin-bottom: 0;\n}\n\n.edit-process-task--StepBar {\n  margin-bottom: 70px;\n}\n\n.edit-process-task--TabSwitch {\n  margin-bottom: 35px;\n}\n\n.edit-process-task--TabPageSwitcher {\n  padding-bottom: 50px;\n  min-height: var(--record-it-header-height);\n  min-height: calc(\n    100vh - var(--record-it-header-height) - 50px - 35px\n  ); /* show 50px of the tab switch when scrolled to bottom (35px is space between tabs and switcher) */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
