// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `config-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;
}

config-list > * + * {
  margin-top: 0.6rem;
}

.config-list--List {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}

.config-list--AddButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/config-list/config-list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["config-list {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: end;\n}\n\nconfig-list > * + * {\n  margin-top: 0.6rem;\n}\n\n.config-list--List {\n  display: flex;\n  flex-direction: column;\n  gap: 0.4rem;\n  width: 100%;\n}\n\n.config-list--AddButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
