// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-thing-edit-entry-dialog--SelectCoordinatesOnMapIcon {
  cursor: pointer;
}

.gallery-thing-edit-entry-dialog--PictureDescription {
  margin-top: 17px;
}

.gallery-thing-edit-entry-dialog--ButtonRefreshPropertyInfo {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-edit-entry-dialog/gallery-thing-edit-entry-dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".gallery-thing-edit-entry-dialog--SelectCoordinatesOnMapIcon {\n  cursor: pointer;\n}\n\n.gallery-thing-edit-entry-dialog--PictureDescription {\n  margin-top: 17px;\n}\n\n.gallery-thing-edit-entry-dialog--ButtonRefreshPropertyInfo {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
