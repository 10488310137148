// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-icon {
  display: inline-block;
}

custom-icon > svg {
  height: 100%;
  width: 100%;
}

custom-icon[data-2x-zoom],
custom-icon[data-1x5-zoom] {
  overflow: hidden;
}

custom-icon[data-2x-zoom] > svg {
  transform: scale(2, 2);
}

custom-icon[data-1x5-zoom] > svg {
  transform: scale(1.5, 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/custom-icon/custom-icon.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["custom-icon {\n  display: inline-block;\n}\n\ncustom-icon > svg {\n  height: 100%;\n  width: 100%;\n}\n\ncustom-icon[data-2x-zoom],\ncustom-icon[data-1x5-zoom] {\n  overflow: hidden;\n}\n\ncustom-icon[data-2x-zoom] > svg {\n  transform: scale(2, 2);\n}\n\ncustom-icon[data-1x5-zoom] > svg {\n  transform: scale(1.5, 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
