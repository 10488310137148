// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `basemap-map {
  display: block;
  width: 100%;
  height: 65vh;
  background: var(--record-it-color-gray-600);

  /*
  this is needed so the leaflet z-index abusing elements don't "leak" outside/in front of this element
  (we create a new stacking context this way)
  */
  transform: translate(0, 0);
}

.basemap-map--MapElement {
  width: 100%;
  height: 100%;
}

.basemap-map--ControlIcon {
  font-size: 17px;
  vertical-align: middle;
}

.basemap-map--MapElement > .waves-ripple {
  transition: all 0.2s ease-out;
  z-index: 500;
}

basemap-map .leaflet-touch .leaflet-control-layers,
basemap-map .leaflet-touch .leaflet-bar {
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

basemap-map .leaflet-popup-content-wrapper {
  border-radius: 2px;
}

basemap-map .leaflet-container a.leaflet-popup-close-button {
  text-align: right;
  padding: 0;
  top: 7px;
  right: 7px;
  width: auto;
  height: auto;
}

basemap-map .leaflet-popup-content p {
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/map/basemap-map/basemap-map.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,2CAA2C;;EAE3C;;;GAGC;EACD,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,QAAQ;EACR,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":["basemap-map {\n  display: block;\n  width: 100%;\n  height: 65vh;\n  background: var(--record-it-color-gray-600);\n\n  /*\n  this is needed so the leaflet z-index abusing elements don't \"leak\" outside/in front of this element\n  (we create a new stacking context this way)\n  */\n  transform: translate(0, 0);\n}\n\n.basemap-map--MapElement {\n  width: 100%;\n  height: 100%;\n}\n\n.basemap-map--ControlIcon {\n  font-size: 17px;\n  vertical-align: middle;\n}\n\n.basemap-map--MapElement > .waves-ripple {\n  transition: all 0.2s ease-out;\n  z-index: 500;\n}\n\nbasemap-map .leaflet-touch .leaflet-control-layers,\nbasemap-map .leaflet-touch .leaflet-bar {\n  border: none;\n  border-radius: 2px;\n  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);\n}\n\nbasemap-map .leaflet-popup-content-wrapper {\n  border-radius: 2px;\n}\n\nbasemap-map .leaflet-container a.leaflet-popup-close-button {\n  text-align: right;\n  padding: 0;\n  top: 7px;\n  right: 7px;\n  width: auto;\n  height: auto;\n}\n\nbasemap-map .leaflet-popup-content p {\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
