// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-select-and-edit-widget {
  display: block;
}

.person-select-and-edit-widget--DuplicatePersons {
  margin-top: 17px;
}

.person-select-and-edit-widget--DuplicatePersonsTitle {
  font-weight: 500;
  color: var(--record-it-color-orange);
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-select-and-edit-widget/person-select-and-edit-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;AACtC","sourcesContent":["person-select-and-edit-widget {\n  display: block;\n}\n\n.person-select-and-edit-widget--DuplicatePersons {\n  margin-top: 17px;\n}\n\n.person-select-and-edit-widget--DuplicatePersonsTitle {\n  font-weight: 500;\n  color: var(--record-it-color-orange);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
