// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-structure-table-widget-entry-row {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/edit-structure-table-widget/edit-structure-table-widget-entry-row/edit-structure-table-widget-entry-row.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":["edit-structure-table-widget-entry-row {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
