// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `last-opened-process-task-groups-list {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/last-opened-process-task-groups-list/last-opened-process-task-groups-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":["last-opened-process-task-groups-list {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
