// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-info-overview-process-task-list {
  display: block;
  --drop-target--hit-box-extra-size: 10px;
}

.process-task-info-overview-process-task-list--ProcessTaskWrapper {
  font-weight: normal;
}

.process-task-info-overview-process-task-list--ProcessTaskWrapper
  + .process-task-info-overview-process-task-list--ProcessTaskWrapper {
  margin-top: 8px;
}

.process-task-info-overview-process-task-list--ProcessTaskIcon {
  vertical-align: middle;
  cursor: move;
}

.process-task-info-overview-process-task-list--ActiveProcessTaskLink {
  color: #4a4a4a;
}

.process-task-info-overview-process-task-list--ActiveProcessTaskLink:hover,
.process-task-info-overview-process-task-list--ActiveProcessTaskLink:active {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-info-overview/process-task-info-overview-process-task-list/process-task-info-overview-process-task-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,qBAAqB;AACvB","sourcesContent":["process-task-info-overview-process-task-list {\n  display: block;\n  --drop-target--hit-box-extra-size: 10px;\n}\n\n.process-task-info-overview-process-task-list--ProcessTaskWrapper {\n  font-weight: normal;\n}\n\n.process-task-info-overview-process-task-list--ProcessTaskWrapper\n  + .process-task-info-overview-process-task-list--ProcessTaskWrapper {\n  margin-top: 8px;\n}\n\n.process-task-info-overview-process-task-list--ProcessTaskIcon {\n  vertical-align: middle;\n  cursor: move;\n}\n\n.process-task-info-overview-process-task-list--ActiveProcessTaskLink {\n  color: #4a4a4a;\n}\n\n.process-task-info-overview-process-task-list--ActiveProcessTaskLink:hover,\n.process-task-info-overview-process-task-list--ActiveProcessTaskLink:active {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
