// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-entry-to-person-widget {
  display: block;
}

.gallery-thing-entry-to-person-widget--owner-list-item {
  padding: 0;
}

.gallery-thing-entry-to-person-widget--owner-list-item:first {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-entry-to-person-widget/gallery-thing-entry-to-person-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":["gallery-thing-entry-to-person-widget {\n  display: block;\n}\n\n.gallery-thing-entry-to-person-widget--owner-list-item {\n  padding: 0;\n}\n\n.gallery-thing-entry-to-person-widget--owner-list-item:first {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
