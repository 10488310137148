// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `multi-picture-input {
  display: block;
}

.multi-picture-input--PictureInput {
  text-transform: initial;
}

.multi-picture-input--PictureInputIcon {
  font-size: 18px;
}

.multi-picture-input--SelectedPictureWrapper {
  display: flex;
  align-items: center;
  gap: 10px 16px;
}

.multi-picture-input--SelectedPicture {
  max-width: 80px;
}

.multi-picture-input--SelectedPictureIcon {
  cursor: pointer;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/multi-picture-input/multi-picture-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB","sourcesContent":["multi-picture-input {\n  display: block;\n}\n\n.multi-picture-input--PictureInput {\n  text-transform: initial;\n}\n\n.multi-picture-input--PictureInputIcon {\n  font-size: 18px;\n}\n\n.multi-picture-input--SelectedPictureWrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px 16px;\n}\n\n.multi-picture-input--SelectedPicture {\n  max-width: 80px;\n}\n\n.multi-picture-input--SelectedPictureIcon {\n  cursor: pointer;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
