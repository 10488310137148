// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `swiping-indicator {
  display: inline-block;
}

.swiping-indicator--IndicatorCircle {
  display: inline-block;

  height: 4px;
  width: 4px;

  border-radius: 50%;
  background: var(--record-it-color-gray-500);
}

.swiping-indicator--IndicatorCircle + .swiping-indicator--IndicatorCircle {
  margin-left: 4px;
}

.swiping-indicator--IndicatorCircleSmall {
  height: 3px;
  width: 3px;
}

.swiping-indicator--IndicatorCircleMedium {
  height: 5px;
  width: 5px;
}

.swiping-indicator--IndicatorCircleActive {
  height: 6px;
  width: 6px;

  background: var(--record-it-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/swiping-indicator/swiping-indicator.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;;EAErB,WAAW;EACX,UAAU;;EAEV,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,UAAU;;EAEV,0CAA0C;AAC5C","sourcesContent":["swiping-indicator {\n  display: inline-block;\n}\n\n.swiping-indicator--IndicatorCircle {\n  display: inline-block;\n\n  height: 4px;\n  width: 4px;\n\n  border-radius: 50%;\n  background: var(--record-it-color-gray-500);\n}\n\n.swiping-indicator--IndicatorCircle + .swiping-indicator--IndicatorCircle {\n  margin-left: 4px;\n}\n\n.swiping-indicator--IndicatorCircleSmall {\n  height: 3px;\n  width: 3px;\n}\n\n.swiping-indicator--IndicatorCircleMedium {\n  height: 5px;\n  width: 5px;\n}\n\n.swiping-indicator--IndicatorCircleActive {\n  height: 6px;\n  width: 6px;\n\n  background: var(--record-it-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
