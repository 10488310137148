// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `list-item-related-entity-counts {
  display: flex;
  flex-flow: row nowrap;
  max-width: calc(var(--counts) * var(--count-max-width));
  width: 100%;
  /* set via the template */
  --counts: 0;
  --count-max-width: 60px;
}

.list-item-related-entity-counts--Count {
  flex: 1 1 auto;
  max-width: var(--count-max-width);
  min-width: 0;
  padding: 5px;
  white-space: nowrap;
  position: relative;
  line-height: 2em;
}

.list-item-related-entity-counts--Count_empty {
  color: var(--record-it-color-red);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/list-item-related-entity-counts/list-item-related-entity-counts.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,uDAAuD;EACvD,WAAW;EACX,yBAAyB;EACzB,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["list-item-related-entity-counts {\n  display: flex;\n  flex-flow: row nowrap;\n  max-width: calc(var(--counts) * var(--count-max-width));\n  width: 100%;\n  /* set via the template */\n  --counts: 0;\n  --count-max-width: 60px;\n}\n\n.list-item-related-entity-counts--Count {\n  flex: 1 1 auto;\n  max-width: var(--count-max-width);\n  min-width: 0;\n  padding: 5px;\n  white-space: nowrap;\n  position: relative;\n  line-height: 2em;\n}\n\n.list-item-related-entity-counts--Count_empty {\n  color: var(--record-it-color-red);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
