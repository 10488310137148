// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tags-widget-list {
  display: block;
}

.tags-widget-list--Tag + .tags-widget-list--Tag {
  margin-top: 6px;
}

.tags-widget-list--Tag {
  display: flex;
  background-color: var(--record-it-color-white);
  border-radius: 3px;
}

.tags-widget-list--Tag > custom-icon {
  padding: 10px;
  font-size: 20px;
  color: var(--record-it-color-gray-800);
  background-color: var(--record-it-color-gray-200);
  cursor: pointer;
}

.tags-widget-list--Tag > div {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/tags-widget-list/tags-widget-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8CAA8C;EAC9C,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sCAAsC;EACtC,iDAAiD;EACjD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":["tags-widget-list {\n  display: block;\n}\n\n.tags-widget-list--Tag + .tags-widget-list--Tag {\n  margin-top: 6px;\n}\n\n.tags-widget-list--Tag {\n  display: flex;\n  background-color: var(--record-it-color-white);\n  border-radius: 3px;\n}\n\n.tags-widget-list--Tag > custom-icon {\n  padding: 10px;\n  font-size: 20px;\n  color: var(--record-it-color-gray-800);\n  background-color: var(--record-it-color-gray-200);\n  cursor: pointer;\n}\n\n.tags-widget-list--Tag > div {\n  padding: 10px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
