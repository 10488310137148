// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-entry-widget-overlay {
  display: none;
  height: 100vh;
}

.edit-entry-widget-overlay--EditEntryWidgetWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  background: var(--record-it-color-gray-200);

  transform: translateY(-10px);
}

.edit-entry-widget-overlay--EditEntryWidget {
  height: 100%;
  margin: 10px 0; /* if you change this you also have to change it in the edit_structure.html */
}

.edit-entry-widget-overlay--EditEntryWidgetPlaceholder {
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/edit-entry-widget-overlay/edit-entry-widget-overlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,gBAAgB;EAChB,2CAA2C;;EAE3C,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,cAAc,EAAE,6EAA6E;AAC/F;;AAEA;AACA","sourcesContent":["edit-entry-widget-overlay {\n  display: none;\n  height: 100vh;\n}\n\n.edit-entry-widget-overlay--EditEntryWidgetWrapper {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  overflow: hidden;\n  background: var(--record-it-color-gray-200);\n\n  transform: translateY(-10px);\n}\n\n.edit-entry-widget-overlay--EditEntryWidget {\n  height: 100%;\n  margin: 10px 0; /* if you change this you also have to change it in the edit_structure.html */\n}\n\n.edit-entry-widget-overlay--EditEntryWidgetPlaceholder {\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
