// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-structure-template-entry-dialog {
  display: block;
}

.edit-structure-template-entry-dialog--IconWrapper {
  padding: 12px;
  line-height: 1em;
}

.edit-structure-template-entry-dialog--IconWrapper
  + .edit-structure-template-entry-dialog--IconWrapper {
  margin-left: 10px;
}

.edit-structure-template-entry-dialog--IconPreview {
  display: flex;
}

.edit-structure-template-entry-dialog--IconWrapper {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-structure-template-entry-dialog/edit-structure-template-entry-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;AACZ","sourcesContent":["edit-structure-template-entry-dialog {\n  display: block;\n}\n\n.edit-structure-template-entry-dialog--IconWrapper {\n  padding: 12px;\n  line-height: 1em;\n}\n\n.edit-structure-template-entry-dialog--IconWrapper\n  + .edit-structure-template-entry-dialog--IconWrapper {\n  margin-left: 10px;\n}\n\n.edit-structure-template-entry-dialog--IconPreview {\n  display: flex;\n}\n\n.edit-structure-template-entry-dialog--IconWrapper {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
