// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-comment-attachment {
  display: inline-block;
}

.defect-comment-attachment--Attachment {
  display: flex;
  flex-direction: row;
  padding: 8px;
  border: 1px solid var(--record-it-color-gray-300);
  border-radius: 3px;
  cursor: pointer;
  gap: 6px;
}

.defect-comment-attachment--AttachmentPicture img {
  width: 50px;
  height: 50px;
}

/*
  Properly align icon in center
*/
.defect-comment-attachment--Icon {
  color: var(--record-it-color-gray-600);
  transform: translateY(3px);
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-comment-attachment/defect-comment-attachment.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,iDAAiD;EACjD,kBAAkB;EAClB,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;CAEC;AACD;EACE,sCAAsC;EACtC,0BAA0B;AAC5B","sourcesContent":["defect-comment-attachment {\n  display: inline-block;\n}\n\n.defect-comment-attachment--Attachment {\n  display: flex;\n  flex-direction: row;\n  padding: 8px;\n  border: 1px solid var(--record-it-color-gray-300);\n  border-radius: 3px;\n  cursor: pointer;\n  gap: 6px;\n}\n\n.defect-comment-attachment--AttachmentPicture img {\n  width: 50px;\n  height: 50px;\n}\n\n/*\n  Properly align icon in center\n*/\n.defect-comment-attachment--Icon {\n  color: var(--record-it-color-gray-600);\n  transform: translateY(3px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
