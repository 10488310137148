// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-report-type-widget {
  display: block;
}

.gallery-thing-report-type-widget--ReportTypeSelectRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gallery-thing-report-type-widget--ReportTypeSelectLabel {
  font-size: 13px;
  width: 100%;
}

.gallery-thing-report-type-widget--ReportTypeSelectRow > report-type-select {
  flex: 1 1 auto;
  margin-right: 15px;
}

.gallery-thing-report-type-widget--CurrentReportTypeText {
  margin-top: 5px;
}

.gallery-thing-report-type-widget--ReportTypeSelectRow,
.gallery-thing-report-type-widget--CurrentReportTypeText {
  margin-bottom: 10px;
}

.gallery-thing-report-type-widget--CurrentReportTypeUpDownloadWrapper {
  margin-right: 10px;
  margin-top: 3px;
  flex: 0 0;
}

.gallery-thing-report-type-widget--SetReportTypeButton {
  flex: 0 1 auto;
  margin-top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-report-type-widget/gallery-thing-report-type-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":["gallery-thing-report-type-widget {\n  display: block;\n}\n\n.gallery-thing-report-type-widget--ReportTypeSelectRow {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.gallery-thing-report-type-widget--ReportTypeSelectLabel {\n  font-size: 13px;\n  width: 100%;\n}\n\n.gallery-thing-report-type-widget--ReportTypeSelectRow > report-type-select {\n  flex: 1 1 auto;\n  margin-right: 15px;\n}\n\n.gallery-thing-report-type-widget--CurrentReportTypeText {\n  margin-top: 5px;\n}\n\n.gallery-thing-report-type-widget--ReportTypeSelectRow,\n.gallery-thing-report-type-widget--CurrentReportTypeText {\n  margin-bottom: 10px;\n}\n\n.gallery-thing-report-type-widget--CurrentReportTypeUpDownloadWrapper {\n  margin-right: 10px;\n  margin-top: 3px;\n  flex: 0 0;\n}\n\n.gallery-thing-report-type-widget--SetReportTypeButton {\n  flex: 0 1 auto;\n  margin-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
