// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `kuk-defect-shortcut {
  display: block;
}

.kuk-defect-shortcut--Main {
  background-color: var(--record-it-color-gray-300);
  padding: 1.8rem 2.4rem;
  margin-top: 3.2rem;
  border-radius: 0.5rem;

  display: flex;
  flex-wrap: wrap;
  column-gap: 4.8rem;
  row-gap: 2.4rem;
  align-items: center;
  justify-content: center;
}

.kuk-defect-shortcut--Icons {
  position: relative;

  border: 0;
  background: inherit;
}

.kuk-defect-shortcut--MainIcon {
  font-size: 10em;
  color: var(--record-it-color-gray-600);
}

.kuk-defect-shortcut--SubIcon {
  font-size: 3em;
  color: var(--record-it-color-primary);
  background-color: white;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0.4rem;
}

.kuk-defect-shortcut--Text {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: end;
  justify-content: space-between;
}

.kuk-defect-shortcut--Description {
  font-size: 1.4em;
  max-width: 32rem;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home_page/kuk-defect-shortcut/kuk-defect-shortcut.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iDAAiD;EACjD,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;;EAErB,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;;EAElB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,qCAAqC;EACrC,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["kuk-defect-shortcut {\n  display: block;\n}\n\n.kuk-defect-shortcut--Main {\n  background-color: var(--record-it-color-gray-300);\n  padding: 1.8rem 2.4rem;\n  margin-top: 3.2rem;\n  border-radius: 0.5rem;\n\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 4.8rem;\n  row-gap: 2.4rem;\n  align-items: center;\n  justify-content: center;\n}\n\n.kuk-defect-shortcut--Icons {\n  position: relative;\n\n  border: 0;\n  background: inherit;\n}\n\n.kuk-defect-shortcut--MainIcon {\n  font-size: 10em;\n  color: var(--record-it-color-gray-600);\n}\n\n.kuk-defect-shortcut--SubIcon {\n  font-size: 3em;\n  color: var(--record-it-color-primary);\n  background-color: white;\n  border-radius: 100%;\n  position: absolute;\n  bottom: 0;\n  right: 0.4rem;\n}\n\n.kuk-defect-shortcut--Text {\n  display: flex;\n  flex-direction: column;\n  gap: 1.2rem;\n  align-items: end;\n  justify-content: space-between;\n}\n\n.kuk-defect-shortcut--Description {\n  font-size: 1.4em;\n  max-width: 32rem;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
