// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `value-calculation-all-results-widget {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.value-calculation-all-results-widget--LabelValuePair {
  display: flex;
}

.value-calculation-all-results-widget--Text {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

label.value-calculation-all-results-widget--Text {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/valueCalculationComponents/value-calculation-all-results-widget/value-calculation-all-results-widget.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["value-calculation-all-results-widget {\n  width: 55%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.value-calculation-all-results-widget--LabelValuePair {\n  display: flex;\n}\n\n.value-calculation-all-results-widget--Text {\n  width: 50%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\nlabel.value-calculation-all-results-widget--Text {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
