// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-person-relations-widget {
  display: block;
}

.edit-person-relations-widget--WarningMessageBox {
  margin-bottom: 17px;
}

.edit-person-relations-widget--AddButton {
  flex: 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/edit-person-relations-widget/edit-person-relations-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["edit-person-relations-widget {\n  display: block;\n}\n\n.edit-person-relations-widget--WarningMessageBox {\n  margin-bottom: 17px;\n}\n\n.edit-person-relations-widget--AddButton {\n  flex: 0 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
