// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-user-group-dialog {
  display: block;
}

.edit-user-group-dialog--SynchronizeUsersCheckbox {
  display: inline-block;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/edit-user-group-dialog/edit-user-group-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":["edit-user-group-dialog {\n  display: block;\n}\n\n.edit-user-group-dialog--SynchronizeUsersCheckbox {\n  display: inline-block;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
