// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-general-widget {
  display: block;
}

.process-task-general-widget--ActionStatusTag {
  display: inline-block;
  padding: 2px 0px 2px 7px;
  border: 1px solid var(--record-it-color-primary);
  color: var(--record-it-color-primary);
  font-size: 16px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.process-task-general-widget--ActionStatusTagName {
  min-width: 35px;
  display: inline-block;
  vertical-align: middle;
}

.process-task-general-widget--ActionStatusTagRemoveIcon {
  vertical-align: middle;
  width: 30px;
  cursor: pointer;
}

.process-taks-general-widget--ActionStatusInlineCreationSection {
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-general-widget/process-task-general-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,gDAAgD;EAChD,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB;;AAEA;AACA","sourcesContent":["process-task-general-widget {\n  display: block;\n}\n\n.process-task-general-widget--ActionStatusTag {\n  display: inline-block;\n  padding: 2px 0px 2px 7px;\n  border: 1px solid var(--record-it-color-primary);\n  color: var(--record-it-color-primary);\n  font-size: 16px;\n  border-radius: 3px;\n  margin-bottom: 10px;\n}\n\n.process-task-general-widget--ActionStatusTagName {\n  min-width: 35px;\n  display: inline-block;\n  vertical-align: middle;\n}\n\n.process-task-general-widget--ActionStatusTagRemoveIcon {\n  vertical-align: middle;\n  width: 30px;\n  cursor: pointer;\n}\n\n.process-taks-general-widget--ActionStatusInlineCreationSection {\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
