// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-card {
  display: block;

  border-radius: 10px;
  overflow: hidden;
  background-color: var(--record-it-color-white);

  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

custom-card.custom-card--Dashed {
  background-color: inherit;
  box-shadow: none;

  border: 3px dashed var(--record-it-color-gray-500);
}

custom-card.custom-card--Clickable:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/custom-card/custom-card.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,mBAAmB;EACnB,gBAAgB;EAChB,8CAA8C;;EAE9C;;mCAEiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;;EAEhB,kDAAkD;AACpD;;AAEA;EACE,eAAe;AACjB","sourcesContent":["custom-card {\n  display: block;\n\n  border-radius: 10px;\n  overflow: hidden;\n  background-color: var(--record-it-color-white);\n\n  box-shadow:\n    0 1px 3px 0 rgb(0 0 0 / 0.1),\n    0 1px 2px -1px rgb(0 0 0 / 0.1);\n}\n\ncustom-card.custom-card--Dashed {\n  background-color: inherit;\n  box-shadow: none;\n\n  border: 3px dashed var(--record-it-color-gray-500);\n}\n\ncustom-card.custom-card--Clickable:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
