// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `filter-person-select {
  display: inline-block;
}

.filter-person-select--PersonSelect {
  margin-right: 10px;
}

.filter-person-select--OtherPersonButton {
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 7px;
}

.filter-person-select--ButtonContainer {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.filter-person-select--ForcedVisiblePersonSelect {
  margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/filter-person-select/filter-person-select.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["filter-person-select {\n  display: inline-block;\n}\n\n.filter-person-select--PersonSelect {\n  margin-right: 10px;\n}\n\n.filter-person-select--OtherPersonButton {\n  display: inline-block;\n  margin-top: 7px;\n  margin-bottom: 7px;\n}\n\n.filter-person-select--ButtonContainer {\n  display: inline-block;\n  margin-left: 5px;\n  vertical-align: middle;\n}\n\n.filter-person-select--ForcedVisiblePersonSelect {\n  margin-bottom: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
