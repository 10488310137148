// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-change-log-entry-group {
  display: block;
}

.defect-change-log-entry-group--Entry {
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-change-log-entry-group/defect-change-log-entry-group.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["defect-change-log-entry-group {\n  display: block;\n}\n\n.defect-change-log-entry-group--Entry {\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
