// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-list-item {
  display: block;
}

.process-task-appointment-list-item--CoordinatorAbbrvColumn {
  flex: 0 0 90px;
  text-align: center;
  line-height: 0px;
}

.process-task-appointment-list-item--CoordinatorAbbrvColumn
  > .process-task-appointment-list-item--CoordinatorAbbrv {
  display: inline-block;
  height: 24px;
  min-width: 24px;
  line-height: 22px;
  border-radius: 12px;

  color: var(--record-it-color-gray-800);
  border: 1px solid;
  text-align: center;
  font-size: 11px;
}

.process-task-appointment-list-item--FinishedIcon {
  color: var(--record-it-color-green);
}

.process-task-appointment-list-item--WarningIcon {
  color: var(--record-it-color-orange);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-list-item/process-task-appointment-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;EAEE,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,mBAAmB;;EAEnB,sCAAsC;EACtC,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["process-task-appointment-list-item {\n  display: block;\n}\n\n.process-task-appointment-list-item--CoordinatorAbbrvColumn {\n  flex: 0 0 90px;\n  text-align: center;\n  line-height: 0px;\n}\n\n.process-task-appointment-list-item--CoordinatorAbbrvColumn\n  > .process-task-appointment-list-item--CoordinatorAbbrv {\n  display: inline-block;\n  height: 24px;\n  min-width: 24px;\n  line-height: 22px;\n  border-radius: 12px;\n\n  color: var(--record-it-color-gray-800);\n  border: 1px solid;\n  text-align: center;\n  font-size: 11px;\n}\n\n.process-task-appointment-list-item--FinishedIcon {\n  color: var(--record-it-color-green);\n}\n\n.process-task-appointment-list-item--WarningIcon {\n  color: var(--record-it-color-orange);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
