// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist-question-list-item {
  display: block;
}

.checklist-question-list-item--Title {
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/checklistComponents/checklist-question-list-item/checklist-question-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":["checklist-question-list-item {\n  display: block;\n}\n\n.checklist-question-list-item--Title {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
