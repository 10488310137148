// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-contact-buttons {
  display: inline-block;
}

.person-contact-buttons--Select {
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
}

.person-contact-buttons--SelectContactTypes {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.person-contact-buttons--ContactTypeIconSeparator {
  display: inline-block;
  vertical-align: middle;
}

.person-contact-buttons--ContactTypeIcon {
  vertical-align: middle;
}

.person-contact-buttons--SelectCaret {
  flex: 0 0 auto;
  vertical-align: middle;
}

.person-contact-buttons--Contact_highlighted {
  background-color: var(
    --record-it-color-highlighted-person-contact-background
  );
}

.person-contact-buttons--ContactMenuNote {
  display: inline-block;
  color: var(--record-it-color-gray-600);
  font-size: 12px;
  margin-top: -1px;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-contact-buttons/person-contact-buttons.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE;;GAEC;AACH;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;EACtC,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["person-contact-buttons {\n  display: inline-block;\n}\n\n.person-contact-buttons--Select {\n  display: flex;\n  flex-flow: row nowrap;\n  cursor: pointer;\n}\n\n.person-contact-buttons--SelectContactTypes {\n  flex: 1 1 auto;\n  min-width: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.person-contact-buttons--ContactTypeIconSeparator {\n  display: inline-block;\n  vertical-align: middle;\n}\n\n.person-contact-buttons--ContactTypeIcon {\n  vertical-align: middle;\n}\n\n.person-contact-buttons--SelectCaret {\n  flex: 0 0 auto;\n  vertical-align: middle;\n}\n\n.person-contact-buttons--Contact_highlighted {\n  background-color: var(\n    --record-it-color-highlighted-person-contact-background\n  );\n}\n\n.person-contact-buttons--ContactMenuNote {\n  display: inline-block;\n  color: var(--record-it-color-gray-600);\n  font-size: 12px;\n  margin-top: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
