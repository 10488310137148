// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-regions-widget {
  display: block;
}

.edit-regions-widget--DeleteButtonWrapper {
  text-align: right;
}

.edit-regions-widget--SearchInput {
  padding-right: 10px;
}

@media (max-width: 767px) {
  .edit-regions-widget--ButtonBar {
    display: flex;
    flex-flow: column-reverse;
  }
  .edit-regions-widget--SearchInput {
    padding-top: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/regionComponents/edit-regions-widget/edit-regions-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;IACb,yBAAyB;EAC3B;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["edit-regions-widget {\n  display: block;\n}\n\n.edit-regions-widget--DeleteButtonWrapper {\n  text-align: right;\n}\n\n.edit-regions-widget--SearchInput {\n  padding-right: 10px;\n}\n\n@media (max-width: 767px) {\n  .edit-regions-widget--ButtonBar {\n    display: flex;\n    flex-flow: column-reverse;\n  }\n  .edit-regions-widget--SearchInput {\n    padding-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
