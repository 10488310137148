// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-coordinates-on-map-dialog--MapWrapper {
  position: relative;
}

.select-coordinates-on-map-dialog--Marker {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  pointer-events: none;
  width: 35px;
  height: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/select-coordinates-on-map-dialog/select-coordinates-on-map-dialog.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;;EAElB,QAAQ;EACR,SAAS;EACT,iCAAiC;EACjC,oBAAoB;EACpB,WAAW;EACX,YAAY;AACd","sourcesContent":[".select-coordinates-on-map-dialog--MapWrapper {\n  position: relative;\n}\n\n.select-coordinates-on-map-dialog--Marker {\n  position: absolute;\n\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -100%);\n  pointer-events: none;\n  width: 35px;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
