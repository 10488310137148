// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-status-icons {
  display: block;
}

.gallery-thing-status-icons--Container {
  display: flex;
  flex-wrap: wrap;

  margin-right: -10px;

  color: var(--record-it-color-light-silver);
  line-height: 32px;
}

.gallery-thing-status-icons--Label {
  margin-right: 16px;
}

.gallery-thing-status-icons--Item {
  width: 32px;
  height: 32px;

  margin-right: 10px;

  text-align: center;

  border: 1px solid var(--record-it-color-light-silver);
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-status-icons/gallery-thing-status-icons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;;EAEf,mBAAmB;;EAEnB,0CAA0C;EAC1C,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,kBAAkB;;EAElB,kBAAkB;;EAElB,qDAAqD;EACrD,kBAAkB;AACpB","sourcesContent":["gallery-thing-status-icons {\n  display: block;\n}\n\n.gallery-thing-status-icons--Container {\n  display: flex;\n  flex-wrap: wrap;\n\n  margin-right: -10px;\n\n  color: var(--record-it-color-light-silver);\n  line-height: 32px;\n}\n\n.gallery-thing-status-icons--Label {\n  margin-right: 16px;\n}\n\n.gallery-thing-status-icons--Item {\n  width: 32px;\n  height: 32px;\n\n  margin-right: 10px;\n\n  text-align: center;\n\n  border: 1px solid var(--record-it-color-light-silver);\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
