// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.structure-project-page-content--IconSection {
  margin-bottom: 16px;
}

.structure-thing-page-content--ListAndEditingWrapper {
  position: relative;
}

.structure-thing-page-content--ShowEntriesModeWrapper {
  margin-bottom: 25px;
  margin-top: 10px;
}

.structure-thing-page-content--ShowEntriesModeLabel {
  display: inline-block;
  color: var(--record-it-color-gray-600);
}

.structure-thing-page-content--ShowEntriesModeSelect {
  display: inline-block;
  max-width: 200px;
  margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/structureThing/structure-project-page-content/structure-project-page-content.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".structure-project-page-content--IconSection {\n  margin-bottom: 16px;\n}\n\n.structure-thing-page-content--ListAndEditingWrapper {\n  position: relative;\n}\n\n.structure-thing-page-content--ShowEntriesModeWrapper {\n  margin-bottom: 25px;\n  margin-top: 10px;\n}\n\n.structure-thing-page-content--ShowEntriesModeLabel {\n  display: inline-block;\n  color: var(--record-it-color-gray-600);\n}\n\n.structure-thing-page-content--ShowEntriesModeSelect {\n  display: inline-block;\n  max-width: 200px;\n  margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
