// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `scaled-image {
  display: inline-block;
  height: 50px;
  width: 50px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.scaled-image--disabled {
  filter: grayscale(100%);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/scaled-image/scaled-image.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;;EAEX,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["scaled-image {\n  display: inline-block;\n  height: 50px;\n  width: 50px;\n\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  overflow: hidden;\n}\n\n.scaled-image--disabled {\n  filter: grayscale(100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
