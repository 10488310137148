// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-plan-based-overview-overlay-defect-marker {
  display: unset;
  cursor: pointer;
}

.gallery-thing-plan-based-overview-overlay-defect-marker--TooltipContentContent {
  position: relative;
  padding: 10px 16px;
  background: var(--record-it-color-gray-300);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);

  min-width: min-content;

  max-width: 90vw;
}

.gallery-thing-plan-based-overview-overlay-defect-marker--DefectTitle {
  margin-right: 30px; /* width of the close icon */
}

.gallery-thing-plan-based-overview-overlay-defect-marker--EditButtonWrapper {
  margin-top: 9px;
}

.gallery-thing-plan-based-overview-overlay-defect-marker--TooltipContentContentCloseIconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.gallery-thing-plan-based-overview-overlay-defect-marker--TooltipContentContentCloseIconContainer
  > * {
  flex: 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-plan-based-overview-overlay/gallery-thing-plan-based-overview-overlay-defect-marker/gallery-thing-plan-based-overview-overlay-defect-marker.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,2CAA2C;EAC3C,2CAA2C;;EAE3C,sBAAsB;;EAEtB,eAAe;AACjB;;AAEA;EACE,kBAAkB,EAAE,4BAA4B;AAClD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":["gallery-thing-plan-based-overview-overlay-defect-marker {\n  display: unset;\n  cursor: pointer;\n}\n\n.gallery-thing-plan-based-overview-overlay-defect-marker--TooltipContentContent {\n  position: relative;\n  padding: 10px 16px;\n  background: var(--record-it-color-gray-300);\n  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);\n\n  min-width: min-content;\n\n  max-width: 90vw;\n}\n\n.gallery-thing-plan-based-overview-overlay-defect-marker--DefectTitle {\n  margin-right: 30px; /* width of the close icon */\n}\n\n.gallery-thing-plan-based-overview-overlay-defect-marker--EditButtonWrapper {\n  margin-top: 9px;\n}\n\n.gallery-thing-plan-based-overview-overlay-defect-marker--TooltipContentContentCloseIconContainer {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  right: 0;\n  height: 30px;\n  width: 30px;\n  cursor: pointer;\n}\n\n.gallery-thing-plan-based-overview-overlay-defect-marker--TooltipContentContentCloseIconContainer\n  > * {\n  flex: 0 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
