// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page--Announcement {
  margin-top: 15px;
  font-size: 15px;
  padding: 15px;
  border: 1px solid var(--record-it-color-primary);
}

.home-page--Content-Container {
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home_page/home_page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,gDAAgD;AAClD;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".home-page--Announcement {\n  margin-top: 15px;\n  font-size: 15px;\n  padding: 15px;\n  border: 1px solid var(--record-it-color-primary);\n}\n\n.home-page--Content-Container {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
