// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synchronization-status-sidebar--Aside {
  left: unset;
  right: 0;

  display: flex;
  flex-flow: column nowrap;
}

.synchronization-status-sidebar--Aside .record-it-sidebar-heading {
  flex: 0 0 auto;
}

.synchronization-status-sidebar--ContentWrapper {
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;

  padding-top: 0;
  padding-right: calc(20px + var(--record-it-safe-area-inset-right));
  padding-bottom: 0;
  padding-left: 20px;
}

.synchronization-status-sidebar--Header {
  font-weight: 500;
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.synchronization-status-sidebar--EntityName {
  font-size: 1rem;
  font-weight: 500;
}

.synchronization-status-sidebar--RetrySynchronizationButton {
  margin-bottom: 17px;
}

.synchronization-status-sidebar--Button {
  width: 100%;
}

.synchronization-status-sidebar--Footer {
  background: var(--record-it-color-gray-800);
  color: white;

  padding-top: 20px;
  padding-left: 20px;
  padding-right: calc(20px + var(--record-it-safe-area-inset-right));
  padding-bottom: calc(20px + var(--record-it-safe-area-inset-bottom));
}
`, "",{"version":3,"sources":["webpack://./src/synchronizationComponents/synchronization-status-sidebar/synchronization-status-sidebar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,QAAQ;;EAER,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,gBAAgB;;EAEhB,cAAc;EACd,kEAAkE;EAClE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2CAA2C;EAC3C,YAAY;;EAEZ,iBAAiB;EACjB,kBAAkB;EAClB,kEAAkE;EAClE,oEAAoE;AACtE","sourcesContent":[".synchronization-status-sidebar--Aside {\n  left: unset;\n  right: 0;\n\n  display: flex;\n  flex-flow: column nowrap;\n}\n\n.synchronization-status-sidebar--Aside .record-it-sidebar-heading {\n  flex: 0 0 auto;\n}\n\n.synchronization-status-sidebar--ContentWrapper {\n  flex: 1 1 auto;\n  min-height: 0;\n  overflow-y: auto;\n\n  padding-top: 0;\n  padding-right: calc(20px + var(--record-it-safe-area-inset-right));\n  padding-bottom: 0;\n  padding-left: 20px;\n}\n\n.synchronization-status-sidebar--Header {\n  font-weight: 500;\n  font-size: 1.3rem;\n  margin-top: 1rem;\n  margin-bottom: 0.5rem;\n}\n\n.synchronization-status-sidebar--EntityName {\n  font-size: 1rem;\n  font-weight: 500;\n}\n\n.synchronization-status-sidebar--RetrySynchronizationButton {\n  margin-bottom: 17px;\n}\n\n.synchronization-status-sidebar--Button {\n  width: 100%;\n}\n\n.synchronization-status-sidebar--Footer {\n  background: var(--record-it-color-gray-800);\n  color: white;\n\n  padding-top: 20px;\n  padding-left: 20px;\n  padding-right: calc(20px + var(--record-it-safe-area-inset-right));\n  padding-bottom: calc(20px + var(--record-it-safe-area-inset-bottom));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
