// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ultra-rapid-fire-widget {
  display: block;
}

.ultra-rapid-fire-widget--CloseButton {
  position: absolute;
  top: 16px;
  right: 22px; /* TODO */

  min-width: 118px;
  height: 40px;
}

.ultra-rapid-fire-widget--CreateDefectButton {
  height: 40px;
  margin-top: 16px;
}

.ultra-rapid-fire-widget--ButtonGroup {
  position: absolute;
  bottom: 15px;
  left: 25px;

  display: flex;
  flex-direction: column-reverse;
  gap: 25px;
}

@media (orientation: portrait) {
  .ultra-rapid-fire-widget--CloseButton {
    top: 16px;
    right: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/ultra-rapid-fire-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW,EAAE,SAAS;;EAEtB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;;EAEV,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE;IACE,SAAS;IACT,WAAW;EACb;AACF","sourcesContent":["ultra-rapid-fire-widget {\n  display: block;\n}\n\n.ultra-rapid-fire-widget--CloseButton {\n  position: absolute;\n  top: 16px;\n  right: 22px; /* TODO */\n\n  min-width: 118px;\n  height: 40px;\n}\n\n.ultra-rapid-fire-widget--CreateDefectButton {\n  height: 40px;\n  margin-top: 16px;\n}\n\n.ultra-rapid-fire-widget--ButtonGroup {\n  position: absolute;\n  bottom: 15px;\n  left: 25px;\n\n  display: flex;\n  flex-direction: column-reverse;\n  gap: 25px;\n}\n\n@media (orientation: portrait) {\n  .ultra-rapid-fire-widget--CloseButton {\n    top: 16px;\n    right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
