// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `selected-picture-picture-scroller {
  display: block;
  --selected-picture-picture-scroller--picture-size: 100%;
}

.selected-picture-picture-scroller--PictureScroller {
  --picture-scroller--picture-size: var(
    --selected-picture-picture-scroller--picture-size
  );
}
`, "",{"version":3,"sources":["webpack://./src/picture/selected-picture-picture-scroller/selected-picture-picture-scroller.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uDAAuD;AACzD;;AAEA;EACE;;GAEC;AACH","sourcesContent":["selected-picture-picture-scroller {\n  display: block;\n  --selected-picture-picture-scroller--picture-size: 100%;\n}\n\n.selected-picture-picture-scroller--PictureScroller {\n  --picture-scroller--picture-size: var(\n    --selected-picture-picture-scroller--picture-size\n  );\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
