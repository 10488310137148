// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-status-select {
  display: block;
}

.defect-status-select--Label {
  text-transform: none;
  font-size: 0.7em;
}

.defect-status-select--Row {
  display: flex;
  gap: 18px;
  height: 32px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/defect-status-select/defect-status-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["defect-status-select {\n  display: block;\n}\n\n.defect-status-select--Label {\n  text-transform: none;\n  font-size: 0.7em;\n}\n\n.defect-status-select--Row {\n  display: flex;\n  gap: 18px;\n  height: 32px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
