// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-picture-overview {
  display: block;
  overflow: hidden;
  will-change: transform;
}

.gallery-thing-picture-overview--Header {
  font-size: 20px;
  font-weight: 500;
  color: var(--record-it-color-secondary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.gallery-thing-picture-overview--NoPicturesInfoText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 30px 10px;
}

.gallery-thing-picture-overview--NoFilteredPictureGroups {
  display: block;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
}

.gallery-thing-picture-overview--SeparatorMiddleContent {
  padding: 0 24px;
}

.gallery-thing-picture-overview--SubSeparatorDeleteArea {
  cursor: pointer;
  color: var(--record-it-color-red);
}

.gallery-thing-picture-overview--ProjectDescription {
  font-size: 13px;
  color: var(--record-it-color-gray-700);
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-picture-overview/gallery-thing-picture-overview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,uCAAuC;EACvC,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC","sourcesContent":["gallery-thing-picture-overview {\n  display: block;\n  overflow: hidden;\n  will-change: transform;\n}\n\n.gallery-thing-picture-overview--Header {\n  font-size: 20px;\n  font-weight: 500;\n  color: var(--record-it-color-secondary);\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  margin-bottom: 10px;\n}\n\n.gallery-thing-picture-overview--NoPicturesInfoText {\n  font-size: 16px;\n  font-weight: 400;\n  text-align: center;\n  padding: 30px 10px;\n}\n\n.gallery-thing-picture-overview--NoFilteredPictureGroups {\n  display: block;\n  margin: 20px 0;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.gallery-thing-picture-overview--SeparatorMiddleContent {\n  padding: 0 24px;\n}\n\n.gallery-thing-picture-overview--SubSeparatorDeleteArea {\n  cursor: pointer;\n  color: var(--record-it-color-red);\n}\n\n.gallery-thing-picture-overview--ProjectDescription {\n  font-size: 13px;\n  color: var(--record-it-color-gray-700);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
