// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-list-multi-select {
  display: block;
}

.person-list-multi-select--Container {
  margin-top: -3px;
}

.person-list-multi-select--ContainerDisabled {
  opacity: 0.7;
}

.person-list-multi-select--PersonItem {
  display: flex;
  align-items: center;

  margin-top: 3px;

  cursor: pointer;
}

.person-list-multi-select--PersonItemCheckbox {
  flex: 0 0 32px;
}

.person-list-multi-select--PersonItemLabel {
  flex: 1 1 auto;

  height: 36px;
  line-height: 36px;

  padding: 0 8px;

  border-radius: 3px;
  background: white;
  opacity: 0.55;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  transition: 0.3s ease opacity;
}

.person-list-multi-select--PersonItemLabelActivated {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-list-multi-select/person-list-multi-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;;EAEnB,eAAe;;EAEf,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;;EAEd,YAAY;EACZ,iBAAiB;;EAEjB,cAAc;;EAEd,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;;EAEb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;;EAEhB,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ","sourcesContent":["person-list-multi-select {\n  display: block;\n}\n\n.person-list-multi-select--Container {\n  margin-top: -3px;\n}\n\n.person-list-multi-select--ContainerDisabled {\n  opacity: 0.7;\n}\n\n.person-list-multi-select--PersonItem {\n  display: flex;\n  align-items: center;\n\n  margin-top: 3px;\n\n  cursor: pointer;\n}\n\n.person-list-multi-select--PersonItemCheckbox {\n  flex: 0 0 32px;\n}\n\n.person-list-multi-select--PersonItemLabel {\n  flex: 1 1 auto;\n\n  height: 36px;\n  line-height: 36px;\n\n  padding: 0 8px;\n\n  border-radius: 3px;\n  background: white;\n  opacity: 0.55;\n\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  transition: 0.3s ease opacity;\n}\n\n.person-list-multi-select--PersonItemLabelActivated {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
