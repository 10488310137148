// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-process-task-measure-point-reading-table {
  display: block;
}

.edit-process-task-measure-point-reading-table--Row {
  display: flex;
  flex-flow: row nowrap;
}

.edit-process-task-measure-point-reading-table--HeaderRow {
  font-weight: 500;
}

.edit-process-task-measure-point-reading-table--HeaderRow
  .edit-process-task-measure-point-reading-table--ReadingProperty {
  text-align: center;
}

.edit-process-task-measure-point-reading-table--PropertyName {
  flex: 0 0 70px;
  align-self: center;
}

.edit-process-task-measure-point-reading-table--ReadingProperty {
  flex: 1 1 0%;
  margin-left: 17px;
  align-self: center;
}

.edit-process-task-measure-point-reading-table--ReadingPropertyInputFieldPlaceholder {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-task-measure-point-reading-table/edit-process-task-measure-point-reading-table.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["edit-process-task-measure-point-reading-table {\n  display: block;\n}\n\n.edit-process-task-measure-point-reading-table--Row {\n  display: flex;\n  flex-flow: row nowrap;\n}\n\n.edit-process-task-measure-point-reading-table--HeaderRow {\n  font-weight: 500;\n}\n\n.edit-process-task-measure-point-reading-table--HeaderRow\n  .edit-process-task-measure-point-reading-table--ReadingProperty {\n  text-align: center;\n}\n\n.edit-process-task-measure-point-reading-table--PropertyName {\n  flex: 0 0 70px;\n  align-self: center;\n}\n\n.edit-process-task-measure-point-reading-table--ReadingProperty {\n  flex: 1 1 0%;\n  margin-left: 17px;\n  align-self: center;\n}\n\n.edit-process-task-measure-point-reading-table--ReadingPropertyInputFieldPlaceholder {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
