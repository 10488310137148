// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-info-overview-assignee-widget {
  display: block;
}

.process-task-info-overview-assignee-widget--EditText {
  display: inline-block;
  position: relative;
}

.process-task-info-overview-assignee-widget--MenuItem {
  cursor: pointer;
}

.process-task-info-overview-assignee-widget--ProcessTaskGroupInfoText {
  white-space: pre-wrap;
}

.process-task-info-overview-assignee-widget--ProcessTaskGroupPreviousAssigneeHint {
  white-space: pre-wrap;
  color: var(--record-it-color-gray-600);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-info-overview/process-task-info-overview-assignee-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;AACxC","sourcesContent":["process-task-info-overview-assignee-widget {\n  display: block;\n}\n\n.process-task-info-overview-assignee-widget--EditText {\n  display: inline-block;\n  position: relative;\n}\n\n.process-task-info-overview-assignee-widget--MenuItem {\n  cursor: pointer;\n}\n\n.process-task-info-overview-assignee-widget--ProcessTaskGroupInfoText {\n  white-space: pre-wrap;\n}\n\n.process-task-info-overview-assignee-widget--ProcessTaskGroupPreviousAssigneeHint {\n  white-space: pre-wrap;\n  color: var(--record-it-color-gray-600);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
