// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist-manage-question-sets-dialog {
  display: block;
}

.checklist-manage-question-sets-dialog--Wrapper > * + * {
  margin-top: 0.6em;
}

.checklist-manage-question-sets-dialog--Header {
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 0.4em;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/checklist-manage-question-sets-dialog/checklist-manage-question-sets-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":["checklist-manage-question-sets-dialog {\n  display: block;\n}\n\n.checklist-manage-question-sets-dialog--Wrapper > * + * {\n  margin-top: 0.6em;\n}\n\n.checklist-manage-question-sets-dialog--Header {\n  font-weight: bold;\n  font-size: 1.6em;\n  margin-bottom: 0.4em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
