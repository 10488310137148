// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-bulk-edit-panel {
  display: block;

  margin-top: 30px;
  margin-bottom: 30px;
}

.gallery-thing-bulk-edit-panel--WidgetRow {
  display: flex;
  flex-flow: row wrap;

  width: 100%;

  margin-left: -20px;
  margin-top: -20px;
}

.gallery-thing-bulk-edit-panel--WidgetRow > * {
  margin-left: 20px;
  margin-top: 20px;
}

.gallery-thing-bulk-edit-panel--WidgetWrapper {
  min-width: 100px;
  max-width: 500px;
  align-self: flex-end;
}

.gallery-thing-bulk-edit-panel--WidgetLabel {
  font-size: 13px;
  color: var(--record-it-color-gray-600);
  margin-bottom: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-bulk-edit-panel/gallery-thing-bulk-edit-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;;EAEnB,WAAW;;EAEX,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,sCAAsC;EACtC,kBAAkB;AACpB","sourcesContent":["gallery-thing-bulk-edit-panel {\n  display: block;\n\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.gallery-thing-bulk-edit-panel--WidgetRow {\n  display: flex;\n  flex-flow: row wrap;\n\n  width: 100%;\n\n  margin-left: -20px;\n  margin-top: -20px;\n}\n\n.gallery-thing-bulk-edit-panel--WidgetRow > * {\n  margin-left: 20px;\n  margin-top: 20px;\n}\n\n.gallery-thing-bulk-edit-panel--WidgetWrapper {\n  min-width: 100px;\n  max-width: 500px;\n  align-self: flex-end;\n}\n\n.gallery-thing-bulk-edit-panel--WidgetLabel {\n  font-size: 13px;\n  color: var(--record-it-color-gray-600);\n  margin-bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
