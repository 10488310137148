// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `page-message-box {
  display: block;
}

.page-message-box--Container {
  display: flex;

  margin: 20px 0px;
  padding: 20px;

  border: 1px solid var(--record-it-color-gray-600);
  border-radius: 10px;

  text-align: center;
}

.page-message-box--Message {
  flex: 1 1 auto;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/page-message-box/page-message-box.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;;EAEb,gBAAgB;EAChB,aAAa;;EAEb,iDAAiD;EACjD,mBAAmB;;EAEnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,YAAY;AACd","sourcesContent":["page-message-box {\n  display: block;\n}\n\n.page-message-box--Container {\n  display: flex;\n\n  margin: 20px 0px;\n  padding: 20px;\n\n  border: 1px solid var(--record-it-color-gray-600);\n  border-radius: 10px;\n\n  text-align: center;\n}\n\n.page-message-box--Message {\n  flex: 1 1 auto;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
