// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-user-widget {
  display: block;
}

.process-task-appointment-user-widget--UserAvailableResponse {
  margin-top: 7px;
}

.process-task-appointment-user-widget--CirclePreloaderContainer {
  display: inline-block;
  position: relative;
  height: 24px;
  width: 24px;
  vertical-align: middle;
  margin-left: 7px;
}

.process-task-appointment-user-widget--LoadingMessage,
.process-task-appointment-user-widget--NotAvailableIcon,
.process-task-appointment-user-widget--AvailableIcon,
.process-task-appointment-user-widget--MessageWithIconText {
  vertical-align: middle;
}

.process-task-appointment-user-widget--AvailableIcon {
  color: var(--record-it-color-green);
}

.process-task-appointment-user-widget--NotAvailableIcon {
  color: var(--record-it-color-red);
}

.process-task-appointment-user-widget--Columns {
  display: flex;
}

.process-task-appointment-user-widget--Column {
  flex: 1 1 50%;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-user-widget/process-task-appointment-user-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;;;;EAIE,sBAAsB;AACxB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":["process-task-appointment-user-widget {\n  display: block;\n}\n\n.process-task-appointment-user-widget--UserAvailableResponse {\n  margin-top: 7px;\n}\n\n.process-task-appointment-user-widget--CirclePreloaderContainer {\n  display: inline-block;\n  position: relative;\n  height: 24px;\n  width: 24px;\n  vertical-align: middle;\n  margin-left: 7px;\n}\n\n.process-task-appointment-user-widget--LoadingMessage,\n.process-task-appointment-user-widget--NotAvailableIcon,\n.process-task-appointment-user-widget--AvailableIcon,\n.process-task-appointment-user-widget--MessageWithIconText {\n  vertical-align: middle;\n}\n\n.process-task-appointment-user-widget--AvailableIcon {\n  color: var(--record-it-color-green);\n}\n\n.process-task-appointment-user-widget--NotAvailableIcon {\n  color: var(--record-it-color-red);\n}\n\n.process-task-appointment-user-widget--Columns {\n  display: flex;\n}\n\n.process-task-appointment-user-widget--Column {\n  flex: 1 1 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
