// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-container-multi-toggle--ExpandIconWrapper {
  flex: 0 0 auto;
  padding-left: 10px;
  color: #878787;
  cursor: pointer;
}

.expandable-container-multi-toggle--ExpandIconWrapper > * {
  font-size: 1.5em;
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/expandable-container-multi-toggle/expandable-container-multi-toggle.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".expandable-container-multi-toggle--ExpandIconWrapper {\n  flex: 0 0 auto;\n  padding-left: 10px;\n  color: #878787;\n  cursor: pointer;\n}\n\n.expandable-container-multi-toggle--ExpandIconWrapper > * {\n  font-size: 1.5em;\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
