// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-person-widget {
  display: block;
}

.edit-person-widget--FirstExpandableContainer {
  margin-top: 0;
}

.edit-person-widget--ZipMunicipalityRow {
  display: flex;
  flex-flow: row wrap;
}

.edit-person-widget--ZipMunicipalityRow > * {
  flex: 1 1 50%;
}

@media all and (max-width: 767px) {
  .edit-person-widget--ZipMunicipalityRow {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/edit-person-widget/edit-person-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":["edit-person-widget {\n  display: block;\n}\n\n.edit-person-widget--FirstExpandableContainer {\n  margin-top: 0;\n}\n\n.edit-person-widget--ZipMunicipalityRow {\n  display: flex;\n  flex-flow: row wrap;\n}\n\n.edit-person-widget--ZipMunicipalityRow > * {\n  flex: 1 1 50%;\n}\n\n@media all and (max-width: 767px) {\n  .edit-person-widget--ZipMunicipalityRow {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
