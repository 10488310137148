// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-defect-overview-action-bar {
  display: block;
}

.gallery-thing-defect-overview-action-bar--Icon {
  cursor: pointer;
}

.gallery-thing-defect-overview-action-bar--IconActive {
  color: var(--record-it-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-overview-action-bar/gallery-thing-defect-overview-action-bar/gallery-thing-defect-overview-action-bar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":["gallery-thing-defect-overview-action-bar {\n  display: block;\n}\n\n.gallery-thing-defect-overview-action-bar--Icon {\n  cursor: pointer;\n}\n\n.gallery-thing-defect-overview-action-bar--IconActive {\n  color: var(--record-it-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
