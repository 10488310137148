// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist-thing-overview {
  display: block;
}

checklist-thing-overview > * + * {
  margin-top: 3.6em;
}

.checklist-thing-overview--HeaderGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2em;
}

.checklist-thing-overview--GridPictureItem {
  width: min(100%, 400px);
  align-self: start;
}

.checklist-thing-overview--MainTitlePicture {
  width: 100%;
  aspect-ratio: 1/1;
}

.checklist-thing-overview--GridDetailsItem {
  grid-column: 2 / span 2;
}

.checklist-thing-overview--GridItemPadding {
  padding: 1em 1.4em;
}

.checklist-thing-overview--EditDetailsHeaderIcon {
  font-size: 0.6em;
  color: var(--record-it-color-gray-700);
}

.checklist-thing-overview--TextButton {
  background: transparent;
  color: var(--record-it-color-gray-700);
  text-decoration: underline;
  border: 0;
  padding: 0.8em;
}

.checklist-thing-overview--PropertyInputField {
  margin: 0;
}

.checklist-thing-overview--PersonWidget {
  padding: 1em;
}

@media (max-width: 767px) {
  .checklist-thing-overview--GridPictureItem {
    grid-column: span 3;
    justify-self: center;
  }

  .checklist-thing-overview--GridDetailsItem {
    grid-column: span 3;
  }

  .checklist-thing-overview--PropertyInputField {
    margin: 0 0 1em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/checklistComponents/checklist-thing-overview/checklist-thing-overview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,uBAAuB;EACvB,sCAAsC;EACtC,0BAA0B;EAC1B,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,mBAAmB;IACnB,oBAAoB;EACtB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["checklist-thing-overview {\n  display: block;\n}\n\nchecklist-thing-overview > * + * {\n  margin-top: 3.6em;\n}\n\n.checklist-thing-overview--HeaderGrid {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 1.2em;\n}\n\n.checklist-thing-overview--GridPictureItem {\n  width: min(100%, 400px);\n  align-self: start;\n}\n\n.checklist-thing-overview--MainTitlePicture {\n  width: 100%;\n  aspect-ratio: 1/1;\n}\n\n.checklist-thing-overview--GridDetailsItem {\n  grid-column: 2 / span 2;\n}\n\n.checklist-thing-overview--GridItemPadding {\n  padding: 1em 1.4em;\n}\n\n.checklist-thing-overview--EditDetailsHeaderIcon {\n  font-size: 0.6em;\n  color: var(--record-it-color-gray-700);\n}\n\n.checklist-thing-overview--TextButton {\n  background: transparent;\n  color: var(--record-it-color-gray-700);\n  text-decoration: underline;\n  border: 0;\n  padding: 0.8em;\n}\n\n.checklist-thing-overview--PropertyInputField {\n  margin: 0;\n}\n\n.checklist-thing-overview--PersonWidget {\n  padding: 1em;\n}\n\n@media (max-width: 767px) {\n  .checklist-thing-overview--GridPictureItem {\n    grid-column: span 3;\n    justify-self: center;\n  }\n\n  .checklist-thing-overview--GridDetailsItem {\n    grid-column: span 3;\n  }\n\n  .checklist-thing-overview--PropertyInputField {\n    margin: 0 0 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
