// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login--Header {
  position: relative;
  height: 105px;
  border-bottom: 3px solid var(--record-it-color-primary);
  background: var(--record-it-color-secondary);
}

.login--HeaderLogo {
  position: absolute;
  top: 100%;
  left: 50%;
  height: 64px;
  width: 64px;

  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/pageRoots/login/login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uDAAuD;EACvD,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,YAAY;EACZ,WAAW;;EAEX,gCAAgC;AAClC","sourcesContent":[".login--Header {\n  position: relative;\n  height: 105px;\n  border-bottom: 3px solid var(--record-it-color-primary);\n  background: var(--record-it-color-secondary);\n}\n\n.login--HeaderLogo {\n  position: absolute;\n  top: 100%;\n  left: 50%;\n  height: 64px;\n  width: 64px;\n\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
