// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `user-group-user-role-widget {
  display: block;
}

.user-group-user-role-widget--Row,
.user-group-user-role-widget--HeaderRow {
  display: flex;
  flex-flow: row nowrap;
  padding: 14px 0px;
  align-items: center;
}

.user-group-user-role-widget--Row {
  border-bottom: 1px solid var(--record-it-color-gray-500);
}

.user-group-user-role-widget--HeaderRow {
  color: var(--record-it-color-dark-grey);
  border-bottom: 2px solid var(--record-it-color-gray-500);
}

.user-group-user-role-widget--NameColumn {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  word-wrap: break-word;
}

.user-group-user-role-widget--CheckboxColumn {
  flex: 0 0 100px;
  text-align: center;
}

.user-group-user-role-widget--ButtonBar {
  margin-top: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/userRoleComponents/user-group-user-role-widget/user-group-user-role-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;;EAEE,aAAa;EACb,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE,uCAAuC;EACvC,wDAAwD;AAC1D;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["user-group-user-role-widget {\n  display: block;\n}\n\n.user-group-user-role-widget--Row,\n.user-group-user-role-widget--HeaderRow {\n  display: flex;\n  flex-flow: row nowrap;\n  padding: 14px 0px;\n  align-items: center;\n}\n\n.user-group-user-role-widget--Row {\n  border-bottom: 1px solid var(--record-it-color-gray-500);\n}\n\n.user-group-user-role-widget--HeaderRow {\n  color: var(--record-it-color-dark-grey);\n  border-bottom: 2px solid var(--record-it-color-gray-500);\n}\n\n.user-group-user-role-widget--NameColumn {\n  flex: 1 1 auto;\n  min-width: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  font-weight: 400;\n  word-wrap: break-word;\n}\n\n.user-group-user-role-widget--CheckboxColumn {\n  flex: 0 0 100px;\n  text-align: center;\n}\n\n.user-group-user-role-widget--ButtonBar {\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
