// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-thing-main-contact-bank-account-data-dialog {
  display: none;
}

.edit-thing-main-contact-bank-account-data-dialog--MainContactName {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-thing-main-contact-bank-account-data-dialog/edit-thing-main-contact-bank-account-data-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["edit-thing-main-contact-bank-account-data-dialog {\n  display: none;\n}\n\n.edit-thing-main-contact-bank-account-data-dialog--MainContactName {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
