// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-group-multiple-contact-persons-widget {
  display: block;
}
.process-task-group-multiple-contact-persons-widget--PersonDeleteButton {
  margin-top: 10px;
}

.process-task-group-multiple-contact-persons-widget--SelectThingGroupOwnerPersonText {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-group-multiple-contact-persons-widget/process-task-group-multiple-contact-persons-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["process-task-group-multiple-contact-persons-widget {\n  display: block;\n}\n.process-task-group-multiple-contact-persons-widget--PersonDeleteButton {\n  margin-top: 10px;\n}\n\n.process-task-group-multiple-contact-persons-widget--SelectThingGroupOwnerPersonText {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
