// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-device-status-icon {
  display: inline-block;
}

.process-task-device-status-icon--TransitionDirectionIconWrapper {
  margin-bottom: -5px;
  text-align: center;
}

.process-task-device-status-icon--TransitionDirectionIcon {
  height: 1em;
  width: 0.8em;
  overflow: hidden;
  display: block;
}

.process-task-device-status-icon--Icon {
  transition: 0.3s ease transform;
}

.process-task-device-status-icon--Icon[data-device-status='ready'] {
  transform: rotateZ(-45deg);
}

.process-task-device-status-icon--Icon[data-device-status='deployed'] {
  transform: rotateZ(0deg);
}

.process-task-device-status-icon--Icon[data-device-status='finished'] {
  transform: rotateZ(45deg);
  opacity: 0.6;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-device-status-icon/process-task-device-status-icon.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["process-task-device-status-icon {\n  display: inline-block;\n}\n\n.process-task-device-status-icon--TransitionDirectionIconWrapper {\n  margin-bottom: -5px;\n  text-align: center;\n}\n\n.process-task-device-status-icon--TransitionDirectionIcon {\n  height: 1em;\n  width: 0.8em;\n  overflow: hidden;\n  display: block;\n}\n\n.process-task-device-status-icon--Icon {\n  transition: 0.3s ease transform;\n}\n\n.process-task-device-status-icon--Icon[data-device-status='ready'] {\n  transform: rotateZ(-45deg);\n}\n\n.process-task-device-status-icon--Icon[data-device-status='deployed'] {\n  transform: rotateZ(0deg);\n}\n\n.process-task-device-status-icon--Icon[data-device-status='finished'] {\n  transform: rotateZ(45deg);\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
