// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `help-dialog {
  display: block;
}

.help-dialog--Columns {
  display: flex;
  column-gap: 4rem;
  row-gap: 1rem;
  flex-wrap: wrap;
}

.help-dialog--Columns > * {
  flex: 1 0 300px;
}

.help-dialog--CardList > * {
  display: block;
}

.help-dialog--CardList > * + * {
  margin-top: 0.6rem;
}

.help-dialog--Card {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.help-dialog--Pill {
  margin-left: 0.4rem;
  color: var(--record-it-color-black);
  font-size: 0.8em;
  padding: 0.2rem 0.6rem;
  border-radius: 6px;
  background-color: var(--record-it-color-gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/help-dialog/help-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,mCAAmC;EACnC,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,iDAAiD;AACnD","sourcesContent":["help-dialog {\n  display: block;\n}\n\n.help-dialog--Columns {\n  display: flex;\n  column-gap: 4rem;\n  row-gap: 1rem;\n  flex-wrap: wrap;\n}\n\n.help-dialog--Columns > * {\n  flex: 1 0 300px;\n}\n\n.help-dialog--CardList > * {\n  display: block;\n}\n\n.help-dialog--CardList > * + * {\n  margin-top: 0.6rem;\n}\n\n.help-dialog--Card {\n  padding: 1rem;\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}\n\n.help-dialog--Pill {\n  margin-left: 0.4rem;\n  color: var(--record-it-color-black);\n  font-size: 0.8em;\n  padding: 0.2rem 0.6rem;\n  border-radius: 6px;\n  background-color: var(--record-it-color-gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
