// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `calendar-year-select {
  display: block;
}

.calendar-year-select--Bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
}

.calendar-year-select--YearChangeButton {
  border: 1px solid var(--record-it-color-gray-500);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
}

.calendar-year-select--Year {
  padding: 0.5em 1em;
  cursor: pointer;
}

.calendar-year-select--YearLabel {
  padding: 0.3em 0.5em;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-year-select--SelectedYearLabel {
  background-color: var(--record-it-color-primary);
  color: var(--record-it-color-white);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/calendar-year-select/calendar-year-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iDAAiD;EACjD,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gDAAgD;EAChD,mCAAmC;AACrC","sourcesContent":["calendar-year-select {\n  display: block;\n}\n\n.calendar-year-select--Bar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 65px;\n}\n\n.calendar-year-select--YearChangeButton {\n  border: 1px solid var(--record-it-color-gray-500);\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 1em;\n}\n\n.calendar-year-select--Year {\n  padding: 0.5em 1em;\n  cursor: pointer;\n}\n\n.calendar-year-select--YearLabel {\n  padding: 0.3em 0.5em;\n  border-radius: 3px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.calendar-year-select--SelectedYearLabel {\n  background-color: var(--record-it-color-primary);\n  color: var(--record-it-color-white);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
