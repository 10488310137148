// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-select-picture-group-widget {
  display: inline-block;
}

.gallery-thing-select-picture-group-widget--SelectAllArea {
  cursor: pointer;
}

.gallery-thing-select-picture-group-widget--SelectAllArea:not(
    :last-child
  )::after {
  content: '|';
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-select-picture-group-widget/gallery-thing-select-picture-group-widget.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,YAAY;AACd","sourcesContent":["gallery-thing-select-picture-group-widget {\n  display: inline-block;\n}\n\n.gallery-thing-select-picture-group-widget--SelectAllArea {\n  cursor: pointer;\n}\n\n.gallery-thing-select-picture-group-widget--SelectAllArea:not(\n    :last-child\n  )::after {\n  content: '|';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
