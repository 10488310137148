// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tooltip-content {
  display: none;
}

.tooltip-content--Content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: unset;
  right: unset;
}

.tooltip-content--TriangleUp,
.tooltip-content--TriangleDown {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  left: 50%;
}

.tooltip-content--TriangleUp {
  transform: translate(-50%, -100%);
  top: 0;
  border-bottom: 12px solid var(--record-it-color-gray-100);
}

.tooltip-content--TriangleDown {
  transform: translate(-50%, 0);
  top: 100%;
  border-top: 12px solid var(--record-it-color-gray-100);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/tooltip-content/tooltip-content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,MAAM;EACN,OAAO;EACP,aAAa;EACb,YAAY;AACd;;AAEA;;EAEE,kCAAkC;EAClC,mCAAmC;EACnC,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,iCAAiC;EACjC,MAAM;EACN,yDAAyD;AAC3D;;AAEA;EACE,6BAA6B;EAC7B,SAAS;EACT,sDAAsD;AACxD","sourcesContent":["tooltip-content {\n  display: none;\n}\n\n.tooltip-content--Content {\n  position: absolute;\n  z-index: 1;\n  top: 0;\n  left: 0;\n  bottom: unset;\n  right: unset;\n}\n\n.tooltip-content--TriangleUp,\n.tooltip-content--TriangleDown {\n  border-left: 8px solid transparent;\n  border-right: 8px solid transparent;\n  position: absolute;\n  left: 50%;\n}\n\n.tooltip-content--TriangleUp {\n  transform: translate(-50%, -100%);\n  top: 0;\n  border-bottom: 12px solid var(--record-it-color-gray-100);\n}\n\n.tooltip-content--TriangleDown {\n  transform: translate(-50%, 0);\n  top: 100%;\n  border-top: 12px solid var(--record-it-color-gray-100);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
