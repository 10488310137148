// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `manage-property-definitions-widget {
  display: block;
}

.manage-property-definitions-widget--ThingTypeImportBlock {
  display: flex;
  gap: 14px;
}

.manage-property-definitions-widget--ThingTypeImportBlockCustomSelect {
  flex: 1 0 auto;
}

.manage-property-definitions-widget--TemplateBlock {
  display: flex;
  width: 100%;
  align-items: center;
}

.manage-property-definitions-widget--TemplateBlock:not(:last-child) {
  margin-bottom: 20px;
}

.manage-property-definitions-widget--WarningIcon {
  display: flex;
  align-items: end;
  gap: 10px;

  margin-bottom: 20px;

  color: var(--record-it-color-orange);
}
`, "",{"version":3,"sources":["webpack://./src/propertyComponents/manage-property-definitions-widget/manage-property-definitions-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAS;;EAET,mBAAmB;;EAEnB,oCAAoC;AACtC","sourcesContent":["manage-property-definitions-widget {\n  display: block;\n}\n\n.manage-property-definitions-widget--ThingTypeImportBlock {\n  display: flex;\n  gap: 14px;\n}\n\n.manage-property-definitions-widget--ThingTypeImportBlockCustomSelect {\n  flex: 1 0 auto;\n}\n\n.manage-property-definitions-widget--TemplateBlock {\n  display: flex;\n  width: 100%;\n  align-items: center;\n}\n\n.manage-property-definitions-widget--TemplateBlock:not(:last-child) {\n  margin-bottom: 20px;\n}\n\n.manage-property-definitions-widget--WarningIcon {\n  display: flex;\n  align-items: end;\n  gap: 10px;\n\n  margin-bottom: 20px;\n\n  color: var(--record-it-color-orange);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
