// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-property-widget {
  display: block;
}

.gallery-thing-property-widget--Label {
  font-size: 13px;
  color: var(--record-it-color-gray-600);
  margin-bottom: 6px;
}

.gallery-thing-property-widget--SelectionSwitchWrapper {
  height: 26px;
}

.gallery-thing-property-widget--IntervalInputFieldWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-property-widget/gallery-thing-property-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":["gallery-thing-property-widget {\n  display: block;\n}\n\n.gallery-thing-property-widget--Label {\n  font-size: 13px;\n  color: var(--record-it-color-gray-600);\n  margin-bottom: 6px;\n}\n\n.gallery-thing-property-widget--SelectionSwitchWrapper {\n  height: 26px;\n}\n\n.gallery-thing-property-widget--IntervalInputFieldWrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n  max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
