// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-related-appointments-list {
  display: block;
}

.process-task-appointment-related-appointments-list--Label {
  color: var(--record-it-color-gray-600);
  font-size: 13px;
  margin-bottom: 11px;
  text-transform: uppercase;
}

.process-task-appointment-related-appointments-list--Appointment
  + .process-task-appointment-related-appointments-list--Appointment {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-related-appointments-list/process-task-appointment-related-appointments-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,gBAAgB;AAClB","sourcesContent":["process-task-appointment-related-appointments-list {\n  display: block;\n}\n\n.process-task-appointment-related-appointments-list--Label {\n  color: var(--record-it-color-gray-600);\n  font-size: 13px;\n  margin-bottom: 11px;\n  text-transform: uppercase;\n}\n\n.process-task-appointment-related-appointments-list--Appointment\n  + .process-task-appointment-related-appointments-list--Appointment {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
