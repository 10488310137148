// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-assignee-select {
  display: block;
  container-type: inline-size;
}

.defect-assignee-select--Container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 2rem;
  row-gap: 0.2rem;
}

.defect-assignee-select--UserSelect {
  flex: 1 1 auto;
}

.defect-assignee-select--InviteButton {
  flex: 0 0 auto;
}

@container (max-width: 700px) {
  .defect-assignee-select--Container {
    align-items: stretch;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/defect-assignee-select/defect-assignee-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,oBAAoB;IACpB,sBAAsB;EACxB;AACF","sourcesContent":["defect-assignee-select {\n  display: block;\n  container-type: inline-size;\n}\n\n.defect-assignee-select--Container {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n  column-gap: 2rem;\n  row-gap: 0.2rem;\n}\n\n.defect-assignee-select--UserSelect {\n  flex: 1 1 auto;\n}\n\n.defect-assignee-select--InviteButton {\n  flex: 0 0 auto;\n}\n\n@container (max-width: 700px) {\n  .defect-assignee-select--Container {\n    align-items: stretch;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
