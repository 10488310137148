// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-task-device-dialog--DeviceIndex {
  color: var(--record-it-color-gray-700);
  margin-top: -25px;
  margin-bottom: 25px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-task-device-dialog/edit-process-task-device-dialog.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".edit-process-task-device-dialog--DeviceIndex {\n  color: var(--record-it-color-gray-700);\n  margin-top: -25px;\n  margin-bottom: 25px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
