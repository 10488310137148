// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-img {
  display: block;
}

.custom-img--Image {
  top: 0;
  left: 0;

  width: inherit;
  height: inherit;

  background: white;

  object-fit: cover;
}

.custom-img--Image:first-child {
  position: relative;
}

.custom-img--Image:not(:first-child) {
  position: absolute;
}

.custom-img--NoDragImage {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/picture/custom-img/custom-img.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,MAAM;EACN,OAAO;;EAEP,cAAc;EACd,eAAe;;EAEf,iBAAiB;;EAEjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":["custom-img {\n  display: block;\n}\n\n.custom-img--Image {\n  top: 0;\n  left: 0;\n\n  width: inherit;\n  height: inherit;\n\n  background: white;\n\n  object-fit: cover;\n}\n\n.custom-img--Image:first-child {\n  position: relative;\n}\n\n.custom-img--Image:not(:first-child) {\n  position: absolute;\n}\n\n.custom-img--NoDragImage {\n  -ms-user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
