// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `global-filter {
  display: flex;
  font-size: 1.5em;
  align-items: center;
}

global-filter > * {
  flex: 0 0 auto;
}

.global-filter--ClearIcon {
  cursor: pointer;
  margin-right: 1em;
}

.global-filter--InputField {
  flex: 1 1 auto;
  padding: 0.5em 0;
  margin: 0 1em;
  border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/global-filter/global-filter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,SAAS;AACX","sourcesContent":["global-filter {\n  display: flex;\n  font-size: 1.5em;\n  align-items: center;\n}\n\nglobal-filter > * {\n  flex: 0 0 auto;\n}\n\n.global-filter--ClearIcon {\n  cursor: pointer;\n  margin-right: 1em;\n}\n\n.global-filter--InputField {\n  flex: 1 1 auto;\n  padding: 0.5em 0;\n  margin: 0 1em;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
