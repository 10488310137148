// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `user-group-user-widget {
  display: block;
}

.user-group-user-widget--MainRow {
  display: flex;
  font-size: 17px;
}

.user-group-user-widget--Email {
  /* TODO: use a recordit grey */
  color: #878787;
  font-size: 13px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.user-group-user-widget--RolesWidget {
  margin-top: 17px;
}
`, "",{"version":3,"sources":["webpack://./src/userGroupComponents/user-group-user-widget/user-group-user-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,eAAe;;EAEf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["user-group-user-widget {\n  display: block;\n}\n\n.user-group-user-widget--MainRow {\n  display: flex;\n  font-size: 17px;\n}\n\n.user-group-user-widget--Email {\n  /* TODO: use a recordit grey */\n  color: #878787;\n  font-size: 13px;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.user-group-user-widget--RolesWidget {\n  margin-top: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
