// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-from-csv-file-dialog--RadioButtonContainer {
  flex-wrap: wrap;
  width: 100%;
}

.import-from-csv-file-dialog--RadioButtonContainer > * {
  flex: 1 0 100px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".import-from-csv-file-dialog--RadioButtonContainer {\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n.import-from-csv-file-dialog--RadioButtonContainer > * {\n  flex: 1 0 100px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
