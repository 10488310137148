// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `date-time-picker {
  display: block;
}

.date-time-picker--Label {
  font-size: 0.7em;
  margin-bottom: 1px;
  margin-top: 4px;
  opacity: 0.6;
}

.date-time-picker--InputsWrapper {
  margin-left: -5px;
}

.date-time-picker--InputsWrapper > * {
  margin-left: 5px;
}

.date-time-picker--PickerIcon {
  cursor: pointer;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  width: 32px;
}

.date-time-picker--PickerIconDisabled {
  color: var(--record-it-color-disabled);
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/date-time-picker/date-time-picker.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":["date-time-picker {\n  display: block;\n}\n\n.date-time-picker--Label {\n  font-size: 0.7em;\n  margin-bottom: 1px;\n  margin-top: 4px;\n  opacity: 0.6;\n}\n\n.date-time-picker--InputsWrapper {\n  margin-left: -5px;\n}\n\n.date-time-picker--InputsWrapper > * {\n  margin-left: 5px;\n}\n\n.date-time-picker--PickerIcon {\n  cursor: pointer;\n  font-size: 20px;\n  height: 32px;\n  line-height: 32px;\n  vertical-align: middle;\n  width: 32px;\n}\n\n.date-time-picker--PickerIconDisabled {\n  color: var(--record-it-color-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
