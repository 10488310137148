// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-status-icons.edit-gallery-thing--StatusIcons {
  flex: 1 1 auto;
}

.edit-gallery-thing--ThingPicture {
  margin-top: 15px;
  margin-bottom: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/edit_gallery_thing/edit_gallery_thing.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["gallery-thing-status-icons.edit-gallery-thing--StatusIcons {\n  flex: 1 1 auto;\n}\n\n.edit-gallery-thing--ThingPicture {\n  margin-top: 15px;\n  margin-bottom: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
