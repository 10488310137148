// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-structure-template-dialog {
  display: block;
}

.edit-structure-template-dialog--FlawNumberCustomSelect {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-structure-template-dialog/edit-structure-template-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":["edit-structure-template-dialog {\n  display: block;\n}\n\n.edit-structure-template-dialog--FlawNumberCustomSelect {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
