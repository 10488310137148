// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `global-site-state-info {
  display: block;
  min-height: 44px; /* 24px icon size + padding of .global-site-info--WarningIconContainer */
}

.global-site-state-info--HasWarning {
  box-shadow: 0 1px 3px -2px #949494;
  margin-bottom: 5px;
}

.global-site-info--WarningIconContainer {
  text-align: right;
  color: var(--record-it-color-primary);
  padding: 10px 16px 10px 5px;
}

.global-site-info--WarningTextContainer {
  padding: 10px 5px 5px 5px;
}

.global-site-info--WarningTextList {
}

.global-site-info--CloseTextContainer {
  text-align: right;
}

.global-site-info--CloseText {
  text-transform: uppercase;
  color: #a5a5a5;
  cursor: pointer;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/global-site-state-info/global-site-state-info.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB,EAAE,wEAAwE;AAC5F;;AAEA;EACE,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,qCAAqC;EACrC,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;AACA;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,eAAe;AACjB","sourcesContent":["global-site-state-info {\n  display: block;\n  min-height: 44px; /* 24px icon size + padding of .global-site-info--WarningIconContainer */\n}\n\n.global-site-state-info--HasWarning {\n  box-shadow: 0 1px 3px -2px #949494;\n  margin-bottom: 5px;\n}\n\n.global-site-info--WarningIconContainer {\n  text-align: right;\n  color: var(--record-it-color-primary);\n  padding: 10px 16px 10px 5px;\n}\n\n.global-site-info--WarningTextContainer {\n  padding: 10px 5px 5px 5px;\n}\n\n.global-site-info--WarningTextList {\n}\n\n.global-site-info--CloseTextContainer {\n  text-align: right;\n}\n\n.global-site-info--CloseText {\n  text-transform: uppercase;\n  color: #a5a5a5;\n  cursor: pointer;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
