// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-configuration-step-positions--PositionGroup
  + .edit-process-configuration-step-positions--PositionGroup {
  margin-top: 47px;
}

.edit-process-configuration-step-positions--PositionGroupHeader {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.edit-process-configuration-step-positions--PositionGroupHeaderPart {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/edit_process_configuration_step_positions/edit_process_configuration_step_positions.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".edit-process-configuration-step-positions--PositionGroup\n  + .edit-process-configuration-step-positions--PositionGroup {\n  margin-top: 47px;\n}\n\n.edit-process-configuration-step-positions--PositionGroupHeader {\n  font-size: 20px;\n  font-weight: 500;\n  margin-bottom: 15px;\n}\n\n.edit-process-configuration-step-positions--PositionGroupHeaderPart {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
