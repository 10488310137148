// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.structure-template-exclude-from-flaw-numbers-widget--Title {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.structure-template-exclude-from-flaw-numbers-widget--ParameterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.structure-template-exclude-from-flaw-numbers-widget--ParameterContainerElement {
  width: 50%;
}

.structure-template-exclude-from-flaw-numbers-widget--ButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-end;
}

.structure-template-exclude-from-flaw-numbers-widget--ButtonContainerSpacer {
  flex-grow: 1;
}

.structure-template-exclude-from-flaw-numbers-widget-AddButtonRow {
  margin-top: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/structureTemplateComponents/structure-template-exclude-from-flaw-numbers-widget/structure-template-exclude-from-flaw-numbers-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".structure-template-exclude-from-flaw-numbers-widget--Title {\n  display: block;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.structure-template-exclude-from-flaw-numbers-widget--ParameterContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.structure-template-exclude-from-flaw-numbers-widget--ParameterContainerElement {\n  width: 50%;\n}\n\n.structure-template-exclude-from-flaw-numbers-widget--ButtonContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  height: 100%;\n  justify-content: flex-end;\n}\n\n.structure-template-exclude-from-flaw-numbers-widget--ButtonContainerSpacer {\n  flex-grow: 1;\n}\n\n.structure-template-exclude-from-flaw-numbers-widget-AddButtonRow {\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
