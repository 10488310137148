// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-positions-and-devices-todo-list {
  display: block;
}

.process-task-positions-and-devices-todo-list--GroupName {
  color: var(--record-it-color-gray-700);
  font-size: 12px;
}

.process-task-positions-and-devices-todo-list--TodoList {
  margin-bottom: 4px;
  padding-left: 25px;
}

.process-task-positions-and-devices-todo-list--TodoListItem
  + .process-task-positions-and-devices-todo-list--TodoListItem {
  margin-top: 10px;
}

.process-task-positions-and-devices-todo-list--TodoListItemName {
  font-weight: 500;
}

.process-task-positions-and-devices-todo-list--PositionInfo,
.process-task-positions-and-devices-todo-list--Note {
  margin-left: 6px;
  margin-top: 3px;
}

.process-task-positions-and-devices-todo-list--Note {
  white-space: pre-line;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-positions-and-devices-todo-list/process-task-positions-and-devices-todo-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["process-task-positions-and-devices-todo-list {\n  display: block;\n}\n\n.process-task-positions-and-devices-todo-list--GroupName {\n  color: var(--record-it-color-gray-700);\n  font-size: 12px;\n}\n\n.process-task-positions-and-devices-todo-list--TodoList {\n  margin-bottom: 4px;\n  padding-left: 25px;\n}\n\n.process-task-positions-and-devices-todo-list--TodoListItem\n  + .process-task-positions-and-devices-todo-list--TodoListItem {\n  margin-top: 10px;\n}\n\n.process-task-positions-and-devices-todo-list--TodoListItemName {\n  font-weight: 500;\n}\n\n.process-task-positions-and-devices-todo-list--PositionInfo,\n.process-task-positions-and-devices-todo-list--Note {\n  margin-left: 6px;\n  margin-top: 3px;\n}\n\n.process-task-positions-and-devices-todo-list--Note {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
