// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-protocol {
  display: block;
}

.defect-protocol--PanelHead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-protocol/defect-protocol.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":["defect-protocol {\n  display: block;\n}\n\n.defect-protocol--PanelHead {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
