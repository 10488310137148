// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-measure-point-markings-overlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.process-task-measure-point-markings-overlay--MarkingWrapper {
  position: absolute;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.process-task-measure-point-markings-overlay--Marking {
  position: absolute;
}

.process-task-measure-point-markings-overlay--MarkingDescription {
  position: absolute;
  transform: translate(-50%, 0);
  font-weight: bold;
  color: orange;
  font-size: 18px;
  margin-top: 8px;
  margin-left: 8px;
  top: 50%;
  left: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-measure-point-markings-overlay/process-task-measure-point-markings-overlay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,QAAQ;EACR,SAAS;AACX","sourcesContent":["process-task-measure-point-markings-overlay {\n  display: block;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  user-select: none;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n}\n\n.process-task-measure-point-markings-overlay--MarkingWrapper {\n  position: absolute;\n  width: 25px;\n  height: 25px;\n  transform: translate(-50%, -50%);\n  cursor: pointer;\n}\n\n.process-task-measure-point-markings-overlay--Marking {\n  position: absolute;\n}\n\n.process-task-measure-point-markings-overlay--MarkingDescription {\n  position: absolute;\n  transform: translate(-50%, 0);\n  font-weight: bold;\n  color: orange;\n  font-size: 18px;\n  margin-top: 8px;\n  margin-left: 8px;\n  top: 50%;\n  left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
