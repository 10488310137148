// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-picture-overview-and-export {
  display: block;
}

.gallery-thing-picture-overview-and-export--ViewSelectionSwitch {
  display: block;
  position: relative;
  margin-bottom: 35px;
}

.gallery-thing-picture-overview-and-export--BottomSiteContainer {
  background: white;
  padding-top: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-picture-overview-and-export/gallery-thing-picture-overview-and-export.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":["gallery-thing-picture-overview-and-export {\n  display: block;\n}\n\n.gallery-thing-picture-overview-and-export--ViewSelectionSwitch {\n  display: block;\n  position: relative;\n  margin-bottom: 35px;\n}\n\n.gallery-thing-picture-overview-and-export--BottomSiteContainer {\n  background: white;\n  padding-top: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
