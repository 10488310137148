// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-position-list {
  display: block;
}

.process-task-position-list--Group + .process-task-position-list--Group {
  margin-top: 22px;
}

.process-task-position-list--GroupName {
  color: var(--record-it-color-gray-700);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 7px;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-position-list/process-task-position-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":["process-task-position-list {\n  display: block;\n}\n\n.process-task-position-list--Group + .process-task-position-list--Group {\n  margin-top: 22px;\n}\n\n.process-task-position-list--GroupName {\n  color: var(--record-it-color-gray-700);\n  font-size: 13px;\n  font-weight: 500;\n  margin-bottom: 7px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
