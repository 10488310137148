// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `urfw-picture-preview {
  display: block;
}

.urfw-picture-preview--PreviewPicture {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 100px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.urfw-picture-preview--PreviewPictureAnimation {
  cursor: pointer;
  animation: picture-slide-in 800ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

@keyframes picture-slide-in {
  0% {
    opacity: 0%;
    transform: translate(-25vw, -25vh) scale(4);
  }
  100% {
    opacity: 100%;
    transform: translate(0px, 0px) scale(1);
  }
}
@media (orientation: portrait) {
  .urfw-picture-preview--PreviewPicture {
    right: 20px;
    bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/urfw-picture-preview/urfw-picture-preview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,wCAAwC;EACxC,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,wEAAwE;AAC1E;;AAEA;EACE;IACE,WAAW;IACX,2CAA2C;EAC7C;EACA;IACE,aAAa;IACb,uCAAuC;EACzC;AACF;AACA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["urfw-picture-preview {\n  display: block;\n}\n\n.urfw-picture-preview--PreviewPicture {\n  position: absolute;\n  right: 30px;\n  bottom: 30px;\n  width: 100px;\n  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);\n  cursor: pointer;\n}\n\n.urfw-picture-preview--PreviewPictureAnimation {\n  cursor: pointer;\n  animation: picture-slide-in 800ms cubic-bezier(0.55, 0.055, 0.675, 0.19);\n}\n\n@keyframes picture-slide-in {\n  0% {\n    opacity: 0%;\n    transform: translate(-25vw, -25vh) scale(4);\n  }\n  100% {\n    opacity: 100%;\n    transform: translate(0px, 0px) scale(1);\n  }\n}\n@media (orientation: portrait) {\n  .urfw-picture-preview--PreviewPicture {\n    right: 20px;\n    bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
