// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `rapid-fire-image-preview {
  display: flex;
  flex-flow: row nowrap;
}

.rapid-fire-image-preview--LeftSpacer {
  flex: 1 1 0;
  margin-right: 7px;
}

.rapid-fire-image-preview--ImageContainer {
  flex: 0 1 auto;
  min-width: 0;
}

.rapid-fire-image-preview--ButtonsContainer {
  flex: 1 1 0;
  margin-left: 7px;
  align-self: flex-end;
}

.rapid-fire-image-preview--PreviewImage {
  display: inline-block;

  max-width: 500px;
  max-height: 20vh;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/rapid-fire-image-preview/rapid-fire-image-preview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;;EAErB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["rapid-fire-image-preview {\n  display: flex;\n  flex-flow: row nowrap;\n}\n\n.rapid-fire-image-preview--LeftSpacer {\n  flex: 1 1 0;\n  margin-right: 7px;\n}\n\n.rapid-fire-image-preview--ImageContainer {\n  flex: 0 1 auto;\n  min-width: 0;\n}\n\n.rapid-fire-image-preview--ButtonsContainer {\n  flex: 1 1 0;\n  margin-left: 7px;\n  align-self: flex-end;\n}\n\n.rapid-fire-image-preview--PreviewImage {\n  display: inline-block;\n\n  max-width: 500px;\n  max-height: 20vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
