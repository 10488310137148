// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-confirm-select {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-top: -10px;
}

person-confirm-select > * {
  margin-left: 15px;
  margin-top: 10px;
}

.person-confirm-select--PersonSelect {
  flex: 1 1 auto;
}

.person-confirm-select--ButtonWrapper {
  flex: 0 0 auto;
  align-self: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-confirm-select/person-confirm-select.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB","sourcesContent":["person-confirm-select {\n  display: flex;\n  flex-flow: row wrap;\n  margin-left: -15px;\n  margin-top: -10px;\n}\n\nperson-confirm-select > * {\n  margin-left: 15px;\n  margin-top: 10px;\n}\n\n.person-confirm-select--PersonSelect {\n  flex: 1 1 auto;\n}\n\n.person-confirm-select--ButtonWrapper {\n  flex: 0 0 auto;\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
