// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-process-task-group--SiteContainer {
  max-width: 1400px;
}

.create-process-task-group--PageHeader {
  margin-bottom: 0;
}

.create-process-task-group--ColumnContainer {
  display: flex;
  margin-left: -20px;
}

.create-process-task-group--Column {
  flex: 1 1 33.33%;
  min-width: 0;
  margin-left: 20px;
}

.create-process-task-group--Row {
  opacity: 1;
  transition: opacity 0.3s ease;
  position: relative;
  padding: 0 0 25px 0;
}

.create-process-task-group--ButtonWrapper {
  text-align: right;
  margin-bottom: 50px;
}

.create-process-task-group--CreateButton {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.create-process-task-group--CreateButton:disabled {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/create_process_task_group/create_process_task_group.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".create-process-task-group--SiteContainer {\n  max-width: 1400px;\n}\n\n.create-process-task-group--PageHeader {\n  margin-bottom: 0;\n}\n\n.create-process-task-group--ColumnContainer {\n  display: flex;\n  margin-left: -20px;\n}\n\n.create-process-task-group--Column {\n  flex: 1 1 33.33%;\n  min-width: 0;\n  margin-left: 20px;\n}\n\n.create-process-task-group--Row {\n  opacity: 1;\n  transition: opacity 0.3s ease;\n  position: relative;\n  padding: 0 0 25px 0;\n}\n\n.create-process-task-group--ButtonWrapper {\n  text-align: right;\n  margin-bottom: 50px;\n}\n\n.create-process-task-group--CreateButton {\n  opacity: 1;\n  transition: opacity 0.3s ease;\n}\n\n.create-process-task-group--CreateButton:disabled {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
