// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `calendar-day-select {
  display: block;
}

.calendar-day-select--Bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
}

.calendar-day-select--WeekChangeButton {
  border: 1px solid var(--record-it-color-gray-500);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
}

.calendar-day-select--Day {
  padding: 0.5em 1em;
  cursor: pointer;
}

.calendar-day-select--DayWeekdayLabel {
  font-size: 12px;
  text-align: center;
  color: var(--record-it-color-gray-700);
}

.calendar-day-select--DayLabel {
  padding: 0.5em;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-day-select--SelectedDayLabel {
  background-color: var(--record-it-color-primary);
  color: var(--record-it-color-white);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/calendar-day-select/calendar-day-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iDAAiD;EACjD,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gDAAgD;EAChD,mCAAmC;AACrC","sourcesContent":["calendar-day-select {\n  display: block;\n}\n\n.calendar-day-select--Bar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 65px;\n}\n\n.calendar-day-select--WeekChangeButton {\n  border: 1px solid var(--record-it-color-gray-500);\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 1em;\n}\n\n.calendar-day-select--Day {\n  padding: 0.5em 1em;\n  cursor: pointer;\n}\n\n.calendar-day-select--DayWeekdayLabel {\n  font-size: 12px;\n  text-align: center;\n  color: var(--record-it-color-gray-700);\n}\n\n.calendar-day-select--DayLabel {\n  padding: 0.5em;\n  width: 28px;\n  height: 28px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.calendar-day-select--SelectedDayLabel {\n  background-color: var(--record-it-color-primary);\n  color: var(--record-it-color-white);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
