// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `image-full-screen-overlay {
  display: block;
}

.image-full-screen-overlay--ZoomBox {
  width: 100%;
}

.image-full-screen-overlay--Image {
  display: block;
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/image-full-screen-overlay/image-full-screen-overlay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["image-full-screen-overlay {\n  display: block;\n}\n\n.image-full-screen-overlay--ZoomBox {\n  width: 100%;\n}\n\n.image-full-screen-overlay--Image {\n  display: block;\n  width: 100%;\n  max-height: 80vh;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
