// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `no-picture-upload-placeholder {
  display: block;
  position: relative;
  background: var(--record-it-color-gray-100);
  border: dashed 1px rgba(74, 74, 74, 0.17);
}

.no-picture-upload-placeholder--ContentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--record-it-color-secondary);
  text-align: center;
}

.no-picture-upload-placeholder--Description {
  opacity: 0.54;
}

.no-picture-upload-placeholder--NoPictureIcon {
  display: inline-block;
  opacity: 0.3;
}

no-picture-upload-placeholder[data-fill-absolute] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/picture/no-picture-upload-placeholder/no-picture-upload-placeholder.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,2CAA2C;EAC3C,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV","sourcesContent":["no-picture-upload-placeholder {\n  display: block;\n  position: relative;\n  background: var(--record-it-color-gray-100);\n  border: dashed 1px rgba(74, 74, 74, 0.17);\n}\n\n.no-picture-upload-placeholder--ContentWrapper {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: var(--record-it-color-secondary);\n  text-align: center;\n}\n\n.no-picture-upload-placeholder--Description {\n  opacity: 0.54;\n}\n\n.no-picture-upload-placeholder--NoPictureIcon {\n  display: inline-block;\n  opacity: 0.3;\n}\n\nno-picture-upload-placeholder[data-fill-absolute] {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
