// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.module-tile-home-page--TilesContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
}

.module-tile-home-page--Tile {
  position: relative;

  flex: 0 0 40%;
  min-width: 100px;
  max-width: 290px;

  margin-bottom: 30px;
}

.module-tile-home-page--TileIcon {
  width: 100%;
  border-radius: 8.33%;
}

.module-tile-home-page--TileDescription {
  font-size: 30px;
  margin-bottom: 7px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.module-tile-home-page--TileLink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

@media all and (max-width: 767px) {
  .module-tile-home-page--TilesContainer {
    margin-top: 40px;
  }

  .module-tile-home-page--TileDescription {
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home_page/module-tile-homepage/module-tile-homepage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;;EAElB,aAAa;EACb,gBAAgB;EAChB,gBAAgB;;EAEhB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,cAAc;AAChB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".module-tile-home-page--TilesContainer {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-around;\n  align-items: center;\n  min-height: 100%;\n}\n\n.module-tile-home-page--Tile {\n  position: relative;\n\n  flex: 0 0 40%;\n  min-width: 100px;\n  max-width: 290px;\n\n  margin-bottom: 30px;\n}\n\n.module-tile-home-page--TileIcon {\n  width: 100%;\n  border-radius: 8.33%;\n}\n\n.module-tile-home-page--TileDescription {\n  font-size: 30px;\n  margin-bottom: 7px;\n  font-weight: 400;\n  text-align: center;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.module-tile-home-page--TileLink {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n}\n\n@media all and (max-width: 767px) {\n  .module-tile-home-page--TilesContainer {\n    margin-top: 40px;\n  }\n\n  .module-tile-home-page--TileDescription {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
