// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `drawing-settings-bar {
  display: inline-block;
}

drawing-settings-bar > * {
  display: inline-block;
}

.drawing-settings-bar--Dropdown {
  display: inline-block;
}

.drawing-settings-bar--DropdownButton {
  background: var(--record-it-color-gray-100);
  border: none;
  margin: 0;
  padding: 2px 5px;
  text-align: center;
}

.drawing-settings-bar--DropdownButtonText {
  vertical-align: middle;
}

.drawing-settings-bar--DropdownButtonIcon {
  vertical-align: middle;
}

.drawing-settings-bar--DropdownMenuItem {
  cursor: pointer;
}

.drawing-settings-bar--ColorSquare {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

.drawing-settings-bar--ColorLine {
  display: block;
  width: auto;
  height: 1.2em;
}

.drawing-settings-bar--ColorSquare,
.drawing-settings-bar--ColorLine {
  border: 1px solid #ececec;
}

.drawing-settings-bar--StrokePreview {
  width: 100px;
}

/************ flex-layout **************/

drawing-settings-bar[flex-layout] {
  display: flex;
  flex-flow: row wrap;
}

drawing-settings-bar[flex-layout] > * {
  flex: 1 1 auto;
}

drawing-settings-bar[flex-layout] .drawing-settings-bar--DropdownButton {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/drawingComponents/drawing-settings-bar/drawing-settings-bar.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,2CAA2C;EAC3C,YAAY;EACZ,SAAS;EACT,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;AACf;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA,wCAAwC;;AAExC;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["drawing-settings-bar {\n  display: inline-block;\n}\n\ndrawing-settings-bar > * {\n  display: inline-block;\n}\n\n.drawing-settings-bar--Dropdown {\n  display: inline-block;\n}\n\n.drawing-settings-bar--DropdownButton {\n  background: var(--record-it-color-gray-100);\n  border: none;\n  margin: 0;\n  padding: 2px 5px;\n  text-align: center;\n}\n\n.drawing-settings-bar--DropdownButtonText {\n  vertical-align: middle;\n}\n\n.drawing-settings-bar--DropdownButtonIcon {\n  vertical-align: middle;\n}\n\n.drawing-settings-bar--DropdownMenuItem {\n  cursor: pointer;\n}\n\n.drawing-settings-bar--ColorSquare {\n  display: inline-block;\n  width: 1em;\n  height: 1em;\n  vertical-align: middle;\n}\n\n.drawing-settings-bar--ColorLine {\n  display: block;\n  width: auto;\n  height: 1.2em;\n}\n\n.drawing-settings-bar--ColorSquare,\n.drawing-settings-bar--ColorLine {\n  border: 1px solid #ececec;\n}\n\n.drawing-settings-bar--StrokePreview {\n  width: 100px;\n}\n\n/************ flex-layout **************/\n\ndrawing-settings-bar[flex-layout] {\n  display: flex;\n  flex-flow: row wrap;\n}\n\ndrawing-settings-bar[flex-layout] > * {\n  flex: 1 1 auto;\n}\n\ndrawing-settings-bar[flex-layout] .drawing-settings-bar--DropdownButton {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
