// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `person-select-and-edit-widget-phone-contact {
  display: block;
}

.person-select-and-edit-widget-phone-contact--ContactRow {
  display: flex;
  align-items: flex-end;
}

.person-select-and-edit-widget-phone-contact--ContactRow_highlighted {
  background-color: var(
    --record-it-color-highlighted-person-contact-background
  );
}

.person-select-and-edit-widget-phone-contact--ContactRowIcon {
  flex: 0 0 auto;
  margin-left: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.person-select-and-edit-widget-phone-contact--ContactRowInput {
  flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/personComponents/person-select-and-edit-widget/person-select-and-edit-widget-phone-contact.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE;;GAEC;AACH;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["person-select-and-edit-widget-phone-contact {\n  display: block;\n}\n\n.person-select-and-edit-widget-phone-contact--ContactRow {\n  display: flex;\n  align-items: flex-end;\n}\n\n.person-select-and-edit-widget-phone-contact--ContactRow_highlighted {\n  background-color: var(\n    --record-it-color-highlighted-person-contact-background\n  );\n}\n\n.person-select-and-edit-widget-phone-contact--ContactRowIcon {\n  flex: 0 0 auto;\n  margin-left: 7px;\n  margin-bottom: 7px;\n  cursor: pointer;\n}\n\n.person-select-and-edit-widget-phone-contact--ContactRowInput {\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
