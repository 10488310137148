// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `base-property-signature-widget {
  display: block;
}

.base-property-signature-widget--Label {
  align-self: flex-start;
}

.base-property-signature-widget--FillHeight {
  height: 100%;
}

.base-property-signature-widget--SignatureInTable {
  display: inline-flex;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.base-property-signature-widget--SignatureArea {
  max-width: 300px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/propertyComponents/base-property-signature-widget/base-property-signature-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;EAC7B,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":["base-property-signature-widget {\n  display: block;\n}\n\n.base-property-signature-widget--Label {\n  align-self: flex-start;\n}\n\n.base-property-signature-widget--FillHeight {\n  height: 100%;\n}\n\n.base-property-signature-widget--SignatureInTable {\n  display: inline-flex;\n  justify-content: space-around;\n  flex-direction: row-reverse;\n}\n\n.base-property-signature-widget--SignatureArea {\n  max-width: 300px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
