// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-measure-point-reading-item {
  display: block;
  flex-grow: 1;
}

process-task-measure-point-reading-item
  + process-task-measure-point-reading-item {
  margin-top: 10px;
}

.process-task-measure-point-reading-item--PropertyContainer {
  display: flex;
  align-items: flex-end;
}

.process-task-measure-point-reading-item--Date {
  flex: 0 0 90px;
  margin: 0;
  margin-top: 10px;
  padding-right: 15px;
  align-self: flex-end;
  margin-bottom: 6px;
  color: var(--record-it-color-gray-600);
}

.process-task-measure-point-reading-item--Property {
  flex: 1 0 0;
  margin: 0;
  margin-top: 10px;
  padding-right: 24px;
}

@media (max-width: 767px) {
  .process-task-measure-point-reading-item--PropertyContainer {
    flex-wrap: wrap;
    flex-flow: column;
    align-items: stretch;
  }
  .process-task-measure-point-reading-item--Date {
    flex: 0 0 0;
    align-self: flex-start;
  }
  .process-task-measure-point-reading-item--Property {
    padding-left: 5px;
  }
  .process-task-measure-point-reading-item--PropertyContainer
    .record-it-input-and-label-container-label {
    width: 160px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-measure-point-reading-item/process-task-measure-point-reading-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,SAAS;EACT,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,oBAAoB;EACtB;EACA;IACE,WAAW;IACX,sBAAsB;EACxB;EACA;IACE,iBAAiB;EACnB;EACA;;IAEE,YAAY;EACd;AACF","sourcesContent":["process-task-measure-point-reading-item {\n  display: block;\n  flex-grow: 1;\n}\n\nprocess-task-measure-point-reading-item\n  + process-task-measure-point-reading-item {\n  margin-top: 10px;\n}\n\n.process-task-measure-point-reading-item--PropertyContainer {\n  display: flex;\n  align-items: flex-end;\n}\n\n.process-task-measure-point-reading-item--Date {\n  flex: 0 0 90px;\n  margin: 0;\n  margin-top: 10px;\n  padding-right: 15px;\n  align-self: flex-end;\n  margin-bottom: 6px;\n  color: var(--record-it-color-gray-600);\n}\n\n.process-task-measure-point-reading-item--Property {\n  flex: 1 0 0;\n  margin: 0;\n  margin-top: 10px;\n  padding-right: 24px;\n}\n\n@media (max-width: 767px) {\n  .process-task-measure-point-reading-item--PropertyContainer {\n    flex-wrap: wrap;\n    flex-flow: column;\n    align-items: stretch;\n  }\n  .process-task-measure-point-reading-item--Date {\n    flex: 0 0 0;\n    align-self: flex-start;\n  }\n  .process-task-measure-point-reading-item--Property {\n    padding-left: 5px;\n  }\n  .process-task-measure-point-reading-item--PropertyContainer\n    .record-it-input-and-label-container-label {\n    width: 160px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
