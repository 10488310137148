// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-configuration-configurable-email-text-widget {
  display: block;
}

.process-configuration-configurable-email-text-widget--SectionTitle {
  display: inline-block;
  font-weight: bolder;
  font-size: 15px;
  margin: 15px 0;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-configuration-configurable-email-text-widget/process-configuration-configurable-email-text-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB","sourcesContent":["process-configuration-configurable-email-text-widget {\n  display: block;\n}\n\n.process-configuration-configurable-email-text-widget--SectionTitle {\n  display: inline-block;\n  font-weight: bolder;\n  font-size: 15px;\n  margin: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
