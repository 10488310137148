// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-process-configuration-step-dialog--IconWrapper {
  padding: 12px;
  line-height: 1em;
}

.edit-process-configuration-step-dialog--IconWrapper
  + .edit-process-configuration-step-dialog--IconWrapper {
  margin-left: 10px;
}

.edit-process-configuration-step-dialog--CheckboxTable {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  gap: 8px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-configuration-step-dialog/edit-process-configuration-step-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,6DAA6D;EAC7D,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".edit-process-configuration-step-dialog--IconWrapper {\n  padding: 12px;\n  line-height: 1em;\n}\n\n.edit-process-configuration-step-dialog--IconWrapper\n  + .edit-process-configuration-step-dialog--IconWrapper {\n  margin-left: 10px;\n}\n\n.edit-process-configuration-step-dialog--CheckboxTable {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
