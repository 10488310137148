// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `progress-bar {
  display: block;
  --color: var(--progress-bar-color, var(--record-it-color-primary));
  --background: var(--progress-bar-bg, var(--record-it-color-gray-400));
  --border-radius: 3px;
}

progress-bar progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: var(--border-radius);
  background: var(--background);
  width: 100%;
  height: 10px;
}
progress-bar progress[value]::-webkit-progress-bar {
  border-radius: var(--border-radius);
  background: var(--background);
  width: 100%;
}
progress-bar progress[value]::-webkit-progress-value {
  border-radius: var(--border-radius);
  background: var(--color);
}
progress-bar progress[value]::-moz-progress-bar {
  border-radius: var(--border-radius);
  background: var(--color);
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/progress-bar/progress-bar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kEAAkE;EAClE,qEAAqE;EACrE,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,mCAAmC;EACnC,6BAA6B;EAC7B,WAAW;EACX,YAAY;AACd;AACA;EACE,mCAAmC;EACnC,6BAA6B;EAC7B,WAAW;AACb;AACA;EACE,mCAAmC;EACnC,wBAAwB;AAC1B;AACA;EACE,mCAAmC;EACnC,wBAAwB;EACxB,WAAW;AACb","sourcesContent":["progress-bar {\n  display: block;\n  --color: var(--progress-bar-color, var(--record-it-color-primary));\n  --background: var(--progress-bar-bg, var(--record-it-color-gray-400));\n  --border-radius: 3px;\n}\n\nprogress-bar progress[value] {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  border: none;\n  border-radius: var(--border-radius);\n  background: var(--background);\n  width: 100%;\n  height: 10px;\n}\nprogress-bar progress[value]::-webkit-progress-bar {\n  border-radius: var(--border-radius);\n  background: var(--background);\n  width: 100%;\n}\nprogress-bar progress[value]::-webkit-progress-value {\n  border-radius: var(--border-radius);\n  background: var(--color);\n}\nprogress-bar progress[value]::-moz-progress-bar {\n  border-radius: var(--border-radius);\n  background: var(--color);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
