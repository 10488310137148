// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-process-task-recurring-appointment-widget {
  display: block;
}

.edit-process-task-recurring-appointment-widget--TimeWrapper {
  align-items: center;
}

.edit-process-task-recurring-appointment-widget--TimePicker {
  flex: 0 0 auto;
}

.edit-process-task-recurring-appointment-widget--TimePickerDivider {
  margin: 0 16px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-task-recurring-appointment-widget/edit-process-task-recurring-appointment-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["edit-process-task-recurring-appointment-widget {\n  display: block;\n}\n\n.edit-process-task-recurring-appointment-widget--TimeWrapper {\n  align-items: center;\n}\n\n.edit-process-task-recurring-appointment-widget--TimePicker {\n  flex: 0 0 auto;\n}\n\n.edit-process-task-recurring-appointment-widget--TimePickerDivider {\n  margin: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
