// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-configuration-form-send-targets-widget {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-configuration-form-send-targets-widget/process-configuration-form-send-targets-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":["process-configuration-form-send-targets-widget {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
