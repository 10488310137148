// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-thing-assignee-select {
  display: block;
}

.defect-thing-assignee-select--Label {
  text-transform: none;
  font-size: 0.7em;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/defect-thing-assignee-select/defect-thing-assignee-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":["defect-thing-assignee-select {\n  display: block;\n}\n\n.defect-thing-assignee-select--Label {\n  text-transform: none;\n  font-size: 0.7em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
