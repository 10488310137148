// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nfc-tag-widget {
  display: block;
}

.nfc-tag-widget--InputWrapper {
  align-items: baseline;
}

.nfc-tag-widget--NfcButton {
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/nfc-tag-widget/nfc-tag-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["nfc-tag-widget {\n  display: block;\n}\n\n.nfc-tag-widget--InputWrapper {\n  align-items: baseline;\n}\n\n.nfc-tag-widget--NfcButton {\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
