// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-template-entry-properties-default-value-widget {
  display: block;
}

.structure-template-entry-properties-default-value-widget--Column {
  flex: 1 1 100px;
}
`, "",{"version":3,"sources":["webpack://./src/structureTemplateComponents/structure-template-entry-properties-default-value-widget/structure-template-entry-properties-default-value-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["structure-template-entry-properties-default-value-widget {\n  display: block;\n}\n\n.structure-template-entry-properties-default-value-widget--Column {\n  flex: 1 1 100px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
