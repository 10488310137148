// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --gallery-thing-picture-selector--pictureBoxMaxWidth: 200px;
}

gallery-thing-picture-selector {
  display: block;
}

.gallery-thing-picture-selector--Container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: -5px;
  margin-right: -5px;
}

.gallery-thing-picture-selector--Picture {
  flex: 0 0 20%;
  padding: 5px;
  max-width: var(--gallery-thing-picture-selector--pictureBoxMaxWidth);
}

.gallery-thing-picture-selector--PictureContainer {
  position: relative;
}

.gallery-thing-picture-selector--PictureUploadArea {
  border: 1px dashed var(--record-it-color-gray-300);
}

.gallery-thing-picture-selector--PictureContentWrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 100%;
}

.gallery-thing-picture-selector--PicturePreview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: auto;
  height: auto;
}

.gallery-thing-picture-selector--PictureLabel {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin-top: 10px;
  white-space: nowrap;
  max-width: var(--gallery-thing-picture-selector--pictureBoxMaxWidth);
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-picture-selector/gallery-thing-picture-selector.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;AAC7D;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,oEAAoE;AACtE;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;;EAEP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,oEAAoE;AACtE","sourcesContent":[":root {\n  --gallery-thing-picture-selector--pictureBoxMaxWidth: 200px;\n}\n\ngallery-thing-picture-selector {\n  display: block;\n}\n\n.gallery-thing-picture-selector--Container {\n  display: flex;\n  flex-wrap: wrap;\n  margin-left: -5px;\n  margin-top: -5px;\n  margin-right: -5px;\n}\n\n.gallery-thing-picture-selector--Picture {\n  flex: 0 0 20%;\n  padding: 5px;\n  max-width: var(--gallery-thing-picture-selector--pictureBoxMaxWidth);\n}\n\n.gallery-thing-picture-selector--PictureContainer {\n  position: relative;\n}\n\n.gallery-thing-picture-selector--PictureUploadArea {\n  border: 1px dashed var(--record-it-color-gray-300);\n}\n\n.gallery-thing-picture-selector--PictureContentWrapper {\n  position: relative;\n  overflow: hidden;\n  cursor: pointer;\n  padding-bottom: 100%;\n}\n\n.gallery-thing-picture-selector--PicturePreview {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  width: auto;\n  height: auto;\n}\n\n.gallery-thing-picture-selector--PictureLabel {\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  text-align: center;\n  margin-top: 10px;\n  white-space: nowrap;\n  max-width: var(--gallery-thing-picture-selector--pictureBoxMaxWidth);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
