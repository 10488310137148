// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture {
  display: block;
  position: relative;
}

.picture--ContentWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px solid rgba(125, 125, 125, 0.25);
  width: 100%;
  height: 100%;
}

.picture--ContentWrapper.no-border,
picture[no-border] .picture--ContentWrapper {
  border: none;
}

.picture--ContentWrapper.selected {
  border: 3px solid #f57d00;
}

.picture--ContentWrapper.not-editable {
  cursor: not-allowed;
}

.picture--ContentWrapper.not-clickable {
  cursor: default;
}

.picture--CustomImg {
  width: 100%;
  height: 100%;
}

.picture--NoPicturePlaceholder {
  padding-bottom: 75%;

  background: #dedede url('../../../img/missing-picture.png');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: soft-light; /* this is just temporary until we have the real background image */

  width: 100%;
  height: 100%;
}

.picture--NoPictureText {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  transform: translate(-50%, -50%);

  font-weight: bold;
  font-size: 1.1em;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/picture/picture/picture.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,2CAA2C;EAC3C,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;;EAEnB,2DAA2D;EAC3D,oBAAoB;EACpB,4BAA4B;EAC5B,2BAA2B;EAC3B,iCAAiC,EAAE,mEAAmE;;EAEtG,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,cAAc;EACd,gCAAgC;;EAEhC,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["picture {\n  display: block;\n  position: relative;\n}\n\n.picture--ContentWrapper {\n  overflow: hidden;\n  position: relative;\n  cursor: pointer;\n  border: 1px solid rgba(125, 125, 125, 0.25);\n  width: 100%;\n  height: 100%;\n}\n\n.picture--ContentWrapper.no-border,\npicture[no-border] .picture--ContentWrapper {\n  border: none;\n}\n\n.picture--ContentWrapper.selected {\n  border: 3px solid #f57d00;\n}\n\n.picture--ContentWrapper.not-editable {\n  cursor: not-allowed;\n}\n\n.picture--ContentWrapper.not-clickable {\n  cursor: default;\n}\n\n.picture--CustomImg {\n  width: 100%;\n  height: 100%;\n}\n\n.picture--NoPicturePlaceholder {\n  padding-bottom: 75%;\n\n  background: #dedede url('../../../img/missing-picture.png');\n  background-size: 30%;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-blend-mode: soft-light; /* this is just temporary until we have the real background image */\n\n  width: 100%;\n  height: 100%;\n}\n\n.picture--NoPictureText {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  max-width: 90%;\n  transform: translate(-50%, -50%);\n\n  font-weight: bold;\n  font-size: 1.1em;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
