// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-upload-area {
  display: block;
}

.picture-upload-area--ContentContainer {
  position: relative;
  text-align: center;
}

.picture-upload-area--IconWrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

picture-upload-area[data-hide-description]
  .no-picture-upload-placeholder--Description {
  display: none;
}

picture-upload-area[data-hide-description] .picture-upload-area--Icon {
  vertical-align: middle;
}

picture-upload-area[data-fill-absolute] {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

picture-upload-area[data-fill-absolute] .picture-upload-area--ContentContainer {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/picture/picture-upload-area/picture-upload-area.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,OAAO;EACP,kBAAkB;EAClB,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,gCAAgC;AAClC","sourcesContent":["picture-upload-area {\n  display: block;\n}\n\n.picture-upload-area--ContentContainer {\n  position: relative;\n  text-align: center;\n}\n\n.picture-upload-area--IconWrapper {\n  position: relative;\n  display: inline-block;\n  cursor: pointer;\n}\n\npicture-upload-area[data-hide-description]\n  .no-picture-upload-placeholder--Description {\n  display: none;\n}\n\npicture-upload-area[data-hide-description] .picture-upload-area--Icon {\n  vertical-align: middle;\n}\n\npicture-upload-area[data-fill-absolute] {\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n\npicture-upload-area[data-fill-absolute] .picture-upload-area--ContentContainer {\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
