// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pagination {
  display: block;
  text-align: center;
}

.pagination--Container {
  margin: 20px 0;
  color: #878787;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.pagination--Container > div {
  margin: 0 3px;
}

.pagination--Container a,
.pagination--Container span {
  text-decoration: none;
  color: #999999;
}

.pagination--PageSizeSelect {
  position: absolute;
  right: 0;
  width: 64px;
}

@media (max-width: 767px) {
  .pagination--Container {
    margin: 17px 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/pagination/pagination.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":["pagination {\n  display: block;\n  text-align: center;\n}\n\n.pagination--Container {\n  margin: 20px 0;\n  color: #878787;\n  font-size: 14px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  position: relative;\n}\n\n.pagination--Container > div {\n  margin: 0 3px;\n}\n\n.pagination--Container a,\n.pagination--Container span {\n  text-decoration: none;\n  color: #999999;\n}\n\n.pagination--PageSizeSelect {\n  position: absolute;\n  right: 0;\n  width: 64px;\n}\n\n@media (max-width: 767px) {\n  .pagination--Container {\n    margin: 17px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
