// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-user-dialog {
  display: block;
}

.edit-user-dialog--UserGroupBadges {
  font-size: 13px;
  cursor: pointer;
}

.edit-user-dialog--PermissionCheckbox {
  width: 100%;
}

.edit-user-dialog--PermissionDate {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-user-dialog/edit-user-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["edit-user-dialog {\n  display: block;\n}\n\n.edit-user-dialog--UserGroupBadges {\n  font-size: 13px;\n  cursor: pointer;\n}\n\n.edit-user-dialog--PermissionCheckbox {\n  width: 100%;\n}\n\n.edit-user-dialog--PermissionDate {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
