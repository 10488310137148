// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-offer-relations-widget {
  display: block;
}

process-task-offer-relations-widget-process-task {
  display: block;
}

.process-task-offer-relations-widget-process-task--SelectAllWrapper {
  padding-left: 23px;
  margin-bottom: 20px;
}

.process-task-offer-relations-widget-process-task--SelectAllWrapper__MarginTop {
  margin-top: 47px;
}

process-task-offer-relations-widget-positions {
  display: block;
}

process-task-offer-relations-widget-devices {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-offer-relations-widget/process-task-offer-relations-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["process-task-offer-relations-widget {\n  display: block;\n}\n\nprocess-task-offer-relations-widget-process-task {\n  display: block;\n}\n\n.process-task-offer-relations-widget-process-task--SelectAllWrapper {\n  padding-left: 23px;\n  margin-bottom: 20px;\n}\n\n.process-task-offer-relations-widget-process-task--SelectAllWrapper__MarginTop {\n  margin-top: 47px;\n}\n\nprocess-task-offer-relations-widget-positions {\n  display: block;\n}\n\nprocess-task-offer-relations-widget-devices {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
