// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-select {
  display: block;
}

.custom-select--Input {
  display: block;
  border: 0;
  padding: 6px;
  cursor: inherit;
  background: none;
  width: 100%;
  height: 100%;
  min-width: 0; /* to convice the input element to shrink in flexbox */
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-select--Input:disabled {
  cursor: not-allowed;
}

custom-select[data-status='warning'] {
  color: orange;
}

custom-select[data-status='warning'] .custom-select--SelectWrapper {
  border-bottom-color: orange;
}

custom-select[data-no-border] > .record-it-custom-select-wrapper {
  border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/custom-select/custom-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,SAAS;EACT,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,YAAY,EAAE,sDAAsD;EACpE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,SAAS;AACX","sourcesContent":["custom-select {\n  display: block;\n}\n\n.custom-select--Input {\n  display: block;\n  border: 0;\n  padding: 6px;\n  cursor: inherit;\n  background: none;\n  width: 100%;\n  height: 100%;\n  min-width: 0; /* to convice the input element to shrink in flexbox */\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.custom-select--Input:disabled {\n  cursor: not-allowed;\n}\n\ncustom-select[data-status='warning'] {\n  color: orange;\n}\n\ncustom-select[data-status='warning'] .custom-select--SelectWrapper {\n  border-bottom-color: orange;\n}\n\ncustom-select[data-no-border] > .record-it-custom-select-wrapper {\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
