// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-picture-export-panel {
  display: block;
  background: var(--record-it-color-gray-300);
}

.gallery-thing-picture-export-panel--ParameterPanel {
  margin-bottom: 0;
}

.gallery-thing-picture-export-panel--ExportButtonsWrapper {
  text-align: right;
  margin-top: 25px;
}

.gallery-thing-picture-export-panel--SelectedPicturesText {
  display: inline;
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-picture-export-panel/gallery-thing-picture-export-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["gallery-thing-picture-export-panel {\n  display: block;\n  background: var(--record-it-color-gray-300);\n}\n\n.gallery-thing-picture-export-panel--ParameterPanel {\n  margin-bottom: 0;\n}\n\n.gallery-thing-picture-export-panel--ExportButtonsWrapper {\n  text-align: right;\n  margin-top: 25px;\n}\n\n.gallery-thing-picture-export-panel--SelectedPicturesText {\n  display: inline;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
