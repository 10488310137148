// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-user-company-setting-dialog--FirstExpandableContainer {
  margin-top: 0;
}

.edit-user-company-setting-dialog--Subheading {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-user-company-setting-dialog/edit-user-company-setting-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".edit-user-company-setting-dialog--FirstExpandableContainer {\n  margin-top: 0;\n}\n\n.edit-user-company-setting-dialog--Subheading {\n  margin-top: 10px;\n  font-size: 16px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
