// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `finish_invite {
  display: block;
}

.finish-invite--SuccessMessage {
  margin-top: 42px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/finish_invite/finish_invite.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["finish_invite {\n  display: block;\n}\n\n.finish-invite--SuccessMessage {\n  margin-top: 42px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
