// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-position-list-item-additional-info-text {
  border-top: 1px solid var(--record-it-color-gray-300);
  color: var(--record-it-color-gray-800);
  display: none;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.process-task-position-list-item-additional-info-text--HasText {
  display: flex;
}

.process-task-position-list-item-additional-info-text--LeftText {
  flex: 1 1 auto;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.process-task-position-list-item-additional-info-text--Price {
  flex: 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-position-list-item/process-task-position-list-item-additional-info-text.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["process-task-position-list-item-additional-info-text {\n  border-top: 1px solid var(--record-it-color-gray-300);\n  color: var(--record-it-color-gray-800);\n  display: none;\n  line-height: 1.5rem;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.process-task-position-list-item-additional-info-text--HasText {\n  display: flex;\n}\n\n.process-task-position-list-item-additional-info-text--LeftText {\n  flex: 1 1 auto;\n  min-width: 0;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.process-task-position-list-item-additional-info-text--Price {\n  flex: 0 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
