// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-menu-sidebar--Sidebar {
  display: flex;
  flex-flow: column nowrap;
}

.main-menu-sidebar--GlobalSiteStateInfo {
  flex: 0 0 auto;
  padding-left: var(--record-it-safe-area-inset-left);
}

.main-menu-sidebar--MainNavigation {
  overflow: auto;
  flex: 1 1 auto;
  min-height: 0;

  padding-top: 10px;
  padding-left: var(--record-it-safe-area-inset-left);
  padding-bottom: var(--record-it-safe-area-inset-bottom);
}

.main-menu-sidebar-left-spacer {
  margin-left: var(--record-it-safe-area-inset-left);
}

@media (min-width: 1280px) and (min-height: 910px) {
  :root {
    --sidebar-top-padding: calc(
      var(--desktop-header-height) + var(--record-it-safe-area-inset-top)
    );
  }

  /* overwrite animation/state styles */
  .main-menu-sidebar--Sidebar {
    display: flex !important;
    opacity: 1 !important;
    transform: translate(0, 0) !important;
  }

  .main-menu-sidebar--Backdrop {
    display: none !important;
  }

  .main-menu-sidebar--Sidebar {
    top: var(--sidebar-top-padding);
  }

  .main-menu-sidebar--HeaderBreadCrumbIsVisible .main-menu-sidebar--Sidebar {
    top: calc(var(--sidebar-top-padding) - var(--breadcrumb-height));
  }

  .main-menu-sidebar--Heading {
    display: none;
  }

  .main-menu-sidebar-left-spacer {
    margin-left: calc(
      var(--sidebar-width) + var(--record-it-safe-area-inset-left)
    );
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/main-menu-sidebar/main-menu-sidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,mDAAmD;AACrD;;AAEA;EACE,cAAc;EACd,cAAc;EACd,aAAa;;EAEb,iBAAiB;EACjB,mDAAmD;EACnD,uDAAuD;AACzD;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE;IACE;;KAEC;EACH;;EAEA,qCAAqC;EACrC;IACE,wBAAwB;IACxB,qBAAqB;IACrB,qCAAqC;EACvC;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,gEAAgE;EAClE;;EAEA;IACE,aAAa;EACf;;EAEA;IACE;;KAEC;EACH;AACF","sourcesContent":[".main-menu-sidebar--Sidebar {\n  display: flex;\n  flex-flow: column nowrap;\n}\n\n.main-menu-sidebar--GlobalSiteStateInfo {\n  flex: 0 0 auto;\n  padding-left: var(--record-it-safe-area-inset-left);\n}\n\n.main-menu-sidebar--MainNavigation {\n  overflow: auto;\n  flex: 1 1 auto;\n  min-height: 0;\n\n  padding-top: 10px;\n  padding-left: var(--record-it-safe-area-inset-left);\n  padding-bottom: var(--record-it-safe-area-inset-bottom);\n}\n\n.main-menu-sidebar-left-spacer {\n  margin-left: var(--record-it-safe-area-inset-left);\n}\n\n@media (min-width: 1280px) and (min-height: 910px) {\n  :root {\n    --sidebar-top-padding: calc(\n      var(--desktop-header-height) + var(--record-it-safe-area-inset-top)\n    );\n  }\n\n  /* overwrite animation/state styles */\n  .main-menu-sidebar--Sidebar {\n    display: flex !important;\n    opacity: 1 !important;\n    transform: translate(0, 0) !important;\n  }\n\n  .main-menu-sidebar--Backdrop {\n    display: none !important;\n  }\n\n  .main-menu-sidebar--Sidebar {\n    top: var(--sidebar-top-padding);\n  }\n\n  .main-menu-sidebar--HeaderBreadCrumbIsVisible .main-menu-sidebar--Sidebar {\n    top: calc(var(--sidebar-top-padding) - var(--breadcrumb-height));\n  }\n\n  .main-menu-sidebar--Heading {\n    display: none;\n  }\n\n  .main-menu-sidebar-left-spacer {\n    margin-left: calc(\n      var(--sidebar-width) + var(--record-it-safe-area-inset-left)\n    );\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
