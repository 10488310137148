// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-question-catalogue-dialog {
  display: block;
}

.edit-question-catalogue-dialog--QuestionSetGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/edit-question-catalogue-dialog/edit-question-catalogue-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,2DAA2D;AAC7D","sourcesContent":["edit-question-catalogue-dialog {\n  display: block;\n}\n\n.edit-question-catalogue-dialog--QuestionSetGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
