// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-list-tree-item {
  display: block;
  margin-top: 1px;
}

.structure-list-tree-item--TitleColumn {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.structure-list-tree-item--PageDepthIndex {
  flex: 0 0 auto;
}

.structure-list-tree-item--TitleInput {
  flex: 0 1 auto;
  margin-left: 0.5em;
}

.structure-list-tree-item--SubEntry {
  margin-left: 15px;
  margin-bottom: 1px;
}

.structure-list-tree-item--NewSubEntryIcon {
  font-size: 1.25rem;
}

.structure-list-tree-item--SelectionSwitchContainer {
  flex: 0 0 auto;
  width: auto;
  height: 70%;
  display: flex;
  justify-content: right;
}

.structure-list-tree-item--SelectedStatusSet {
  opacity: 0.6;
}

.structure-list-tree-item--DraggedOver {
  border-color: var(--record-it-color-primary);
  border-width: 2px;
  border-style: solid;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/structure-list-tree-item/structure-list-tree-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,4CAA4C;EAC5C,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["structure-list-tree-item {\n  display: block;\n  margin-top: 1px;\n}\n\n.structure-list-tree-item--TitleColumn {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n}\n\n.structure-list-tree-item--PageDepthIndex {\n  flex: 0 0 auto;\n}\n\n.structure-list-tree-item--TitleInput {\n  flex: 0 1 auto;\n  margin-left: 0.5em;\n}\n\n.structure-list-tree-item--SubEntry {\n  margin-left: 15px;\n  margin-bottom: 1px;\n}\n\n.structure-list-tree-item--NewSubEntryIcon {\n  font-size: 1.25rem;\n}\n\n.structure-list-tree-item--SelectionSwitchContainer {\n  flex: 0 0 auto;\n  width: auto;\n  height: 70%;\n  display: flex;\n  justify-content: right;\n}\n\n.structure-list-tree-item--SelectedStatusSet {\n  opacity: 0.6;\n}\n\n.structure-list-tree-item--DraggedOver {\n  border-color: var(--record-it-color-primary);\n  border-width: 2px;\n  border-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
