// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-group-authorizations-widget-list-item {
  display: block;
  font-weight: 400;
}

process-task-group-authorizations-widget-list-item
  + process-task-group-authorizations-widget-list-item {
  margin-top: 12px;
}

.process-task-group-authorizations-widget-list-item--UserNameAndIconWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.process-task-group-authorizations-widget-list-item--DeleteIcon {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-group-authorizations-widget/process-task-group-authorizations-widget-list-item/process-task-group-authorizations-widget-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB","sourcesContent":["process-task-group-authorizations-widget-list-item {\n  display: block;\n  font-weight: 400;\n}\n\nprocess-task-group-authorizations-widget-list-item\n  + process-task-group-authorizations-widget-list-item {\n  margin-top: 12px;\n}\n\n.process-task-group-authorizations-widget-list-item--UserNameAndIconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.process-task-group-authorizations-widget-list-item--DeleteIcon {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
