// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-plan-based-overview {
  display: block;
}

.gallery-thing-plan-based-overview--TopBarContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 8px 45px;
  align-items: center;
  margin: 20px 0;
}

.gallery-thing-plan-based-overview--PictureSelectContainer {
  flex: 0 1 300px;
  display: flex;
  flex-wrap: nowrap;
}

.gallery-thing-plan-based-overview--PictureSelectContainer
  > .record-it-input-and-label-container-label {
  flex: 0 0 auto;
}

.gallery-thing-plan-based-overview--PlanPictureAndMarkingsWrapper {
  position: relative;
}

.gallery-thing-picture-overview--NoPlanPicturesInfoText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 30px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-plan-based-overview/gallery-thing-plan-based-overview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["gallery-thing-plan-based-overview {\n  display: block;\n}\n\n.gallery-thing-plan-based-overview--TopBarContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  gap: 8px 45px;\n  align-items: center;\n  margin: 20px 0;\n}\n\n.gallery-thing-plan-based-overview--PictureSelectContainer {\n  flex: 0 1 300px;\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.gallery-thing-plan-based-overview--PictureSelectContainer\n  > .record-it-input-and-label-container-label {\n  flex: 0 0 auto;\n}\n\n.gallery-thing-plan-based-overview--PlanPictureAndMarkingsWrapper {\n  position: relative;\n}\n\n.gallery-thing-picture-overview--NoPlanPicturesInfoText {\n  font-size: 16px;\n  font-weight: 400;\n  text-align: center;\n  padding: 30px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
