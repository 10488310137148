// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `date-picker-dialog {
  display: block;
}

.date-picker-dialog--ContentWrapper {
  left: 50%;
  overflow: auto;
  width: 307.88px !important;
  background-color: white;
}

.date-picker-dialog--ContentWrapper .flatpickr-calendar {
  border-radius: 0px;
  box-shadow: unset;
  -webkit-box-shadow: unset;
}

.date-picker-dialog--ButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  padding: 7px 9px;
}

.date-picker-dialog--ButtonsContainerBottom {
  border-top: 1px solid #dedede;
}

.date-picker-dialog--ButtonsContainer > * + * {
  margin-left: 6px;
}

.date-picker-dialog--Button {
  width: 100%;
}

.date-picker-dialog--SecondaryButton {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/date-picker-dialog/date-picker-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,cAAc;EACd,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":["date-picker-dialog {\n  display: block;\n}\n\n.date-picker-dialog--ContentWrapper {\n  left: 50%;\n  overflow: auto;\n  width: 307.88px !important;\n  background-color: white;\n}\n\n.date-picker-dialog--ContentWrapper .flatpickr-calendar {\n  border-radius: 0px;\n  box-shadow: unset;\n  -webkit-box-shadow: unset;\n}\n\n.date-picker-dialog--ButtonsContainer {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 2px;\n  padding: 7px 9px;\n}\n\n.date-picker-dialog--ButtonsContainerBottom {\n  border-top: 1px solid #dedede;\n}\n\n.date-picker-dialog--ButtonsContainer > * + * {\n  margin-left: 6px;\n}\n\n.date-picker-dialog--Button {\n  width: 100%;\n}\n\n.date-picker-dialog--SecondaryButton {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
