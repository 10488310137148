// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-revision-list-entry {
  display: block;
}

.picture-revision-list-entry--PicturePreview {
  display: block;
  height: 100px;
  width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/pictureRevisionComponents/picture-revision-list-entry/picture-revision-list-entry.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,YAAY;AACd","sourcesContent":["picture-revision-list-entry {\n  display: block;\n}\n\n.picture-revision-list-entry--PicturePreview {\n  display: block;\n  height: 100px;\n  width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
