// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  try to avoid using these styles
  TODO: replace these styles and the complicated styling in the PropertyWidgetStyle
  It is really confusing what each style does.
  It's probably better to define overlapping styles (like compact, compactButton and inTable) in an enum, because some stylings of them are exclusive/overriding the styles of other ones
*/

.property-input-field--InputWrapper {
  min-height: 48px;
}

.property-input-field--DropdownInTable,
.property-input-field--SignatureInTable {
  display: inline-flex;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.property-input-field--DropdownInTable-Item {
  height: 30px;
}

.property-input-field--DropdownInTable-Item:first-child {
  margin-top: 1px;
  flex: 0 0 30px;
}

.property-input-field--DropdownInTable-Item:last-child {
  margin-bottom: 2px;
  margin-top: 0px;
  flex: 1 1 0%;
}

.property-input-field--CompactTextInputSpacer {
  margin-bottom: 16px;
}

.property-input-field--CompactDropdownTextInputSpacer {
  margin-bottom: 16px;
}

.property-input-field--DropdownTextInput {
  margin-top: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/propertyComponents/base-property-widget/deprecated-styles.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EACE,gBAAgB;AAClB;;AAEA;;EAEE,oBAAoB;EACpB,6BAA6B;EAC7B,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/*\n  try to avoid using these styles\n  TODO: replace these styles and the complicated styling in the PropertyWidgetStyle\n  It is really confusing what each style does.\n  It's probably better to define overlapping styles (like compact, compactButton and inTable) in an enum, because some stylings of them are exclusive/overriding the styles of other ones\n*/\n\n.property-input-field--InputWrapper {\n  min-height: 48px;\n}\n\n.property-input-field--DropdownInTable,\n.property-input-field--SignatureInTable {\n  display: inline-flex;\n  justify-content: space-around;\n  flex-direction: row-reverse;\n}\n\n.property-input-field--DropdownInTable-Item {\n  height: 30px;\n}\n\n.property-input-field--DropdownInTable-Item:first-child {\n  margin-top: 1px;\n  flex: 0 0 30px;\n}\n\n.property-input-field--DropdownInTable-Item:last-child {\n  margin-bottom: 2px;\n  margin-top: 0px;\n  flex: 1 1 0%;\n}\n\n.property-input-field--CompactTextInputSpacer {\n  margin-bottom: 16px;\n}\n\n.property-input-field--CompactDropdownTextInputSpacer {\n  margin-bottom: 16px;\n}\n\n.property-input-field--DropdownTextInput {\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
