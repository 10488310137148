// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-upload-widget {
  display: block;
  position: relative;
  background: #fcfcfc;
  box-shadow: 0 1px 4px 0 #d5d5d5;
  border-radius: 3px;
  padding: 25px 20px;
  margin-bottom: 20px;
}

.picture-upload-widget--Title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.picture-upload-widget--ClearFileInput {
  padding: 10px;
}

.picture-upload-widget--ClearFileInput:hover {
  padding: 10px;
}

.picture-upload-widget--Controls {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/picture-upload-widget/picture-upload-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,+BAA+B;EAC/B,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["picture-upload-widget {\n  display: block;\n  position: relative;\n  background: #fcfcfc;\n  box-shadow: 0 1px 4px 0 #d5d5d5;\n  border-radius: 3px;\n  padding: 25px 20px;\n  margin-bottom: 20px;\n}\n\n.picture-upload-widget--Title {\n  font-weight: 500;\n  font-size: 17px;\n  margin-bottom: 10px;\n}\n\n.picture-upload-widget--ClearFileInput {\n  padding: 10px;\n}\n\n.picture-upload-widget--ClearFileInput:hover {\n  padding: 10px;\n}\n\n.picture-upload-widget--Controls {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
