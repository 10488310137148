// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-picture-area-path-input {
  display: block;
}

.structure-picture-area-path-input--InputWrapper {
  position: relative;
  padding-right: 69px;
}

.record-it-input-and-label-container-input
  + .structure-picture-area-path-input--EditButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/structurePictureAreaComponents/structure-picture-area-path-input/structure-picture-area-path-input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,6BAA6B;EAC7B,cAAc;AAChB","sourcesContent":["structure-picture-area-path-input {\n  display: block;\n}\n\n.structure-picture-area-path-input--InputWrapper {\n  position: relative;\n  padding-right: 69px;\n}\n\n.record-it-input-and-label-container-input\n  + .structure-picture-area-path-input--EditButton {\n  position: absolute;\n  right: 0;\n  top: 50%;\n  transform: translate(0, -50%);\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
