// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `icon-section-group {
  display: block;
}

.icon-section--Wrapper > icon-section-group + icon-section-group {
  border-left: 2px solid var(--record-it-color-gray-300);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/icon-section-group/icon-section-group.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sDAAsD;AACxD","sourcesContent":["icon-section-group {\n  display: block;\n}\n\n.icon-section--Wrapper > icon-section-group + icon-section-group {\n  border-left: 2px solid var(--record-it-color-gray-300);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
