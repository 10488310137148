// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `full-screen-content {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.full-screen-content--SafeAreaPadding {
  padding-left: var(--record-it-safe-area-inset-left);
  padding-top: var(--record-it-safe-area-inset-top);
  padding-right: var(--record-it-safe-area-inset-right);
  padding-bottom: var(--record-it-safe-area-inset-bottom);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/full-screen-content/full-screen-content.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,UAAU;AACZ;;AAEA;EACE,mDAAmD;EACnD,iDAAiD;EACjD,qDAAqD;EACrD,uDAAuD;AACzD","sourcesContent":["full-screen-content {\n  display: block;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 1;\n}\n\n.full-screen-content--SafeAreaPadding {\n  padding-left: var(--record-it-safe-area-inset-left);\n  padding-top: var(--record-it-safe-area-inset-top);\n  padding-right: var(--record-it-safe-area-inset-right);\n  padding-bottom: var(--record-it-safe-area-inset-bottom);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
