// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `animated-icon-success {
  display: inline-block;
  height: 3em;
  width: 3em;
  color: red;
}

.animated-icon-success--Circle {
  stroke: #24af24;
  stroke-width: 5px;
  fill: none;
  animation-name: animated-icon-success-circle-animation;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.animated-icon-success--Check {
  stroke: #24af24;
  stroke-width: 6px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  fill: none;

  animation-name: animated-icon-success-check-animation;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-delay: 0.3s;
  animation-fill-mode: both;
}

@keyframes animated-icon-success-circle-animation {
  0% {
    stroke-dasharray: 0 252 1000;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    stroke-dasharray: 126 0 1000;
  }
}

@keyframes animated-icon-success-check-animation {
  0% {
    stroke-dasharray: 0 67 10000;
    opacity: 0;
  }

  40% {
    stroke-dasharray: 0 43 10000;
  }

  50% {
    opacity: 1;
  }

  100% {
    stroke-dasharray: 0 0 10000;
  }
}
`, "",{"version":3,"sources":["webpack://./src/animatedIcon/animated-icon-success/animated-icon-success.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,sDAAsD;EACtD,wBAAwB;EACxB,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,sBAAsB;EACtB,oBAAoB;EACpB,UAAU;;EAEV,qDAAqD;EACrD,wBAAwB;EACxB,4BAA4B;EAC5B,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["animated-icon-success {\n  display: inline-block;\n  height: 3em;\n  width: 3em;\n  color: red;\n}\n\n.animated-icon-success--Circle {\n  stroke: #24af24;\n  stroke-width: 5px;\n  fill: none;\n  animation-name: animated-icon-success-circle-animation;\n  animation-duration: 0.3s;\n  animation-iteration-count: 1;\n  animation-fill-mode: both;\n}\n\n.animated-icon-success--Check {\n  stroke: #24af24;\n  stroke-width: 6px;\n  stroke-linecap: butt;\n  stroke-linejoin: miter;\n  stroke-miterlimit: 4;\n  fill: none;\n\n  animation-name: animated-icon-success-check-animation;\n  animation-duration: 0.4s;\n  animation-iteration-count: 1;\n  animation-delay: 0.3s;\n  animation-fill-mode: both;\n}\n\n@keyframes animated-icon-success-circle-animation {\n  0% {\n    stroke-dasharray: 0 252 1000;\n    opacity: 0;\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    stroke-dasharray: 126 0 1000;\n  }\n}\n\n@keyframes animated-icon-success-check-animation {\n  0% {\n    stroke-dasharray: 0 67 10000;\n    opacity: 0;\n  }\n\n  40% {\n    stroke-dasharray: 0 43 10000;\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    stroke-dasharray: 0 0 10000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
