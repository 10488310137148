// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-template-rating-levels-widget {
  display: block;
}

.structure-template-rating-levels-widget--Container {
  width: 100%;
  padding: 12px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
`, "",{"version":3,"sources":["webpack://./src/structureTemplateComponents/structure-template-rating-levels-widget/structure-template-rating-levels-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,SAAS;EACT,4DAA4D;AAC9D","sourcesContent":["structure-template-rating-levels-widget {\n  display: block;\n}\n\n.structure-template-rating-levels-widget--Container {\n  width: 100%;\n  padding: 12px;\n  display: grid;\n  gap: 12px;\n  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
