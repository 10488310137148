// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `custom-multi-select {
  display: block;
}

.custom-multi-select--Selection {
  display: flex;
  border: 0;
  padding: 6px;
  cursor: inherit;
  background: none;
  height: 100%;
  flex: 1 1 auto;
  min-width: 0;

  align-items: center;

  line-height: 1em;
}

.custom-multi-select--SelectionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-multi-select--Selection:disabled {
  cursor: not-allowed;
}

.custom-multi-select--OptionsItem {
  display: flex;
  flex-wrap: nowrap;
}

.custom-multi-select--OptionsItemCheckbox {
  flex: 0 0 25px;
}

.custom-multi-select--OptionsItemText {
  flex: 1 1 auto;
  min-width: 0;

  padding: 0 10px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-multi-select--MultipleSelectedChoicesInTable {
  width: calc(100% - 20px);
}

custom-multi-select[data-status='warning'] {
  color: orange;
}

custom-multi-select[data-status='warning'] .custom-multi-select-select-wrapper {
  border-bottom-color: orange;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/custom-multi-select/custom-multi-select.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,YAAY;;EAEZ,mBAAmB;;EAEnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,YAAY;;EAEZ,eAAe;;EAEf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["custom-multi-select {\n  display: block;\n}\n\n.custom-multi-select--Selection {\n  display: flex;\n  border: 0;\n  padding: 6px;\n  cursor: inherit;\n  background: none;\n  height: 100%;\n  flex: 1 1 auto;\n  min-width: 0;\n\n  align-items: center;\n\n  line-height: 1em;\n}\n\n.custom-multi-select--SelectionText {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.custom-multi-select--Selection:disabled {\n  cursor: not-allowed;\n}\n\n.custom-multi-select--OptionsItem {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.custom-multi-select--OptionsItemCheckbox {\n  flex: 0 0 25px;\n}\n\n.custom-multi-select--OptionsItemText {\n  flex: 1 1 auto;\n  min-width: 0;\n\n  padding: 0 10px;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.custom-multi-select--MultipleSelectedChoicesInTable {\n  width: calc(100% - 20px);\n}\n\ncustom-multi-select[data-status='warning'] {\n  color: orange;\n}\n\ncustom-multi-select[data-status='warning'] .custom-multi-select-select-wrapper {\n  border-bottom-color: orange;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
