// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button[as-element='record-it-panel-close-button'] {
  border: none;
  padding: 0;
  background: transparent;
  text-transform: uppercase;
  color: var(--record-it-color-gray-600);
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 13px;
}

.record-it-panel-close-button--Icon,
.record-it-panel-close-button--Text {
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/buttons/record-it-panel-close-button/record-it-panel-close-button.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,uBAAuB;EACvB,yBAAyB;EACzB,sCAAsC;EACtC,eAAe;EACf,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;;EAEE,sBAAsB;AACxB","sourcesContent":["button[as-element='record-it-panel-close-button'] {\n  border: none;\n  padding: 0;\n  background: transparent;\n  text-transform: uppercase;\n  color: var(--record-it-color-gray-600);\n  font-size: 13px;\n  letter-spacing: 0.3px;\n  line-height: 13px;\n}\n\n.record-it-panel-close-button--Icon,\n.record-it-panel-close-button--Text {\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
