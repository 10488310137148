// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main-caption {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.main-caption--ModuleName,
.main-caption--CompanyName {
  font-size: 23px;
  font-weight: bold;
  color: var(--record-it-color-primary);
  vertical-align: middle;
}

.main-caption--ModuleName {
  color: white;
  margin-left: 0px;
  display: inline-block;
}

.main-caption--DescriptionSpacer {
  display: block;
  height: 0px;
  overflow: hidden;
}

.main-caption--Description {
  position: absolute;
  top: 100%;
  left: 0;

  font-size: 12px;
  color: white;
}

.main-caption--LogoSvgWrapper {
  display: inline-block;
}

.main-caption--LogoSvg {
  height: 30px;
  max-width: 200px;
  width: auto;
  color: white;
  display: inline-block;
  vertical-align: middle;
}

main-caption:not([data-align='baseline']) .main-caption--LogoSvgWrapper {
  transform: translate(0, 0) !important;
}

main-caption[data-align='baseline'],
main-caption[data-align='baseline'] .main-caption--CompanyName,
main-caption[data-align='baseline'] .main-caption--ModuleName {
  vertical-align: baseline;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/main-caption/main-caption.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;;EAEE,eAAe;EACf,iBAAiB;EACjB,qCAAqC;EACrC,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;;EAEP,eAAe;EACf,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;;;EAGE,wBAAwB;AAC1B","sourcesContent":["main-caption {\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n}\n\n.main-caption--ModuleName,\n.main-caption--CompanyName {\n  font-size: 23px;\n  font-weight: bold;\n  color: var(--record-it-color-primary);\n  vertical-align: middle;\n}\n\n.main-caption--ModuleName {\n  color: white;\n  margin-left: 0px;\n  display: inline-block;\n}\n\n.main-caption--DescriptionSpacer {\n  display: block;\n  height: 0px;\n  overflow: hidden;\n}\n\n.main-caption--Description {\n  position: absolute;\n  top: 100%;\n  left: 0;\n\n  font-size: 12px;\n  color: white;\n}\n\n.main-caption--LogoSvgWrapper {\n  display: inline-block;\n}\n\n.main-caption--LogoSvg {\n  height: 30px;\n  max-width: 200px;\n  width: auto;\n  color: white;\n  display: inline-block;\n  vertical-align: middle;\n}\n\nmain-caption:not([data-align='baseline']) .main-caption--LogoSvgWrapper {\n  transform: translate(0, 0) !important;\n}\n\nmain-caption[data-align='baseline'],\nmain-caption[data-align='baseline'] .main-caption--CompanyName,\nmain-caption[data-align='baseline'] .main-caption--ModuleName {\n  vertical-align: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
