// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-process-task-appointment-dialog-process-task-info {
  display: block;
  margin-bottom: 47px;
}

.edit-process-task-appointment-dialog-process-task-info--ContentWrapper {
  display: flex;
  flex-flow: row wrap;

  margin-top: -16px; /* edit-process-task-appointment-dialog-process-task-info--Info * -1 */
}

.edit-process-task-appointment-dialog-process-task-info--Info {
  flex: 1 1 30%;
  min-width: 300px;
  margin-top: 16px;
}

.edit-process-task-appointment-dialog-process-task-info--InfoBreak {
  flex: 0 0 100%;
}

.edit-process-task-appointment-dialog-process-task-info--NavigationButton {
  flex: 0 0 auto;
  align-self: center;
}

.edit-process-task-appointment-dialog-process-task-info--Spacer {
  flex: 1 1 auto;
}

.edit-process-task-appointment-dialog-process-task-info--InfoLabel {
  margin-bottom: 5px;
  font-size: 13px;
  color: var(--record-it-color-gray-800);
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/edit-process-task-appointment-dialog/edit-process-task-appointment-dialog-process-task-info/edit-process-task-appointment-dialog-process-task-info.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;;EAEnB,iBAAiB,EAAE,sEAAsE;AAC3F;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,gBAAgB;AAClB","sourcesContent":["edit-process-task-appointment-dialog-process-task-info {\n  display: block;\n  margin-bottom: 47px;\n}\n\n.edit-process-task-appointment-dialog-process-task-info--ContentWrapper {\n  display: flex;\n  flex-flow: row wrap;\n\n  margin-top: -16px; /* edit-process-task-appointment-dialog-process-task-info--Info * -1 */\n}\n\n.edit-process-task-appointment-dialog-process-task-info--Info {\n  flex: 1 1 30%;\n  min-width: 300px;\n  margin-top: 16px;\n}\n\n.edit-process-task-appointment-dialog-process-task-info--InfoBreak {\n  flex: 0 0 100%;\n}\n\n.edit-process-task-appointment-dialog-process-task-info--NavigationButton {\n  flex: 0 0 auto;\n  align-self: center;\n}\n\n.edit-process-task-appointment-dialog-process-task-info--Spacer {\n  flex: 1 1 auto;\n}\n\n.edit-process-task-appointment-dialog-process-task-info--InfoLabel {\n  margin-bottom: 5px;\n  font-size: 13px;\n  color: var(--record-it-color-gray-800);\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
