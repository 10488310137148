// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-positions-widget {
  display: block;
}

.process-task-position-widget--LegendBar {
  text-align: right;
  margin-top: -5px;
  margin-right: -30px;
  color: var(--record-it-color-gray-600);
  margin-bottom: 10px;
}

.process-task-position-widget--Legend {
  display: inline-block;
  margin-top: 5px;
  margin-right: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-positions-widget/process-task-positions-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["process-task-positions-widget {\n  display: block;\n}\n\n.process-task-position-widget--LegendBar {\n  text-align: right;\n  margin-top: -5px;\n  margin-right: -30px;\n  color: var(--record-it-color-gray-600);\n  margin-bottom: 10px;\n}\n\n.process-task-position-widget--Legend {\n  display: inline-block;\n  margin-top: 5px;\n  margin-right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
