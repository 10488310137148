// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-project-picture-tiles {
  display: block;
}

.process-task-project-picture-tiles--SelectAllCheckbox {
  margin-bottom: 8px;
}

.process-task-project-picture-tiles--PicturesWrapper {
  display: flex;
  flex-flow: row wrap;

  margin-top: -12px;
  margin-left: -12px;
}

.process-task-project-picture-tiles--PicturePreviewWrapper {
  position: relative;
  margin-top: 12px;
  margin-left: 12px;

  flex: 0 1 140px;
  width: 140px;
  height: 100px;
}

.process-task-project-picture-tiles--PicturePreview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: auto;
  height: auto;

  background-color: var(--record-it-color-gray-400);
  transition: 0.3s ease background-color;

  cursor: pointer;
}

.process-task-project-picture-tiles--PicturePreview:hover {
  background: var(--record-it-color-gray-200);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-project-picture-tiles/process-task-project-picture-tiles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;;EAEnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;;EAEjB,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;;EAEP,WAAW;EACX,YAAY;;EAEZ,iDAAiD;EACjD,sCAAsC;;EAEtC,eAAe;AACjB;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":["process-task-project-picture-tiles {\n  display: block;\n}\n\n.process-task-project-picture-tiles--SelectAllCheckbox {\n  margin-bottom: 8px;\n}\n\n.process-task-project-picture-tiles--PicturesWrapper {\n  display: flex;\n  flex-flow: row wrap;\n\n  margin-top: -12px;\n  margin-left: -12px;\n}\n\n.process-task-project-picture-tiles--PicturePreviewWrapper {\n  position: relative;\n  margin-top: 12px;\n  margin-left: 12px;\n\n  flex: 0 1 140px;\n  width: 140px;\n  height: 100px;\n}\n\n.process-task-project-picture-tiles--PicturePreview {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  width: auto;\n  height: auto;\n\n  background-color: var(--record-it-color-gray-400);\n  transition: 0.3s ease background-color;\n\n  cursor: pointer;\n}\n\n.process-task-project-picture-tiles--PicturePreview:hover {\n  background: var(--record-it-color-gray-200);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
