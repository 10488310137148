// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-preview {
  position: relative;

  display: inline-block;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.picture-preview--NoImage {
  background: #dedede;
}

.picture-preview--NoImageIcon,
.picture-preview--ErrorText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.picture-preview--NoImageIcon.record-it-icon {
  height: 52px;
  width: 52px;
  font-size: 52px;
  line-height: 52px;
  color: var(--record-it-color-gray-600);
  opacity: 0.5;
}

.picture-preview--CustomImageDisabled {
  filter: grayscale(100%);
}
`, "",{"version":3,"sources":["webpack://./src/picture/picture-preview/picture-preview.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["picture-preview {\n  position: relative;\n\n  display: inline-block;\n  width: 200px;\n  height: 200px;\n  overflow: hidden;\n}\n\n.picture-preview--NoImage {\n  background: #dedede;\n}\n\n.picture-preview--NoImageIcon,\n.picture-preview--ErrorText {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.picture-preview--NoImageIcon.record-it-icon {\n  height: 52px;\n  width: 52px;\n  font-size: 52px;\n  line-height: 52px;\n  color: var(--record-it-color-gray-600);\n  opacity: 0.5;\n}\n\n.picture-preview--CustomImageDisabled {\n  filter: grayscale(100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
