// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `created-by-user-card {
  display: block;
}

.created-by-user-card--Header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.created-by-user-card--Header > * {
  flex: 0 0 33%;
}

.created-by-user-card--Time {
  text-align: center;
}

.created-by-user-card--Date {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/cardComponents/created-by-user-card/created-by-user-card.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["created-by-user-card {\n  display: block;\n}\n\n.created-by-user-card--Header {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-between;\n}\n\n.created-by-user-card--Header > * {\n  flex: 0 0 33%;\n}\n\n.created-by-user-card--Time {\n  text-align: center;\n}\n\n.created-by-user-card--Date {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
