// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-list-component-multi-toggle--ExpandIconWrapper {
  flex: 0 0 auto;
  color: var(--record-it-color-gray-700);
  cursor: pointer;

  width: 40px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/expandable-list-component-multi-toggle/expandable-list-component-multi-toggle.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sCAAsC;EACtC,eAAe;;EAEf,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".expandable-list-component-multi-toggle--ExpandIconWrapper {\n  flex: 0 0 auto;\n  color: var(--record-it-color-gray-700);\n  cursor: pointer;\n\n  width: 40px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
