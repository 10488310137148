// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointments-overview {
  display: block;
}

.process-task-appointments-overview--Appointment {
  opacity: 0.6;
}

.process-task-appointments-overview--Appointment
  + .process-task-appointments-overview--Appointment {
  margin-top: 32px;
}

.process-task-appointments-overview--CurrentAppointment {
  border-left: 4px solid var(--record-it-color-primary);
  padding-left: 12px;
}

.process-task-appointments-overview--CurrentAppointment,
.process-task-appointments-overview--CurrentAppointment
  ~ .process-task-appointments-overview--Appointment {
  opacity: 1;
}

.process-task-appointments-overview--AppointmentTitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointments-overview/process-task-appointments-overview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,qDAAqD;EACrD,kBAAkB;AACpB;;AAEA;;;EAGE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["process-task-appointments-overview {\n  display: block;\n}\n\n.process-task-appointments-overview--Appointment {\n  opacity: 0.6;\n}\n\n.process-task-appointments-overview--Appointment\n  + .process-task-appointments-overview--Appointment {\n  margin-top: 32px;\n}\n\n.process-task-appointments-overview--CurrentAppointment {\n  border-left: 4px solid var(--record-it-color-primary);\n  padding-left: 12px;\n}\n\n.process-task-appointments-overview--CurrentAppointment,\n.process-task-appointments-overview--CurrentAppointment\n  ~ .process-task-appointments-overview--Appointment {\n  opacity: 1;\n}\n\n.process-task-appointments-overview--AppointmentTitle {\n  font-size: 15px;\n  font-weight: 500;\n  margin-bottom: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
