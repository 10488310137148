// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `date-time-picker-date-input {
  display: inline-block;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/inputComponents/date-time-picker/date-time-picker-date-input/date-time-picker-date-input.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":["date-time-picker-date-input {\n  display: inline-block;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
