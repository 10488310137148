// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-multiple-projects-dialog--InfoText {
  font-size: 17px;
  text-align: center;
}

.export-multiple-projects-dialog--Icon {
  display: block;
  margin: 0 auto 28px;
  height: 100px;
  width: 100px;
}

.export-multiple-projects-dialog--WarningMessageBox {
  margin-bottom: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/export-multiple-projects-dialog/export-multiple-projects-dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".export-multiple-projects-dialog--InfoText {\n  font-size: 17px;\n  text-align: center;\n}\n\n.export-multiple-projects-dialog--Icon {\n  display: block;\n  margin: 0 auto 28px;\n  height: 100px;\n  width: 100px;\n}\n\n.export-multiple-projects-dialog--WarningMessageBox {\n  margin-bottom: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
