// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `time-picker-dialog {
  display: block;
}

.time-picker-dialog--ContentWrapper {
  background: white;
  left: 50%;
  overflow: auto;
  width: 300px !important;
}

.time-picker-dialog--TimePreview {
  color: var(--record-it-color-gray-600);
  font-size: 47px;
  margin: 5px 5px 10px;
  text-align: center;
}

.time-picker-dialog--TimePreviewPart {
  cursor: pointer;
}

.time-picker-dialog--TimePreviewPartActive {
  color: var(--record-it-color-primary);
}

.time-picker-dialog--TimeWheel {
  margin: 9px auto;
}

.time-picker-dialog--ButtonsContainer {
  background: white;
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
  padding: 7px 9px;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/time-picker-dialog/time-picker-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,SAAS;EACT,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["time-picker-dialog {\n  display: block;\n}\n\n.time-picker-dialog--ContentWrapper {\n  background: white;\n  left: 50%;\n  overflow: auto;\n  width: 300px !important;\n}\n\n.time-picker-dialog--TimePreview {\n  color: var(--record-it-color-gray-600);\n  font-size: 47px;\n  margin: 5px 5px 10px;\n  text-align: center;\n}\n\n.time-picker-dialog--TimePreviewPart {\n  cursor: pointer;\n}\n\n.time-picker-dialog--TimePreviewPartActive {\n  color: var(--record-it-color-primary);\n}\n\n.time-picker-dialog--TimeWheel {\n  margin: 9px auto;\n}\n\n.time-picker-dialog--ButtonsContainer {\n  background: white;\n  border-top: 1px solid #dedede;\n  display: flex;\n  justify-content: space-between;\n  padding: 7px 9px;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
