// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `expandable-dual-row-compact-list-item {
  display: block;
}

.expandable-dual-row-compact-list-item--EntityListItem {
  cursor: pointer;
  height: 55px;
}

.expandable-dual-row-compact-list-item--DualRowWrapper {
  flex: 1 1 auto;
  padding-left: 25px;
  min-width: 0;
}

.expandable-dual-row-compact-list-item--EntityListItemWithCheckbox
  .expandable-dual-row-compact-list-item--DualRowWrapper {
  padding-left: 0;
}

.expandable-dual-row-compact-list-item--TopRow {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.expandable-dual-row-compact-list-item--BottomRow {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;AACd;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,qBAAqB;AACvB","sourcesContent":["expandable-dual-row-compact-list-item {\n  display: block;\n}\n\n.expandable-dual-row-compact-list-item--EntityListItem {\n  cursor: pointer;\n  height: 55px;\n}\n\n.expandable-dual-row-compact-list-item--DualRowWrapper {\n  flex: 1 1 auto;\n  padding-left: 25px;\n  min-width: 0;\n}\n\n.expandable-dual-row-compact-list-item--EntityListItemWithCheckbox\n  .expandable-dual-row-compact-list-item--DualRowWrapper {\n  padding-left: 0;\n}\n\n.expandable-dual-row-compact-list-item--TopRow {\n  align-items: center;\n  display: flex;\n  flex-flow: row nowrap;\n}\n\n.expandable-dual-row-compact-list-item--BottomRow {\n  align-items: center;\n  display: flex;\n  flex-flow: row nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
