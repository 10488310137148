// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-region-pictures-group {
  display: block;
}

.gallery-thing-region-pictures-group--PictureGroupContainer {
  margin-top: 30px;
  margin-bottom: 0px;
}

.gallery-thing-region-pictures-group--RegionDescriptionContainer {
  margin-bottom: 20px;
  display: flex;
}

.gallery-thing-region-pictures-group--RegionDescriptionLabel {
  flex: 0 1 185px;
  font-size: 13px;
  color: var(--record-it-color-gray-700);
}

.gallery-thing-region-pictures-group--RegionDescription {
  flex: 0 1 500px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-region-pictures-group/gallery-thing-region-pictures-group.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,eAAe;AACjB","sourcesContent":["gallery-thing-region-pictures-group {\n  display: block;\n}\n\n.gallery-thing-region-pictures-group--PictureGroupContainer {\n  margin-top: 30px;\n  margin-bottom: 0px;\n}\n\n.gallery-thing-region-pictures-group--RegionDescriptionContainer {\n  margin-bottom: 20px;\n  display: flex;\n}\n\n.gallery-thing-region-pictures-group--RegionDescriptionLabel {\n  flex: 0 1 185px;\n  font-size: 13px;\n  color: var(--record-it-color-gray-700);\n}\n\n.gallery-thing-region-pictures-group--RegionDescription {\n  flex: 0 1 500px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
