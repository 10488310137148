// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry-rapid-fire-widget--MappingTitleHint {
  font-size: 0.9em;
}

.entry-rapid-fire-widget--SelectedEntriesHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.entry-rapid-fire-widget--SelectedEntriesHeaderLeftContent {
  flex: 1 1 auto;
}

.entry-rapid-fire-widget--SelectedEntriesHeaderRightContent {
  flex: 0 0 auto;
  align-self: flex-end;
}

.entry-rapid-fire-widget--EntrySelectWrapper {
  display: flex;
  flex-flow: row nowrap;

  margin-top: 10px;
}

.entry-rapid-fire-widget--EntrySelectWrapper > custom-select {
  flex: 1 1 auto;
  min-width: 0;
}

.entry-rapid-fire-widget--EntrySelectWrapper > .record-it-icon {
  flex: 0 0 auto;
  margin-left: 5px;
  margin-right: 5px;
}

.entry-rapid-fire-widget--EntrySelectWrapperLast {
  margin-top: 25px;
}

.entry-rapid-fire-widget--Preloader {
  background: rgba(255, 255, 255, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/entry-rapid-fire-widget/entry-rapid-fire-widget.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,qBAAqB;;EAErB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".entry-rapid-fire-widget--MappingTitleHint {\n  font-size: 0.9em;\n}\n\n.entry-rapid-fire-widget--SelectedEntriesHeader {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: flex-end;\n}\n\n.entry-rapid-fire-widget--SelectedEntriesHeaderLeftContent {\n  flex: 1 1 auto;\n}\n\n.entry-rapid-fire-widget--SelectedEntriesHeaderRightContent {\n  flex: 0 0 auto;\n  align-self: flex-end;\n}\n\n.entry-rapid-fire-widget--EntrySelectWrapper {\n  display: flex;\n  flex-flow: row nowrap;\n\n  margin-top: 10px;\n}\n\n.entry-rapid-fire-widget--EntrySelectWrapper > custom-select {\n  flex: 1 1 auto;\n  min-width: 0;\n}\n\n.entry-rapid-fire-widget--EntrySelectWrapper > .record-it-icon {\n  flex: 0 0 auto;\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.entry-rapid-fire-widget--EntrySelectWrapperLast {\n  margin-top: 25px;\n}\n\n.entry-rapid-fire-widget--Preloader {\n  background: rgba(255, 255, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
