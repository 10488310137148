// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-picture-filter {
  display: block;
  padding-bottom: 25px;
}

.gallery-thing-picture-filter--DateRangeRow {
  margin-top: 10px;
  margin-left: -10px;
}

.gallery-thing-picture-filter--DateRangeRow > * {
  margin-left: 10px;
}

.gallery-thing-picture-filter--LastDaysRangeSelect {
  display: inline-block;
  width: 200px;
}

.gallery-thing-picture-filter--DateRangeText {
  display: inline-block;
  margin-top: 10px;
  color: var(--record-it-color-secondary);
}

.gallery-thing-picture-filter--DetailDateRangeButton {
  margin-top: 5px;
}

.gallery-thing-picture-filter--ShowExportDetailButton {
  opacity: 1;
  transition: 0.3s ease opacity;
}

.gallery-thing-picture-filter--ShowExportDetailButtonHidden {
  opacity: 0;
}

.gallery-thing-picture-filter--ExportPanel {
  overflow: hidden; /* needed so the animation stays fluid */
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-filter/gallery-thing-picture-filter/gallery-thing-picture-filter.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,uCAAuC;AACzC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB,EAAE,wCAAwC;AAC5D","sourcesContent":["gallery-thing-picture-filter {\n  display: block;\n  padding-bottom: 25px;\n}\n\n.gallery-thing-picture-filter--DateRangeRow {\n  margin-top: 10px;\n  margin-left: -10px;\n}\n\n.gallery-thing-picture-filter--DateRangeRow > * {\n  margin-left: 10px;\n}\n\n.gallery-thing-picture-filter--LastDaysRangeSelect {\n  display: inline-block;\n  width: 200px;\n}\n\n.gallery-thing-picture-filter--DateRangeText {\n  display: inline-block;\n  margin-top: 10px;\n  color: var(--record-it-color-secondary);\n}\n\n.gallery-thing-picture-filter--DetailDateRangeButton {\n  margin-top: 5px;\n}\n\n.gallery-thing-picture-filter--ShowExportDetailButton {\n  opacity: 1;\n  transition: 0.3s ease opacity;\n}\n\n.gallery-thing-picture-filter--ShowExportDetailButtonHidden {\n  opacity: 0;\n}\n\n.gallery-thing-picture-filter--ExportPanel {\n  overflow: hidden; /* needed so the animation stays fluid */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
