// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-process-appointment-devices--Legend {
  text-align: right;
  color: var(--record-it-color-gray-600);
}

.show-process-appointment-devices--LegendItems {
  margin-left: -15px;
  margin-top: -10px;
}

.show-process-appointment-devices--LegendItem {
  margin-left: 15px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/show_process_appointment/show-process-appointment-devices/show-process-appointment-devices.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".show-process-appointment-devices--Legend {\n  text-align: right;\n  color: var(--record-it-color-gray-600);\n}\n\n.show-process-appointment-devices--LegendItems {\n  margin-left: -15px;\n  margin-top: -10px;\n}\n\n.show-process-appointment-devices--LegendItem {\n  margin-left: 15px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
