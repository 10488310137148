// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `icon-section {
  display: block;
}

.icon-section--Wrapper {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 52px;
  justify-content: flex-end;
}

.icon-section--Icon {
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/icon-section/icon-section.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd","sourcesContent":["icon-section {\n  display: block;\n}\n\n.icon-section--Wrapper {\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  height: 52px;\n  justify-content: flex-end;\n}\n\n.icon-section--Icon {\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
