// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit-external-user-credentials-widget {
  display: block;
}

.edit-external-user-credentials-widget--ChangePasswordPanel {
  flex: 1 0 100%;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/edit-external-user-credentials-widget/edit-external-user-credentials-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["edit-external-user-credentials-widget {\n  display: block;\n}\n\n.edit-external-user-credentials-widget--ChangePasswordPanel {\n  flex: 1 0 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
