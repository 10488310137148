// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-task-picture-overview-dialog--ContentWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
}

.process-task-picture-overview-dialog--PictureZoomBox {
  flex: 1 1 auto;
  min-height: 0;
}

.process-task-picture-overview-dialog--PictureScroller {
  flex: 0 0 auto;
  margin-top: 17px;
  height: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-picture-overview-dialog/process-task-picture-overview-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".process-task-picture-overview-dialog--ContentWrapper {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: space-between;\n  height: 100%;\n}\n\n.process-task-picture-overview-dialog--PictureZoomBox {\n  flex: 1 1 auto;\n  min-height: 0;\n}\n\n.process-task-picture-overview-dialog--PictureScroller {\n  flex: 0 0 auto;\n  margin-top: 17px;\n  height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
