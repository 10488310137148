// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `map-sidebar {
  display: flex;
  flex-flow: column nowrap;
}

.map-sidebar--Header {
  flex: 0 0 52px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  background: var(--record-it-color-primary-light);
  color: white;
  font-size: 13px;

  padding: 0 10px;
}

.map-sidebar--Header > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-sidebar--HeaderTitle {
  font-size: 17px;
  font-weight: 500;
}

.map-sidebar--Content {
  flex: 1 1 auto;
  min-height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/map-sidebar/map-sidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,cAAc;;EAEd,aAAa;EACb,wBAAwB;EACxB,uBAAuB;;EAEvB,gDAAgD;EAChD,YAAY;EACZ,eAAe;;EAEf,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,aAAa;AACf","sourcesContent":["map-sidebar {\n  display: flex;\n  flex-flow: column nowrap;\n}\n\n.map-sidebar--Header {\n  flex: 0 0 52px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n\n  background: var(--record-it-color-primary-light);\n  color: white;\n  font-size: 13px;\n\n  padding: 0 10px;\n}\n\n.map-sidebar--Header > * {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.map-sidebar--HeaderTitle {\n  font-size: 17px;\n  font-weight: 500;\n}\n\n.map-sidebar--Content {\n  flex: 1 1 auto;\n  min-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
