// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-list-tree-item-rating-widget {
  display: block;
}

.structure-list-tree-item-rating-widget--Container {
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.structure-list-tree-item-rating-widget--Button
  + .structure-list-tree-item-rating-widget--Button {
  border-left: 1px solid #dddddd;
}

.structure-list-tree-item-rating-widget--Button {
  background-color: inherit;
  border: 0;
  padding: 2px 8px;
  display: flex;
  align-items: center;
}

.structure-list-tree-item-rating-widget--ButtonContent {
  width: 30px;
  overflow-x: clip;
}

.structure-list-tree-item-rating-widget--Icon {
  font-size: 22px;
}

.structure-list-tree-item-rating-widget--Label {
  font-size: 12px;
  font-weight: bold;
  padding: 6px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/structure-list-tree-item-rating-widget/structure-list-tree-item-rating-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;;EAEE,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["structure-list-tree-item-rating-widget {\n  display: block;\n}\n\n.structure-list-tree-item-rating-widget--Container {\n  display: flex;\n  border: 1px solid #dddddd;\n  border-radius: 3px;\n}\n\n.structure-list-tree-item-rating-widget--Button\n  + .structure-list-tree-item-rating-widget--Button {\n  border-left: 1px solid #dddddd;\n}\n\n.structure-list-tree-item-rating-widget--Button {\n  background-color: inherit;\n  border: 0;\n  padding: 2px 8px;\n  display: flex;\n  align-items: center;\n}\n\n.structure-list-tree-item-rating-widget--ButtonContent {\n  width: 30px;\n  overflow-x: clip;\n}\n\n.structure-list-tree-item-rating-widget--Icon {\n  font-size: 22px;\n}\n\n.structure-list-tree-item-rating-widget--Label {\n  font-size: 12px;\n  font-weight: bold;\n  padding: 6px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
