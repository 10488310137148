// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `global-search-result-list-item {
  display: block;
}

.global-search-result-list-item--ScaledImageSize {
  width: 100%;
  height: 100%;
}

.global-search-result-list-item--PropertyItem {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/listItems/globalSearch/global-search-result-list-item/global-search-result-list-item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["global-search-result-list-item {\n  display: block;\n}\n\n.global-search-result-list-item--ScaledImageSize {\n  width: 100%;\n  height: 100%;\n}\n\n.global-search-result-list-item--PropertyItem {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
