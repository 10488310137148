// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-task-appointment-general-widget--EntityText > * {
  vertical-align: middle;
}

.process-task-appointment-general-widget--EntityEditIcon {
  cursor: pointer;
}

.process-task-appointment-general-widget--FollowUpButtons {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-general-widget/process-task-appointment-general-widget.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".process-task-appointment-general-widget--EntityText > * {\n  vertical-align: middle;\n}\n\n.process-task-appointment-general-widget--EntityEditIcon {\n  cursor: pointer;\n}\n\n.process-task-appointment-general-widget--FollowUpButtons {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
