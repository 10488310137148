// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-sms-enabled-text {
  display: block;
}

.process-task-appointment-sms-enabled-text--Text[data-type='error'] {
  color: var(--record-it-color-red);
}

.process-task-appointment-sms-enabled-text--Text[data-type='neutral'] {
  color: var(--record-it-color-gray-600);
}

.process-task-appointment-sms-enabled-text--Text[data-type='ok'] {
  color: var(--record-it-color-green);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-sms-enabled-text/process-task-appointment-sms-enabled-text.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":["process-task-appointment-sms-enabled-text {\n  display: block;\n}\n\n.process-task-appointment-sms-enabled-text--Text[data-type='error'] {\n  color: var(--record-it-color-red);\n}\n\n.process-task-appointment-sms-enabled-text--Text[data-type='neutral'] {\n  color: var(--record-it-color-gray-600);\n}\n\n.process-task-appointment-sms-enabled-text--Text[data-type='ok'] {\n  color: var(--record-it-color-green);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
