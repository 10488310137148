// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-thing-date-range-selection-dialog--LastDaysRangeLabel {
  font-size: 10px;
  color: var(--record-it-color-gray-600);
}

.gallery-thing-date-range-selection-dialog--WarningText {
  margin-bottom: 10px;
}

.gallery-thing-date-range-selection-dialog--AddDateRangeTitle {
  font-size: 16px;
}

.gallery-thing-date-range-selection-dialog--AddDateRangeRow {
  display: flex;
  flex-flow: row nowrap;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.gallery-thing-date-range-selection-dialog--AddDateRangeRow > date-time-picker {
  flex: 1 1 auto;
  min-width: 75px;
  margin-left: 10px;
  margin-top: 10px;
}

.gallery-thing-date-range-selection-dialog--AddDateRangeRow > button {
  flex: 0 0 auto;
  margin-left: 20px;
  margin-top: 10px;
  align-self: flex-end;
}

.gallery-thing-date-range-selection-dialog--DateRange {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #d7d7d7;
  margin: 5px 0px;
}

.gallery-thing-date-range-selection-dialog--DateRangeDeleteIcon {
  flex: 0 0 auto;
}

.gallery-thing-date-range-selection-dialog--DateRangeText {
  flex: 1 1 auto;
  align-self: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-date-range-selection-dialog/gallery-thing-date-range-selection-dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB","sourcesContent":[".gallery-thing-date-range-selection-dialog--LastDaysRangeLabel {\n  font-size: 10px;\n  color: var(--record-it-color-gray-600);\n}\n\n.gallery-thing-date-range-selection-dialog--WarningText {\n  margin-bottom: 10px;\n}\n\n.gallery-thing-date-range-selection-dialog--AddDateRangeTitle {\n  font-size: 16px;\n}\n\n.gallery-thing-date-range-selection-dialog--AddDateRangeRow {\n  display: flex;\n  flex-flow: row nowrap;\n  margin-left: -10px;\n  margin-top: -10px;\n  margin-bottom: 20px;\n}\n\n.gallery-thing-date-range-selection-dialog--AddDateRangeRow > date-time-picker {\n  flex: 1 1 auto;\n  min-width: 75px;\n  margin-left: 10px;\n  margin-top: 10px;\n}\n\n.gallery-thing-date-range-selection-dialog--AddDateRangeRow > button {\n  flex: 0 0 auto;\n  margin-left: 20px;\n  margin-top: 10px;\n  align-self: flex-end;\n}\n\n.gallery-thing-date-range-selection-dialog--DateRange {\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n  padding: 5px 0px;\n  border-bottom: 1px solid #d7d7d7;\n  margin: 5px 0px;\n}\n\n.gallery-thing-date-range-selection-dialog--DateRangeDeleteIcon {\n  flex: 0 0 auto;\n}\n\n.gallery-thing-date-range-selection-dialog--DateRangeText {\n  flex: 1 1 auto;\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
