// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-picture-overview-action-bar {
  display: block;
}

.gallery-thing-picture-overview-action-bar--CreateArea {
  padding-right: 8px;
}

.gallery-thing-picture-overview-action-bar--CreateWrapper {
  /* max-height needs to be set to the button height here, otherwise date-picker will choose to display vertically and mess up the height */
  max-height: 35px;
  display: flex;
  justify-content: flex-end;
}

.gallery-thing-picture-overview-action-bar--CreateDate {
  margin-right: 12px;
}

.gallery-thing-picture-overview-action-bar--CreateButton {
  margin-right: 24px;
}

.gallery-thing-picture-overview-action-bar--FilterIcon {
  cursor: pointer;
}

.gallery-thing-picture-overview-action-bar--FilterIconActive {
  color: var(--record-it-color-primary);
}

@media (max-width: 767px) {
  .gallery-thing-picture-overview-action-bar--CreateDate {
    margin-right: 0px;
  }

  .gallery-thing-picture-overview-action-bar--CreateButton {
    margin-right: 6px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-overview-action-bar/gallery-thing-picture-overview-action-bar/gallery-thing-picture-overview-action-bar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yIAAyI;EACzI,gBAAgB;EAChB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["gallery-thing-picture-overview-action-bar {\n  display: block;\n}\n\n.gallery-thing-picture-overview-action-bar--CreateArea {\n  padding-right: 8px;\n}\n\n.gallery-thing-picture-overview-action-bar--CreateWrapper {\n  /* max-height needs to be set to the button height here, otherwise date-picker will choose to display vertically and mess up the height */\n  max-height: 35px;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.gallery-thing-picture-overview-action-bar--CreateDate {\n  margin-right: 12px;\n}\n\n.gallery-thing-picture-overview-action-bar--CreateButton {\n  margin-right: 24px;\n}\n\n.gallery-thing-picture-overview-action-bar--FilterIcon {\n  cursor: pointer;\n}\n\n.gallery-thing-picture-overview-action-bar--FilterIconActive {\n  color: var(--record-it-color-primary);\n}\n\n@media (max-width: 767px) {\n  .gallery-thing-picture-overview-action-bar--CreateDate {\n    margin-right: 0px;\n  }\n\n  .gallery-thing-picture-overview-action-bar--CreateButton {\n    margin-right: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
