// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `inline-creation-section {
  display: block;
  min-height: 45px;
}

.inline-creation-section--CreationSection {
  position: relative;
}

.inline-creation-section--AddButtonWrapper {
  margin-top: 10px;
  text-align: right;
}

inline-creation-section[data-left-align-open-button]
  .inline-creation-section--OpenButton {
  margin-left: calc(var(--record-it-button-vertical-padding) * -1);
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/inline-creation-section/inline-creation-section.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,gEAAgE;AAClE","sourcesContent":["inline-creation-section {\n  display: block;\n  min-height: 45px;\n}\n\n.inline-creation-section--CreationSection {\n  position: relative;\n}\n\n.inline-creation-section--AddButtonWrapper {\n  margin-top: 10px;\n  text-align: right;\n}\n\ninline-creation-section[data-left-align-open-button]\n  .inline-creation-section--OpenButton {\n  margin-left: calc(var(--record-it-button-vertical-padding) * -1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
