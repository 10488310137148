// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-marking-overlay-marking {
  display: block;
}

.picture-marking-overlay-marking--ZoomBoxExtents {
  border: 2px dashed var(--record-it-color-primary);
  position: absolute;

  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/picture/picture-marking-overlay/picture-marking-overlay-marking/picture-marking-overlay-marking.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iDAAiD;EACjD,kBAAkB;;EAElB,oBAAoB;AACtB","sourcesContent":["picture-marking-overlay-marking {\n  display: block;\n}\n\n.picture-marking-overlay-marking--ZoomBoxExtents {\n  border: 2px dashed var(--record-it-color-primary);\n  position: absolute;\n\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
