// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `zoom-box {
  display: inline-block;
  position: relative;

  min-width: 100px;
  min-height: 50px;
}

.zoom-box--ScrollContainer {
  overflow: hidden;
  position: relative;
}

.zoom-box--ContentWrapper {
  overflow: auto; /* to prevent margin collapsing */
  transform: translate(0, 0) scale(1, 1);
}

.zoom-box--ScrollContainer,
.zoom-box--ContentWrapper {
  height: 100%; /* for fixed height usages */
}

.zoom-box--OverlayLayer {
  position: absolute;
}

.zoom-box--ZoomButtonsContainer {
  position: absolute;
  right: 5px;
  bottom: 5px;

  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease opacity;
}

.zoom-box--ZoomButton {
  display: block;
  padding: 5px;
}

.zoom-box--ZoomButton + .zoom-box--ZoomButton {
  margin-top: 5px;
}

.zoom-box--ZoomButton > .record-it-icon {
  font-size: 18px;
}

.zoom-box--MouseInside .zoom-box--ZoomButtonsContainer {
  opacity: 1;
  pointer-events: initial;
}

.zoom-box--ButtonsContainer {
  position: absolute;
  right: 5px;
  top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/zoom-box/zoom-box.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;;EAElB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc,EAAE,iCAAiC;EACjD,sCAAsC;AACxC;;AAEA;;EAEE,YAAY,EAAE,4BAA4B;AAC5C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;;EAEX,UAAU;EACV,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV","sourcesContent":["zoom-box {\n  display: inline-block;\n  position: relative;\n\n  min-width: 100px;\n  min-height: 50px;\n}\n\n.zoom-box--ScrollContainer {\n  overflow: hidden;\n  position: relative;\n}\n\n.zoom-box--ContentWrapper {\n  overflow: auto; /* to prevent margin collapsing */\n  transform: translate(0, 0) scale(1, 1);\n}\n\n.zoom-box--ScrollContainer,\n.zoom-box--ContentWrapper {\n  height: 100%; /* for fixed height usages */\n}\n\n.zoom-box--OverlayLayer {\n  position: absolute;\n}\n\n.zoom-box--ZoomButtonsContainer {\n  position: absolute;\n  right: 5px;\n  bottom: 5px;\n\n  opacity: 0;\n  pointer-events: none;\n  transition: 0.3s ease opacity;\n}\n\n.zoom-box--ZoomButton {\n  display: block;\n  padding: 5px;\n}\n\n.zoom-box--ZoomButton + .zoom-box--ZoomButton {\n  margin-top: 5px;\n}\n\n.zoom-box--ZoomButton > .record-it-icon {\n  font-size: 18px;\n}\n\n.zoom-box--MouseInside .zoom-box--ZoomButtonsContainer {\n  opacity: 1;\n  pointer-events: initial;\n}\n\n.zoom-box--ButtonsContainer {\n  position: absolute;\n  right: 5px;\n  top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
