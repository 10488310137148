// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-comment {
  display: block;
}

.defect-comment--Defect {
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--defect-comment-color-background);
  border-radius: 3px;
  padding: 10px;
}

.defect-comment--Defect[data-status='open'] {
  border-right: 8px solid var(--record-it-color-defect-state-open);
}

.defect-comment--Defect[data-status='processed'] {
  border-right: 8px solid var(--record-it-color-defect-state-processed);
}

.defect-comment--Defect[data-status='done'] {
  border-right: 8px solid var(--record-it-color-defect-state-done);
}

.defect-comment--Header {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: var(--record-it-color-gray-700);
}

.defect-comment--Text {
  font-size: 16px;
}

.defect-comment--Text[data-status='open'] {
  color: var(--record-it-color-defect-state-open);
}

.defect-comment--Text[data-status='processed'] {
  color: var(--record-it-color-defect-state-processed);
}

.defect-comment--Text[data-status='done'] {
  color: var(--record-it-color-defect-state-done);
}

.defect-comment--Attachments {
  margin-top: 8px;
  display: flex;
  gap: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-comment/defect-comment.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,wDAAwD;EACxD,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,gEAAgE;AAClE;;AAEA;EACE,qEAAqE;AACvE;;AAEA;EACE,gEAAgE;AAClE;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,eAAe;EACf,aAAa;EACb,QAAQ;AACV","sourcesContent":["defect-comment {\n  display: block;\n}\n\n.defect-comment--Defect {\n  margin-bottom: 10px;\n  width: 100%;\n  background-color: var(--defect-comment-color-background);\n  border-radius: 3px;\n  padding: 10px;\n}\n\n.defect-comment--Defect[data-status='open'] {\n  border-right: 8px solid var(--record-it-color-defect-state-open);\n}\n\n.defect-comment--Defect[data-status='processed'] {\n  border-right: 8px solid var(--record-it-color-defect-state-processed);\n}\n\n.defect-comment--Defect[data-status='done'] {\n  border-right: 8px solid var(--record-it-color-defect-state-done);\n}\n\n.defect-comment--Header {\n  display: flex;\n  justify-content: space-between;\n  font-size: 13px;\n  color: var(--record-it-color-gray-700);\n}\n\n.defect-comment--Text {\n  font-size: 16px;\n}\n\n.defect-comment--Text[data-status='open'] {\n  color: var(--record-it-color-defect-state-open);\n}\n\n.defect-comment--Text[data-status='processed'] {\n  color: var(--record-it-color-defect-state-processed);\n}\n\n.defect-comment--Text[data-status='done'] {\n  color: var(--record-it-color-defect-state-done);\n}\n\n.defect-comment--Attachments {\n  margin-top: 8px;\n  display: flex;\n  gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
