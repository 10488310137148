// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `inline-expandable-area {
  display: block;
}

.inline-expandable-area--Wrapper {
  display: flex;
  align-items: center;
}

.inline-expandable-area--Content {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.inline-expandable-area--OpenedIcon,
.inline-expandable-area--ClosedIcon {
  display: inline-block;
  cursor: pointer;
  font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/inline-expandable-area/inline-expandable-area.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;;EAEE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["inline-expandable-area {\n  display: block;\n}\n\n.inline-expandable-area--Wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.inline-expandable-area--Content {\n  display: inline-block;\n  overflow: hidden;\n  vertical-align: middle;\n}\n\n.inline-expandable-area--OpenedIcon,\n.inline-expandable-area--ClosedIcon {\n  display: inline-block;\n  cursor: pointer;\n  font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
