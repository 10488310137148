// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `time-wheel {
  display: block;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  height: 282px;
  width: 282px;
  background: var(--record-it-color-gray-300);
}

.time-wheel--CenterPoint {
  background: var(--record-it-color-primary);
  border-radius: 50%;
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
}

.time-wheel--SelectedTickCircleConnector {
  background: var(--record-it-color-primary);
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform-origin: 0 calc(50% - 1px);
}

.time-wheel--SelectedTickCircle {
  background: var(--record-it-color-primary);
  border-radius: 50%;
  height: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
}

.time-wheel--Tick {
  color: var(--record-it-color-gray-800);
  font-size: 20px;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.time-wheel--TickSecondary {
  color: var(--record-it-color-gray-700);
  font-size: 16px;
}

.time-wheel--TickSelected {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/time-picker-dialog/time-wheel/time-wheel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,gCAAgC;EAChC,WAAW;AACb;;AAEA;EACE,0CAA0C;EAC1C,WAAW;EACX,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,mCAAmC;AACrC;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;EAChC,WAAW;AACb;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,gCAAgC;EAChC,iBAAiB;EACjB,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":["time-wheel {\n  display: block;\n  cursor: pointer;\n  position: relative;\n  border-radius: 50%;\n  height: 282px;\n  width: 282px;\n  background: var(--record-it-color-gray-300);\n}\n\n.time-wheel--CenterPoint {\n  background: var(--record-it-color-primary);\n  border-radius: 50%;\n  height: 10px;\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 10px;\n}\n\n.time-wheel--SelectedTickCircleConnector {\n  background: var(--record-it-color-primary);\n  height: 2px;\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  transform-origin: 0 calc(50% - 1px);\n}\n\n.time-wheel--SelectedTickCircle {\n  background: var(--record-it-color-primary);\n  border-radius: 50%;\n  height: 40px;\n  position: absolute;\n  transform: translate(-50%, -50%);\n  width: 40px;\n}\n\n.time-wheel--Tick {\n  color: var(--record-it-color-gray-800);\n  font-size: 20px;\n  position: absolute;\n  text-align: center;\n  transform: translate(-50%, -50%);\n  user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n}\n\n.time-wheel--TickSecondary {\n  color: var(--record-it-color-gray-700);\n  font-size: 16px;\n}\n\n.time-wheel--TickSelected {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
