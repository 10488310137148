// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `defect-entry-list {
  display: block;
}

.defect-entry-list--Separator {
  border-top: 1px solid var(--record-it-color-gray-500);
}

.defect-entry-list--NoCommentsInfoBlock {
  margin-bottom: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/defectComponents/defect-entry-list/defect-entry-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["defect-entry-list {\n  display: block;\n}\n\n.defect-entry-list--Separator {\n  border-top: 1px solid var(--record-it-color-gray-500);\n}\n\n.defect-entry-list--NoCommentsInfoBlock {\n  margin-bottom: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
