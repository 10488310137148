// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `entity-list-item-text-choices {
  display: block;
  padding-top: 5px;
  height: 100px;
}

.entity-list-item-text-choices--Container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.entity-list-item-text-choices--MainContent {
  flex: 1 1 auto;
  overflow: hidden;
}

.entity-list-item-text-choices--Item {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.entity-list-item-text-choices--Footer {
  margin-top: 5px;
  margin-bottom: 5px;
  flex: 1 0 20px;
}

.entity-list-item-text-choices--FooterText {
  line-height: 1;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/entity-list-item-text-choices/entity-list-item-text-choices.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["entity-list-item-text-choices {\n  display: block;\n  padding-top: 5px;\n  height: 100px;\n}\n\n.entity-list-item-text-choices--Container {\n  display: flex;\n  flex-direction: column;\n  max-height: 100%;\n}\n\n.entity-list-item-text-choices--MainContent {\n  flex: 1 1 auto;\n  overflow: hidden;\n}\n\n.entity-list-item-text-choices--Item {\n  display: inline-block;\n  max-width: 100%;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.entity-list-item-text-choices--Footer {\n  margin-top: 5px;\n  margin-bottom: 5px;\n  flex: 1 0 20px;\n}\n\n.entity-list-item-text-choices--FooterText {\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
