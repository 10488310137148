// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-structure-picture-areas-overlay--TopBarContainer {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  padding-right: 20px;
}

.edit-structure-picture-areas-overlay--PictureAreaSelectContainer {
  display: flex;
  width: unset;
  flex-wrap: nowrap;
}

.edit-structure-picture-areas-overlay--PictureAreaSelectContainer
  > .record-it-input-and-label-container-label {
  flex: 0 0 auto;
}

.edit-structure-picture-areas-overlay--AddButton {
  margin-left: 17px;
}

.edit-structure-picture-areas-overlay--MainContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.edit-structure-picture-areas-overlay--EditBar {
  flex: 0 0 auto;
}

.edit-structure-picture-areas-overlay--Editor {
  flex: 1 1 auto;
  width: 100%;
  min-height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/structurePictureAreaComponents/edit-structure-picture-areas-overlay/edit-structure-picture-areas-overlay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;AACf","sourcesContent":[".edit-structure-picture-areas-overlay--TopBarContainer {\n  flex: 1 1 auto;\n  display: flex;\n  flex-flow: row nowrap;\n  padding-right: 20px;\n}\n\n.edit-structure-picture-areas-overlay--PictureAreaSelectContainer {\n  display: flex;\n  width: unset;\n  flex-wrap: nowrap;\n}\n\n.edit-structure-picture-areas-overlay--PictureAreaSelectContainer\n  > .record-it-input-and-label-container-label {\n  flex: 0 0 auto;\n}\n\n.edit-structure-picture-areas-overlay--AddButton {\n  margin-left: 17px;\n}\n\n.edit-structure-picture-areas-overlay--MainContent {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: column nowrap;\n}\n\n.edit-structure-picture-areas-overlay--EditBar {\n  flex: 0 0 auto;\n}\n\n.edit-structure-picture-areas-overlay--Editor {\n  flex: 1 1 auto;\n  width: 100%;\n  min-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
