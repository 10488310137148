// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-calculation-entity-list-item-widget--Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.value-calculation-entity-list-item-widget--Value {
  overflow: hidden;
  text-overflow: ellipsis;
}

.value-calculation-entity-list-item-widget--ColorCode {
  display: block;
  width: 25%;
}
`, "",{"version":3,"sources":["webpack://./src/valueCalculationComponents/value-calculation-entity-list-item-widget/value-calculation-entity-list-item-widget.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ","sourcesContent":[".value-calculation-entity-list-item-widget--Container {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 10px;\n}\n\n.value-calculation-entity-list-item-widget--Value {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.value-calculation-entity-list-item-widget--ColorCode {\n  display: block;\n  width: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
