// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `rapid-fire-entry-select-entry-button {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--record-it-color-primary-light);
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

rapid-fire-entry-select-entry-button:hover {
  background: var(--record-it-color-primary);
  text-decoration: none;
}

.rapid-fire-entry-select-entry-button--IconWrapper {
  height: 60px;
  width: 60px;
  font-size: 56px;
  line-height: 56px;
  margin-left: 13px;
}

.rapid-fire-entry-select-entry-button--EntryName {
  margin: 0;
  font-size: 1.3em;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/rapid-fire-entry-select/rapid-fire-entry-select-entry-button/rapid-fire-entry-select-entry-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB,uBAAuB;EACvB,gDAAgD;EAChD,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,0CAA0C;EAC1C,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["rapid-fire-entry-select-entry-button {\n  display: flex;\n  padding-top: 16px;\n  padding-bottom: 16px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  background: var(--record-it-color-primary-light);\n  color: #fff;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\nrapid-fire-entry-select-entry-button:hover {\n  background: var(--record-it-color-primary);\n  text-decoration: none;\n}\n\n.rapid-fire-entry-select-entry-button--IconWrapper {\n  height: 60px;\n  width: 60px;\n  font-size: 56px;\n  line-height: 56px;\n  margin-left: 13px;\n}\n\n.rapid-fire-entry-select-entry-button--EntryName {\n  margin: 0;\n  font-size: 1.3em;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
