// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-scroller {
  display: block;
  /**
   * Normally the height/width of the pictures will depend on the height of the picture-scroller
   */
  --picture-scroller--picture-size: 100%;
}

.picture-scroller--Container {
  display: flex;

  height: 100%;

  align-items: center;

  overflow-x: auto;
  overflow-y: hidden;
}

.picture-scroller--PictureWrapper {
  flex: 0 0 auto;

  height: var(--picture-scroller--picture-size);

  border-radius: 2px;
  overflow: hidden;
  margin: 1px 8px;
  border: 1px solid var(--record-it-color-gray-300);

  box-sizing: content-box;
}

.picture-scroller--PictureHighlighted {
  margin: 0px 7px;
  border: 2px solid
    var(
      --picture-scroller--picture-highlighted-color,
      var(--record-it-color-primary)
    );
}

.picture-scroller--Picture {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
}

.picture-scroller--Spacer {
  flex: 0 0 auto;
  width: calc(50% - 42px);
  height: 100%;
}

picture-scroller[data-no-container-spacing]
  .picture-scroller--Picture:first-child {
  margin-left: 0;
}

picture-scroller[data-no-container-spacing]
  .picture-scroller--Picture:last-child {
  margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/picture/picture-scroller/picture-scroller.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd;;IAEE;EACF,sCAAsC;AACxC;;AAEA;EACE,aAAa;;EAEb,YAAY;;EAEZ,mBAAmB;;EAEnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;;EAEd,6CAA6C;;EAE7C,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iDAAiD;;EAEjD,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf;;;;KAIG;AACL;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;AACT;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,YAAY;AACd;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;EAEE,eAAe;AACjB","sourcesContent":["picture-scroller {\n  display: block;\n  /**\n   * Normally the height/width of the pictures will depend on the height of the picture-scroller\n   */\n  --picture-scroller--picture-size: 100%;\n}\n\n.picture-scroller--Container {\n  display: flex;\n\n  height: 100%;\n\n  align-items: center;\n\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n\n.picture-scroller--PictureWrapper {\n  flex: 0 0 auto;\n\n  height: var(--picture-scroller--picture-size);\n\n  border-radius: 2px;\n  overflow: hidden;\n  margin: 1px 8px;\n  border: 1px solid var(--record-it-color-gray-300);\n\n  box-sizing: content-box;\n}\n\n.picture-scroller--PictureHighlighted {\n  margin: 0px 7px;\n  border: 2px solid\n    var(\n      --picture-scroller--picture-highlighted-color,\n      var(--record-it-color-primary)\n    );\n}\n\n.picture-scroller--Picture {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n}\n\n.picture-scroller--Spacer {\n  flex: 0 0 auto;\n  width: calc(50% - 42px);\n  height: 100%;\n}\n\npicture-scroller[data-no-container-spacing]\n  .picture-scroller--Picture:first-child {\n  margin-left: 0;\n}\n\npicture-scroller[data-no-container-spacing]\n  .picture-scroller--Picture:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
