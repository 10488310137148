// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `entry-navigation-button {
  position: fixed;
  left: 0;
  top: 50%;
}

.entry-navigation-button--ContentWrapper {
  transform: translate(0%, -50%);

  width: 70px;
  height: 100px;

  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background: linear-gradient(#afafaf, #afafaf),
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.06));
  cursor: pointer;
}

.entry-navigation-button--Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 34px;
  height: 34px;
  color: white;
}

.entry-navigation-button--navigateIn .entry-navigation-button--ContentWrapper {
  transform: translate(-100%, -50%);
}

.entry-navigation-button--navigateOut .entry-navigation-button--Icon {
  transform: translate(-50%, -50%) rotate(-180deg);
}

@media (max-width: 1767px) {
  .entry-navigation-button--ContentWrapper {
    width: 34px;
    height: 100px;
  }

  .entry-navigation-button--Icon {
    background-size: 38px 38px;
    width: 34px;
    height: 34px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/entry-navigation-button/entry-navigation-button.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,8BAA8B;;EAE9B,WAAW;EACX,aAAa;;EAEb,kBAAkB;EAClB,yCAAyC;EACzC;qEACmE;EACnE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;;EAEhC,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,0BAA0B;IAC1B,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["entry-navigation-button {\n  position: fixed;\n  left: 0;\n  top: 50%;\n}\n\n.entry-navigation-button--ContentWrapper {\n  transform: translate(0%, -50%);\n\n  width: 70px;\n  height: 100px;\n\n  border-radius: 3px;\n  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);\n  background: linear-gradient(#afafaf, #afafaf),\n    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.06));\n  cursor: pointer;\n}\n\n.entry-navigation-button--Icon {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  width: 34px;\n  height: 34px;\n  color: white;\n}\n\n.entry-navigation-button--navigateIn .entry-navigation-button--ContentWrapper {\n  transform: translate(-100%, -50%);\n}\n\n.entry-navigation-button--navigateOut .entry-navigation-button--Icon {\n  transform: translate(-50%, -50%) rotate(-180deg);\n}\n\n@media (max-width: 1767px) {\n  .entry-navigation-button--ContentWrapper {\n    width: 34px;\n    height: 100px;\n  }\n\n  .entry-navigation-button--Icon {\n    background-size: 38px 38px;\n    width: 34px;\n    height: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
