// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-project-pictures-widget {
  display: block;
}

process-task-project-pictures-widget[data-no-margin-top]
  .process-task-project-pictures-widget--ExpandableContainer {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-project-pictures-widget/process-task-project-pictures-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;;EAEE,aAAa;AACf","sourcesContent":["process-task-project-pictures-widget {\n  display: block;\n}\n\nprocess-task-project-pictures-widget[data-no-margin-top]\n  .process-task-project-pictures-widget--ExpandableContainer {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
