// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-picture-cutout-overlay--MainContent {
  position: relative;
  width: 100%;
  height: 100%;
}

.create-picture-cutout-overlay--TopBarContainer {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;
}

.create-picture-cutout-overlay--Title {
  font-size: 17px;
  font-weight: 500;
}

.create-picture-cutout-overlay--TopBarButtons {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  overflow: auto;
}

.create-picture-cutout-overlay--ZoomBox {
  width: 100%;
  height: 100%;
  background: var(--record-it-color-gray-800);
}

.create-picture-cutout-overlay--PictureCoverOverlay {
  position: absolute;
}

.create-picture-cutout-overlay--ExistingCutout {
  position: absolute;
  border: 1px dashed var(--record-it-color-gray-800);
}

.create-picture-cutout-overlay--ExistingCutoutLabel {
  position: absolute;
  top: 2px;
  left: 2px;
  color: var(--record-it-color-gray-800);
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.create-picture-cutout-overlay--RepositionableElementBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
}
`, "",{"version":3,"sources":["webpack://./src/picture/create-picture-cutout-overlay/create-picture-cutout-overlay.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,sCAAsC;EACtC,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;EACR,oCAAoC;AACtC","sourcesContent":[".create-picture-cutout-overlay--MainContent {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.create-picture-cutout-overlay--TopBarContainer {\n  flex: 1 1 auto;\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 16px;\n  align-items: center;\n}\n\n.create-picture-cutout-overlay--Title {\n  font-size: 17px;\n  font-weight: 500;\n}\n\n.create-picture-cutout-overlay--TopBarButtons {\n  flex: 1 1 auto;\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 16px;\n  overflow: auto;\n}\n\n.create-picture-cutout-overlay--ZoomBox {\n  width: 100%;\n  height: 100%;\n  background: var(--record-it-color-gray-800);\n}\n\n.create-picture-cutout-overlay--PictureCoverOverlay {\n  position: absolute;\n}\n\n.create-picture-cutout-overlay--ExistingCutout {\n  position: absolute;\n  border: 1px dashed var(--record-it-color-gray-800);\n}\n\n.create-picture-cutout-overlay--ExistingCutoutLabel {\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  color: var(--record-it-color-gray-800);\n  max-width: 200px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.create-picture-cutout-overlay--RepositionableElementBackdrop {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: rgba(255, 255, 255, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
