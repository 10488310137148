// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `drawing-area {
  display: block;
}

.drawing-area--ContentWrapper {
  position: relative;
}

.drawing-area--SvgElement {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/* tool styles */
.picture-sketcher-text-tool--InputWrapper {
  z-index: 10001;
  position: fixed !important;
  opacity: 0.8;
  background: white;
  border: 1px solid grey;
  width: 250px;
  max-width: 90vw;
}

.picture-sketcher-text-tool--InputWrapper a {
  cursor: pointer;
}

.picture-sketcher-text-tool--InputWrapper .form-control {
  border-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/drawingComponents/drawing-area/drawing-area.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;;EAEP,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA,gBAAgB;AAChB;EACE,cAAc;EACd,0BAA0B;EAC1B,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["drawing-area {\n  display: block;\n}\n\n.drawing-area--ContentWrapper {\n  position: relative;\n}\n\n.drawing-area--SvgElement {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n\n  -ms-user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  user-select: none;\n}\n\n/* tool styles */\n.picture-sketcher-text-tool--InputWrapper {\n  z-index: 10001;\n  position: fixed !important;\n  opacity: 0.8;\n  background: white;\n  border: 1px solid grey;\n  width: 250px;\n  max-width: 90vw;\n}\n\n.picture-sketcher-text-tool--InputWrapper a {\n  cursor: pointer;\n}\n\n.picture-sketcher-text-tool--InputWrapper .form-control {\n  border-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
