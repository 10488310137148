// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `create-process-task-group-page-widget {
  display: block;
  background: white;
  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);
}

.create-process-task-group-page-widget--HasWarning {
  background-color: #fff4e4;
}

.create-process-task-group-page-widget--TitleBar {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--record-it-color-gray-600);
}

.create-process-task-group-page-widget--Title {
  flex: 1 1 auto;
  text-align: center;
  font-weight: bold;
}

.create-process-task-group-page-widget--TitleBarIcon {
  margin-left: 10px;
}

.create-process-task-group-page-widget--Content {
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/create-process-task-group-page-widget/create-process-task-group-page-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,uDAAuD;AACzD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,wDAAwD;AAC1D;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":["create-process-task-group-page-widget {\n  display: block;\n  background: white;\n  box-shadow: 0 1px 4px 0 var(--record-it-color-gray-300);\n}\n\n.create-process-task-group-page-widget--HasWarning {\n  background-color: #fff4e4;\n}\n\n.create-process-task-group-page-widget--TitleBar {\n  display: flex;\n  padding: 10px;\n  border-bottom: 1px solid var(--record-it-color-gray-600);\n}\n\n.create-process-task-group-page-widget--Title {\n  flex: 1 1 auto;\n  text-align: center;\n  font-weight: bold;\n}\n\n.create-process-task-group-page-widget--TitleBarIcon {\n  margin-left: 10px;\n}\n\n.create-process-task-group-page-widget--Content {\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
