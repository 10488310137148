// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-task-appointments-with-warnings-dialog--NoAppointments {
  font-size: 15px;
}

.process-task-appointments-with-warnings-dialog--MarkAllAsFinishedContainer {
  margin-top: 47px;
  text-align: right;
}

@media (max-width: 767px) {
  .process-task-appointments-with-warnings-dialog--MarkAllAsFinishedContainer {
    text-align: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointments-with-warnings-dialog/process-task-appointments-with-warnings-dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".process-task-appointments-with-warnings-dialog--NoAppointments {\n  font-size: 15px;\n}\n\n.process-task-appointments-with-warnings-dialog--MarkAllAsFinishedContainer {\n  margin-top: 47px;\n  text-align: right;\n}\n\n@media (max-width: 767px) {\n  .process-task-appointments-with-warnings-dialog--MarkAllAsFinishedContainer {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
